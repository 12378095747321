
/* This constant file is created to store featureIds*/
export const FeatureConstants = {
    Initial_Assessment_Feature_Id: '85',
    My_Programme: 63,
    My_Goals_And_Actions: 83,
    My_Calendar: 84,
    My_Progress: 82,
    Courses: 64,
    Resources: 65,
    Employment: 66,
    CV_builder: 173,
    Job_Opportunities:72,
    Wallet: 67,
    Assessments: 73,
    Documents: 74,
    Useful_websites: 68,
    Social: 69,
    Message: 70,
    My_Certificate: 95,
}