import { Component, Input, OnInit } from '@angular/core';
import { INavigation } from '../header/header.interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']

})
export class FooterComponent implements OnInit {

  navigation: Observable<Array<INavigation>>;
  currentYear = new Date().getFullYear();
  @Input() intoductionVideoLink : boolean = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.navigation = this.navigationService.currentNavigation;
  }

  gotoSplashScreenPage(){

    this.router.navigate(['/launch'],{state: { showIntroductionLink : true }});
    window.scrollTo(0, 0);
  }

}
