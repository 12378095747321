import { Component, OnInit, EventEmitter, OnDestroy, ViewChild, ElementRef, Output, Input, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { INavigation } from './header.interfaces';
import { NavigationService } from '../../services/navigation.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Base64 } from 'js-base64';
import { Router, ActivatedRoute, NavigationEnd, Event, NavigationStart } from '@angular/router';
import { SrmService } from '../../../features/srm/srm.service';
import { TabsService } from '../../../shared/components/tabs/tabs.service';
import { NotificationService } from './../../services/notification.service';
import { WalletService } from '../../../features/wallet/wallet.service';
import { TogetherModeModalService } from '../../../shared/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';
import { Url } from 'src/app/shared/constants/url-constants';
import { FeatureConstants } from 'src/app/shared/constants/features-constants';
import { servicesService } from '../../../authentication/pre-signin/courses/services.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/service/shared.service';
import { log } from 'console';
import { deletePopUpService } from 'src/app/shared/service/deletePopUp.service';
import { DeleteConfirmationDialogComponent } from 'src/app/features/cv-builder/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'vc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.componant.scss'],
  host: {
    '(document:click)': 'onClick()',
  },

})

export class HeaderComponent implements OnInit, OnDestroy {

  preAboutCTP = [
    {
      "name": "Service leavers",
      "state": "service-leavers",
      "description": "Find out how the Career Transition Partnership can support you in your resettlement journey",
      "sequenceNumber": 1,
    },
    {
      "name": "Employers",
      "state": "employers",
      "description": "Sign up to our no-cost recruitment service and promote vacancies to Armed Forces Service leavers.",
      "sequenceNumber": 2,
    },
    {
      "name": "Training Providers",
      "state": "training-providers",
      "description": "If you are a training provider or an organisation that supports Service leavers with specialist support then consider joining our preferred suppliers list.",
      "sequenceNumber": 3,
    },
  ]

  preTrainingAndEvents = [
    {
      "name": "Courses",
      "featureId": 204,
      "state": "/home/list-courses",
      "description": "The latest industry news, updates and info.",
      "parentFeatureId": null,
      "sequenceNumber": 4,
    },
    {
      "name": "Events",
      "featureId": 205,
      "state": "/home/list-events",
      "description": "Learn how our customers are making big changes.",
      "parentFeatureId": null,
      "sequenceNumber": 5,
    },
    {
      "name": "Workshops",
      "featureId": 206,
      "state": "/home/list-workshops",
      "description": "Get up and running on new features and techniques.",
      "parentFeatureId": null,
      "sequenceNumber": 6,
    },
  ]

  preMenuItems = [
    {
      "name": "Sector Guides",
      "featureId": 207,
      "state": "/sector-guides",
      "parentFeatureId": null,
      "sequenceNumber": 7,
    },
    {
      "name": "Directory of Services",
      "featureId": 208,
      "state": "/home/directory-of-services",
      "parentFeatureId": null,
      "sequenceNumber": 8,
    },
    {
      "name": "Articles & Guides",
      "featureId": 209,
      "state": "/home/articles-guide",
      "parentFeatureId": null,
      "sequenceNumber": 9,
    },
  ]
  postMenuItems = [
    {
      "name": "Job",
      "featureId": 101,
      "state": "/home/jobs",
      "parentFeatureId": null,
      "sequenceNumber": 3,
    },
    {
      "name": "Resources",
      "featureId": 102,
      "state": "/content",
      "parentFeatureId": null,
      "sequenceNumber": 4,
    },
    {
      "name": "CV Builder",
      "featureId": 103,
      "state": "/transition/cvs",
      "parentFeatureId": null,
      "sequenceNumber": 5,
    },
    {
      "name": "Articles & Guides",
      "featureId": 104,
      "state": "/home/articles-guide",
      "parentFeatureId": null,
      "sequenceNumber": 6,
    },
    {
      "name": "Directory of Services",
      "featureId": 105,
      "state": "/home/directory-of-services",
      "parentFeatureId": null,
      "sequenceNumber": 7,
    },
    {
      "name": "Sector Guides",
      "featureId": 106,
      "state": "/sector-guides",
      "parentFeatureId": null,
      "sequenceNumber": 8,
    },
  ]

  postHoverMenu = [
    {
      "name": "My Assessments",
      "state": "/transition/assessments",
    },
    {
      "name": "Job Applications",
      "state": "/home/jobs/my-jobs",
    },
    {
      "name": "My Bookings",
      "state": "/transition/mybookings",
    },
  ]
  @ViewChild('chatpopup1') chatpopup1: ElementRef;
  @ViewChild('chatpopup2') chatpopup2: ElementRef;
  @ViewChild('chatpopup3') chatpopup3: ElementRef;
  @ViewChild('chatpopup4') chatpopup4: ElementRef;
  @ViewChild('chatpopup5') chatpopup5: ElementRef;

  @Input() splashScreenWindow: boolean = false;
  @Output() openmsg = new EventEmitter();
  navigation = [];
  username: string;
  isUserMenuExpanded = false;
  isMobileMenuExpanded = false;
  isChatWindow: boolean = false;
  showOption: any;
  toChat: boolean;
  nChat: boolean;
  chatCount;
  chatFocus: number;
  keyword: string;
  showSearch = true;
  serviceUserLoggedIn: boolean;
  showManuItem = true;
  planNavItem: any[];
  walletFlag: boolean;
  togetherModeIconSrc = './../../../../assets/images/together-mode-icon.png';
  logo: string;
  logoFlag = false;
  landingUrl: string;
  preLoginMenu = [
    { id: 1, name: 'Courses', state: 'courses', positionRight: false },
    { id: 2, name: 'Directory of Services', state: 'services', positionRight: false },
    { id: 3, name: 'News', state: 'news', positionRight: false },
  ];
  signInFlag: boolean = false;
  hidePremenuList: boolean = false;
  currentRoute: string = '';
  fullname: string = '';
  isMenuOpen = false;
  userInitials: string;
  isMenuVisible: boolean = false;
  compareList: { id: string, name: string }[] = [];
  compareListNew: string[];
  private subscription: Subscription;
  aboutDropdownOpen = false;
  trainingDropdownOpen = false;
  profileDropdownOpen = false;
  isFormDirty: boolean = false;

  constructor(
    private readonly navigationService: NavigationService,
    public readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly srmService: SrmService,
    private readonly tabService: TabsService,
    private readonly notificationService: NotificationService,
    private readonly walletService: WalletService,
    private readonly togetherModeModalService: TogetherModeModalService,
    private readonly confirmService: ConfirmService,
    private readonly renderer: Renderer2,
    private readonly servicesService: servicesService,
    private readonly dialogRef: MatDialog,
    private readonly deletepopupService: deletePopUpService,
    private readonly confirm: ConfirmService,
  ) {
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.isMobileMenuExpanded = false;
        }
        if (event instanceof NavigationStart) {
          this.resetMenuIcon();
          (document.activeElement as HTMLElement).blur();
        }
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });

      this.servicesService.chatEvent$.subscribe(data => {
        if(data){
          this.loadCompareList();
        }
      });
      this.deletepopupService.isFormDirty$.subscribe((isDirty) => {
        this.isFormDirty = isDirty;
      });

  }

  ngOnInit() {
    this.loadCompareList();
    if (localStorage.getItem('landingPage')) {
      this.landingUrl = localStorage.getItem('landingPage');
    }
    if (localStorage.getItem('sessionToken')) {
      this.logoFlag = true;
    }
    this.authenticationService.currentLogo.subscribe(logo => {
      this.logo = logo + localStorage.getItem('logoPath');
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.resolveMenuItems();

    this.resolveLogoData();

    this.resolveOtherData();

    this.resolveUserName();

    this.resolveUserInitials();

    this.resolveChatUrl();

    this.togetherModeModalService.resolveTogetherModeStatus();
    this.authenticationService.getSignInFlag().subscribe(flag => {
      this.signInFlag = flag;
    });
  }

  loadCompareList(): void {
    const compareIds = JSON.parse(localStorage.getItem('compareCourses') || '[]');
    this.compareList = [];
    compareIds.forEach((courseId: string) => {
      this.servicesService.getCoursesView(courseId).subscribe((result: any) => {
        this.compareList.push({ id: courseId, name: result.body.data.attributes.courseName });
      });
    });
  }

  toggleMenu1(): void {
    this.isMenuVisible = !this.isMenuVisible;
  }

  removeFromCompare(courseId: string): void {
    let compareList = JSON.parse(localStorage.getItem('compareCourses') || '[]');
    compareList = compareList.filter((id: string) => id !== courseId);
    localStorage.setItem('compareCourses', JSON.stringify(compareList));
    this.servicesService.emitEvent('remove');
  }

  navigateToCompare(): void {
    this.router.navigate(['/compare-courses']);
  }

  resolveMenuItems() {
    if (localStorage.getItem('sessionToken')) {
      this.logoFlag = true;
      this.authenticationService.getAppFeatures().subscribe(data => {
        this.navigation = data;
      });
    }
  }

  resolveOtherData() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.keyword) {
        this.keyword = decodeURIComponent(params.keyword);
      }
    });
    this.tabService.chatFocus.subscribe(flag => {
      this.chatFocus = flag;
    });


    this.tabService.hideChatCount.subscribe(flag => {
      this.chatCount = 0;
    });
    this.tabService.showManuItem.subscribe(flag => {
      this.showManuItem = flag;
    });
    if (localStorage.getItem("sessionToken")) {
      this.walletService.getNotificationCount().subscribe(response => {
        this.walletFlag = response.walletFlag;
        if (response.systemIntroductionFlag === true && this.authenticationService.hasResource([FeatureConstants.Initial_Assessment_Feature_Id])) {
          if (this.router.url != '/programme/start-assessment') {
            this.router.navigateByUrl('/programme');
          }
        }
      });
    }

    this.walletService.walletFlag.subscribe(response => {
      this.walletFlag = response;
    });

    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }

  resolveLogoData() {
    this.authenticationService.currentLogo.subscribe(logo => {
      if (Url.CLIENT_URL === 'test-c2c.captr.online') {
        this.logo = localStorage.getItem('logoPath');
      } else {
        this.logo = logo + localStorage.getItem('logoPath');
      }
    });
  }

  resolveChatUrl() {
    if ((this.router.url === '/chat') || (this.router.url === '/srm/new-chat')) {
      this.toChat = true;
    }
    if (this.authenticationService.isLoggedIn) {
      this.serviceUserLoggedIn = true;
    } else {
      this.serviceUserLoggedIn = false;
    }
  }

  darkmode() {
    const element = document.body;
    element.classList.toggle('dark-mode');

    if (localStorage.getItem('darkmode') === 'true') {
      localStorage.setItem('darkmode', 'false');
    } else {
      localStorage.setItem('darkmode', 'true');
    }
  }

  onload() {
    document.body.classList.toggle('dark-mode', localStorage.getItem('darkmode') === 'true');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'changeCaseOfTitle');
  }

  getRoute() {
    if (this.chatFocus) {
      return 'active';
    }
    else {
      return '';
    }
  }
  onSignOut() {
    this.deletepopupService.emitSignOutEvent(false);
    this.tabService.emitSignOutClicked(true);
    const isTogetherModeAssessment = this.togetherModeModalService.getTogetherModeEnabledStatus()
    const isAssessmentInProgress = JSON.parse(sessionStorage.getItem('isAssessmentChanged'));
    if (isTogetherModeAssessment && isAssessmentInProgress) {
      this.confirmService.confirm({
        message: 'Please save your progress before exiting together mode',
        header: 'Progress not saved',
        showClose: false,
      });
    } else if (!isTogetherModeAssessment && isAssessmentInProgress) {
      this.confirmService.confirm({
        message: 'You have not saved your progress, do you want to continue without saving?',
        header: '',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => this.signOut(),
        reject: () => this.confirmService.choose(false),
      });
     } else if ( this.isFormDirty){
      // No operation, just to block the 'else' block when form is dirty
     }  else{
      this.signOut();
      this.compareList = [];
    }
  }

  onClick() {
    this.isChatWindow = false;
  }
  signOut() {
    this.togetherModeModalService.setTogetherModeFalse();
    this.dialogRef.closeAll();
    this.authenticationService.signout().subscribe();
  }
  resetMenuIcon() {
    const testMenuIcon = document.getElementById('icon-menu');
    if (testMenuIcon?.classList.contains('rotateIcon')) {
      testMenuIcon?.classList.remove('rotateIcon');
    }
  }

  onMenuIconClick() {
    const menuIconStatus = document.getElementById('icon-menu');
    this.isMobileMenuExpanded = !this.isMobileMenuExpanded;
    if (!this.isUserMenuExpanded) {
      this.isUserMenuExpanded = true;
    }
    if (menuIconStatus.classList.contains('rotateIcon')) {
      menuIconStatus.classList.remove('rotateIcon');
    } else {
      menuIconStatus.classList.add('rotateIcon');

    }
  }

  onUserIconClick() {
    this.isUserMenuExpanded = !this.isUserMenuExpanded;
    this.isChatWindow = false;

  }

  resolveUserName() {
    if (localStorage.getItem('sessionToken')) {
      const parsedToken = JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])) || null;
      if (parsedToken) {
        this.username = parsedToken.firstName && parsedToken.lastName
          ? `${parsedToken.firstName} ${parsedToken.lastName}`
          : parsedToken.userName;
        this.fullname = parsedToken.fullName;
      }
    }
  }

  resolveUserInitials() {
    if (localStorage.getItem('sessionToken')) {
      const parsedToken = JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])) || null;
      if (parsedToken) {
        this.authenticationService.getLeaverLocation(parsedToken.userId).subscribe(resp => {
          if (resp.firstName && resp.lastName) {
            this.userInitials = `${resp.firstName.charAt(0)}${resp.lastName.charAt(0)}`.toUpperCase();
          } else if (resp.fullName) {
            const nameParts = resp.fullName.split(' ');
            this.userInitials = nameParts.map(name => name.charAt(0)).join('').toUpperCase();
          } else if (resp.userName) {
            this.userInitials = `${resp.userName.charAt(0)}`.toUpperCase();
          } else {
            this.userInitials = '';
          }
          localStorage.setItem('ctpContact', JSON.stringify(resp?.serviceLeaverDetailsViewDTO?.ctpContactName ? true : false))
          localStorage.setItem('ctpContactName', resp?.serviceLeaverDetailsViewDTO?.ctpContactName)
          localStorage.setItem('ctpContactId', resp?.ctpContact)
        })

      }
    }
  }


  showChat() {
    this.isChatWindow = !this.isChatWindow;
  }

  goToProfile() {
    this.router.navigate([`/user-profile`]);
  }

  clickedChat(event) {
    this.toChat = true;
  }

  onSearch() {
    if (this.keyword === null || this.keyword === "") {
      return;
    }
    this.keyword = this.keyword.trim();
    this.router.navigate([`/content/search`], { queryParams: { keyword: encodeURI(this.keyword).replace(/%20/g, ' ').replace(/%22/g, ' ').replace(/%5E/g, '^') } });
  }


  clickOnSigninPopup() {
    this.authenticationService.setSignInFlag(true);
    this.router.navigate(['/home/sign-in']);
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openChat() {
    if (this.authenticationService.isLoggedIn) {
      this.openmsg.emit("openChat");
    } else {
      this.router.navigate(['/home/sign-in']);
    }
  }
  goToTransition() {
    this.router.navigate(['/home/sign-in']);
  }
  toggleDropdown(event: KeyboardEvent, dropdown: string) {
    event.preventDefault();

    if (dropdown === 'aboutDropdownOpen') {
      this.aboutDropdownOpen = !this.aboutDropdownOpen;
      this.trainingDropdownOpen = false;
      this.profileDropdownOpen = false;
    } else if (dropdown === 'trainingDropdownOpen') {
      this.trainingDropdownOpen = !this.trainingDropdownOpen;
      this.aboutDropdownOpen = false;
      this.profileDropdownOpen = false;
    } else if (dropdown === 'profileDropdownOpen') {
      this.profileDropdownOpen = !this.profileDropdownOpen;
      this.aboutDropdownOpen = false;
      this.trainingDropdownOpen = false;
    }
  }

  onFocusOut(event: FocusEvent) {
    const dropdown = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (!dropdown.contains(relatedTarget)) {
      this.aboutDropdownOpen = false;
      this.trainingDropdownOpen = false;
      this.profileDropdownOpen = false;
    }
  }
  removePopup() {

  }
  isCourseRouteActive(): boolean {
    const url = this.router.url;
    return url.startsWith('/home/list-courses') || url.startsWith('/home/view-course') ||
      url.startsWith('/home/list-events') || url.startsWith('/home/view-event') ||
      url.startsWith('/home/list-workshops') || url.startsWith('/home/view-workshop') ||
      url.startsWith('/home/workshop-search') || url.startsWith('/home/event-search') || url.startsWith('/home/course-search')
  }

  isDosRouteActive(): boolean {
    const url = this.router.url;
    return url.startsWith('/home/directory-of-services') || 
    url.startsWith('home/directory-of-services/find-services')
  }

}




