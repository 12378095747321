<ng-container>
  <div class="w-full bg-[#331643]">
    <div class="w-full home_sub_container">
      <div class=" my-16 max_container flex justify-center">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:px-8 xl:px-0 lg:grid-cols-6 gap-2 sm:gap-8">
          <div>
            <div class="footer_subtitle">About CTP</div>
            <div class="space-y-3">
              <a class="footer_link" [routerLink]="['/service-leavers']"
                >I’m a Service leaver
              </a>
              <a class="footer_link" [routerLink]="['/training-providers']"
                >I’m a Training Provider
              </a>
              <a class="footer_link" [routerLink]="['/employers']">I’m an Employer </a>
            </div>
          </div>
          <div>
            <div class="footer_subtitle">Training & Events</div>
            <div class="space-y-3">
              <a class="footer_link" [routerLink]="['/home/list-courses']">Courses </a>
              <a class="footer_link" [routerLink]="['/home/list-events']">Events </a>
              <a class="footer_link" [routerLink]="['/home/list-workshops']">Workshops </a>
            </div>
          </div>
          <div>
            <div class="footer_subtitle">Jobs</div>
            <div class="space-y-3">
              <a class="footer_link" [routerLink]="['/home/jobs/jobs-list']">All Jobs </a>
              <a class="footer_link" [routerLink]="['/home/jobs/jobs-list']"
                [queryParams]="{ 'job[refinementList][ref_shared_industries.name][]': ['Defence', 'Security'] }">Defence and Security
              </a>
              <a class="footer_link" [routerLink]="['/home/jobs/jobs-list']"
                [queryParams]="{ 'job[refinementList][ref_shared_industries.name][]': ['Logistics', 'Transport', 'Transport, Logistics'] }">Transport and Logistics </a>
              <a class="footer_link" [routerLink]="['/home/jobs/jobs-list']" [queryParams]="{ 'job[refinementList][ref_shared_industries.name][]': ['Building, Construction', 
                            'Engineering', 
                            'Engineering - Civil', 
                            'Engineering - Domestic', 
                            'Engineering - Electrical', 
                            'Engineering - Electronics'] }">Construction and Engineering </a>
              </div>
          </div>
          <div>
            <div class="footer_subtitle">Sector Guides</div>
            <div class="space-y-3">
              <a class="footer_link" [routerLink]="['/sector-guides']">All guides</a>
              <a class="footer_link" [routerLink]="['/sector-guides/logistics-view']">Logistics</a>
              <a class="footer_link" [routerLink]="['/sector-guides/security-view']">Security</a>
              <a class="footer_link" [routerLink]="['/sector-guides/charity-view']">Charity</a>
            </div>
          </div>
          <div>
            <div class="footer_subtitle">Resources</div>

            <div class="space-y-3">
              <a class="footer_link" [routerLink]="['/home/directory-of-services']"
                >Directory of Services
              </a>
              <a class="footer_link"  [routerLink]="['/home/articles-guide']">Articles & Guides</a>
            </div>
          </div>
          <div>
            <div class="footer_subtitle">Legal</div>
            <div class="space-y-3">
              <a class="footer_link" href="../../../../assets/Terms&ConditionsPolicy/modctp.co.uk ts and cs_Website_Terms_Conditions.pdf" target="_blank">Website Terms & Conditions </a>
              <a class="footer_link" href="../../../../assets/Terms&ConditionsPolicy/CTP - Courses Terms and Conditions.pdf" target="_blank">Course Booking Terms & Conditions</a>
              <a class="footer_link" href="../../../../assets/CTP October Privacy Policy v1.1 1_Onboarding.pdf" target="_blank">Privacy policy</a>
              <a class="footer_link" href="../../../../assets/NCF Privacy Policy v1.1.pdf" target="_blank">Non-Contract-Funded Training Privacy Policy </a>
              <a class="footer_link" href="../../../../assets/CTP Employer Privacy Policy 1.pdf" target="_blank">Employer Privacy Policy  </a>
              <a class="footer_link" [routerLink]="['/contact-reed']">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_sub_container w-full">
      <div class="max_container mb-12 pt-8 px-6">
        <hr class="border border-[#562672]"/>
        <div class="flex justify-between items-center xl:px-0 mt-8">
          <div class="text-left cursor-pointer flex lg:block items-center" [routerLink]="['/home']">
            <img src="../../../../assets/images/Header-Footer_SVG/CTP-Logo-white.svg" alt="footer-logo">
          </div>
          <div class="mt-4 md:mt-0">
            <div class="flex space-x-2"><img src="../../../../../assets/images/Courses_SVG/visa.svg" alt="visa" class="w-w-11 h-8">
                <img src="../../../../../assets/images/Courses_SVG/mastercard.svg" alt="mastercard" class="w-11 h-8"></div>
        </div>
          
        </div>
        <div class="text-[#FBF6FE] font-normal text-base text-left mt-4 lg:mt-8 flex lg:block justify-center items-center">
          © Reed in Partnership Limited. All rights reserved.
        </div>
        <div class="text-[#FBF6FE] font-normal text-base text-left mt-4 lg:mt-8 lg:block justify-center items-center">
          Contains public sector information licensed under the <span class="underline">Open Government Licence v3.0</span> including images that are UK MOD © Crown copyright
        </div>
      </div>
    </div>
  </div>
</ng-container>
