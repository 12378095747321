import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'vc-wind-energy',
  templateUrl: './wind-energy.component.html'
})
export class WindEnergyComponent {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Wind Turbine Technician',
      description: `Responsible for the maintenance and repair of wind turbines, troubleshooting mechanical and electrical issues, and ensuring that the turbines operate efficiently. This role requires technical skills in mechanics and electronics, strong problem-solving abilities, and physical fitness for climbing turbines. Salaries usually range between £28k and £41k+.`
    },
    {
      title: 'Wind Energy Engineer',
      description: `Is responsible for designing and developing wind turbines and wind farms, optimising turbine performance, and conducting site assessments. This role requires an engineering degree in mechanical, electrical, or civil engineering, strong analytical skills, and proficiency in CAD software. The average salary is around £44k+ with entry level positions starting from £23k.`
    },
    {
      title: 'Project Manager',
      description: `In the wind energy sector is responsible for overseeing the development and construction of wind farms, managing budgets and timelines, and coordinating with various stakeholders. This role requires project management experience, strong organisational skills, and the ability to manage teams and resources effectively. Salaries can range between £50k and £70k+ depending on experience.`
    },
    {
      title: 'Environmental Scientist',
      description: `Has many responsibilities including assessing the environmental impact of wind energy projects, ensuring compliance with regulations, and conducting wildlife and habitat studies. This role requires a background in environmental science, knowledge of regulatory requirements, and strong research skills. Salaries can range from £21k to £43k depending on experience and role requirements. `
    },
    {
      title: 'Sales And Marketing Specialist',
      description: `Within the wind energy sector is responsible for promoting wind energy products and services, developing marketing strategies, and building relationships with clients and stakeholders. This role requires sales and marketing experience, excellent communication skills, and knowledge of the renewable energy market. The average salary exceeds £48k.`
    },
    {
      title: 'Policy Analyst',
      description: `Is responsible for analysing and developing policies related to wind energy, advocating for renewable energy initiatives, and staying informed about regulatory changes. This role requires strong analytical and research skills, an understanding of energy policy, and excellent written and verbal communication abilities. Average salaries for this role are usually around £50k depending on experience.`
    },
    {
      title: 'Data Analyst',
      description: `Within the wind energy sector is responsible for analysing data from wind turbines to optimise performance, predicting energy output, and identifying trends and patterns. This role requires proficiency in data analysis tools, strong mathematical skills, and the ability to interpret complex data sets. The average salary is approximately £29k.`
    },
    {
      title: 'Operations Manager',
      description: `Has key responsibilities such as managing the day-to-day operations of wind farms, ensuring efficient energy production, and maintaining safety standards. This role requires experience in operations management, strong leadership skills, and knowledge of wind energy systems. Depending on experience and level of responsibility, salaries can vary from £27k and exceed £75k.`
    }
  ];

  constructor(
    private readonly title: Title,
  ) {
    this.title.setTitle('Wind energy');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
  }

  nextSlide(track: HTMLElement): void {
    const slidesToShow = 2;
    const maxIndex = this.slides.length - 1;

    if (this.currentSlideIndex < maxIndex) {
      this.currentSlideIndex++;
      this.moveToSlide(track);
    }
   
  }

  prevSlide(track: HTMLElement): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track);
    }
  }

  moveToSlide(track: HTMLElement): void {
    const isMobileView = window.innerWidth <= 768;
    const trackWidth = track.clientWidth;
    const isLastSlide = this.currentSlideIndex === this.slides.length - 1;
    const isSecondLastSlide = this.currentSlideIndex === this.slides.length - 2;

    if (isMobileView) {

      const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955);
      track.style.transform = `translateX(${amountToMove}px)`;
    } else {
      if (isLastSlide || isSecondLastSlide) {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;
      } else {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;

        const slides = document.querySelectorAll('.carousel-slide') as NodeListOf<HTMLElement>;
        slides.forEach(slide => slide.classList.remove('next-slide'));

        if (this.currentSlideIndex < this.slides.length - 1) {
          slides[this.currentSlideIndex + 1].classList.add('next-slide');
        }
        if (screen.width < 480) {
          const amountToMove = -(this.currentSlideIndex * (trackWidth * 1));
          track.style.transform = `translateX(${amountToMove}px)`;
        }
      }
    }
    this.updatePagination();
  }
  setupPagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    pagination.innerHTML = '';

    this.slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index));
      pagination.appendChild(bullet);
    });

    this.updatePagination();
  }
  goToSlide(index: number): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    this.currentSlideIndex = index;
    this.moveToSlide(track);
  }
  updatePagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    const bullets = Array.from(pagination.children) as HTMLElement[];

    bullets.forEach((bullet, index) => {
      if (index === this.currentSlideIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }
}
