import * as moment from 'moment';
import { ApplicationConstant } from '../constants/app-constant';
import { UntypedFormGroup } from '@angular/forms';
import * as jsonDataDev from 'src/assets/pageTitle.json';
import * as jsonDataTest from 'src/assets/pageTitleTest.json';
import * as jsonDataProd from 'src/assets/pageTitleProd.json';
import * as jsonDataPreprod from 'src/assets/pageTitlePreprod.json';
import { environment } from 'src/environments/environment';

export class Utility {
    static filterArrayByKeyAndValue(items: any[], key: any, value: any): any[] {
        return items.filter(function (item) {
            return item[key] === value;
        });
    }

    static filterArrayByKeyAndArray(items: any[], key: any, value: any[]): any[] {
        return items.filter(function (item) {
            return value.includes(item[key]);
        });
    }

    static inverseFilterArrayByKeyAndArray(items: any[], key: any, value: any[]): any[] {
        return items.filter(function (item) {
            return !value.includes(item[key]);
        });
    }

    static findIndexByKeyAndValue(items: any[], key: any, value: any) {
        for(let i = 0; i < items.length; i += 1) {
            if(items[i][key] === value) {
                return i;
            }
        }
        return -1;
    }

    static getObjectFromArrayByKeyAndValue(items: any[], key: any, value: any): any {
        const list = items.filter(function (item) {
            return item[key] === value;
        });
        if (list.length === 1) {
            return list[0];
        }
        if (list.length <= 0) {
            return null;
        }
        if (list.length > 1) {
            throw new Error('The specified array has multiple objects with key: ' + key + ' and value: ' + value);
        }
        return null;
    }

    static isEmpty(value: string): boolean {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return false;
    }

    static replaceString(regex: string, relaceString: string, value: string) {

        if (Utility.isEmpty(value)) {
            return null;
        }
        return value.replace(regex, relaceString);
    }
    static transformDateToString(date) {
        if (date && moment.isMoment(date)) {
            return date.format();
        } else if (date && !moment.isMoment(date)) {
            return this.transformStringToMomentDate(date).format(ApplicationConstant.DEFAULT_DATE_FORMAT);
        } else {
            return date;
        }
    }
    static transformStringToDate(date: string) {
        moment.locale('en-gb');
        return moment(date, ApplicationConstant.DEFAULT_DATE_FORMAT).toDate();
    }

    static transformStringToMomentDate(date: string) {
        moment.locale('en-gb');
        return moment(date, ApplicationConstant.DEFAULT_DATE_FORMAT);
    }

    static compareTwoDates(firstDate: Date , secondDate: Date , addDays?) {
        if (addDays) {
            firstDate.setDate(firstDate.getDate() + addDays );
            if (firstDate.getTime() > secondDate.getTime()) {
                return true;
            } else {
                return false;
            }
        } else if (firstDate.getTime() > secondDate.getTime()) {
           return true;
        } else {
            return false;
        }
    }

    static setValidators(form: UntypedFormGroup, validators: any): void {
        Object.keys(form.controls).forEach(name => {
            if (validators[name]) {
                const control = form.get(name);
                if (control) {
                    control.setValidators(validators[name]);
                    control.updateValueAndValidity();
                    control.markAsTouched();
                }
            }
        });
    }
    
    static clearAllValidatorsAndErrors(form: UntypedFormGroup): void {
        Object.keys(form.controls).forEach(name => {
            const control = form.get(name);
            control.clearValidators();
            control.setErrors(null);
            control.markAsTouched();
        });
    }

    static getPageTitleByClientIdAndFeatureId(featureId) {
        const clientId = +localStorage.getItem('clientId');
        const localJson = this.getJsonData();

        for (let i = 0; i < localJson.length; i++) {
            if (localJson[i].clientId === clientId) {
                const featureDetail = localJson[i].featureDetail
                for (let j = 0; j < featureDetail.length; j++) {
                    if (featureDetail[j].featureId === featureId) {
                        return featureDetail[j];
                    }
                }

            }
        }
    }

    private static getJsonData() {
        let localJson = null;


        switch (environment.env) {
            case ApplicationConstant.DEV_ENV:
                localJson = jsonDataDev;
                break;

            case ApplicationConstant.TEST_ENV:
                localJson = jsonDataTest;
                break;

            case ApplicationConstant.PROD_ENV:
                localJson = jsonDataProd;
                break;

            case ApplicationConstant.PREPROD_ENV:
                localJson = jsonDataPreprod;
                break;

            default:
                localJson = jsonDataDev;
                break;

        }
        return localJson;
    }
    static filterMapByKey( key: any,planKey:string): any[] {
        const storedData = localStorage.getItem(planKey);
        let refData   = JSON.parse(decodeURIComponent(storedData));
        if (refData && refData.refDataMap && refData.refDataMap[key]) {
            return refData.refDataMap[key];
          } else {
            return undefined; // Key not found
          }  
     }

}
