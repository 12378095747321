import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpUrlEncodingCodec } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthenticationDirective } from './directives/authentication.directive';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './services/notification.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { HttpRequestInterceptorModule } from './interceptors/request-interceptor.service';
import { MainComponent } from './components/main/main.component';
import { MainService } from './services/main.service';
import { FormsModule } from '@angular/forms';
import { ChatComponent } from './components/header/chat/chat.component';
import { BannerService } from './services/banner.service';
import { ConfirmService } from '../shared/components/confirm-box/confirm-box.service';
import { AuthorizationGuard } from './guards/authorization.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MaterialModule } from '../shared/material/material.module';
import { FileUploadService } from './services/file-upload.service';
import { SnackBarService } from './services/snackbar.service';
import { favouritesService } from './services/favourites.service';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { InstantSearchPaginationRendererComponent } from './components/paginator/instant-search-pagination-renderer/instant-search-pagination-renderer.component';
import { LearnDiscoverSharedComponent } from '../shared/components/learn-discover-shared/learn-discover-shared.component';


@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        NotificationComponent,
        AuthenticationDirective,
        MainComponent,
        ChatComponent,
        PaginatorComponent,
        InstantSearchPaginationRendererComponent,
        LearnDiscoverSharedComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        HttpRequestInterceptorModule,
        FormsModule,
        MatSnackBarModule,
        MaterialModule
    ],
    providers: [
        MainService,
        NotificationService,
        AuthenticatedGuard,
        HttpUrlEncodingCodec,
        BannerService,
        ConfirmService,
        AuthorizationGuard,
        FileUploadService,
        SnackBarService,
        favouritesService
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        MainComponent,
        NotificationComponent,
        AuthenticationDirective,
        PaginatorComponent,
        InstantSearchPaginationRendererComponent,
        LearnDiscoverSharedComponent
    ]
})
export class CoreModule { }
