import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { IndexWidget, Widget } from 'instantsearch.js';
import history from 'instantsearch.js/es/lib/routers/history';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import { Router } from '@angular/router';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

const { searchClient, setMeiliSearchParams } = instantMeiliSearch(
  environment.instantSearchHost, environment.instantSearchApiKey,
  { keepZeroFacets : true,
    finitePagination: false,

   }
)

@Injectable({
  providedIn: 'root',
})
export class SharedFeaturedArticlesServiceService {
  public instantSearchInstance: InstantSearch;
  private hasRefinementsSubject = new BehaviorSubject<boolean>(false);
  hasRefinements$ = this.hasRefinementsSubject.asObservable();

  constructor(router: Router) {
    this.instantSearchInstance = new InstantSearch({
      searchClient,
      indexName: 'news-article',
      future: { preserveSharedStateOnUnmount: true }
    });
  }

  start() {
    this.instantSearchInstance.start();
  }

  dispose() {
    this.instantSearchInstance.dispose();
  }

  addWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.addWidgets(widgets);
  }

  removeWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.removeWidgets(widgets);
  }
  updateHasRefinements(hasRefinements: boolean) {
    this.hasRefinementsSubject.next(hasRefinements);
  }
}
