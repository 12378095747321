import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'vc-charity-view',
  templateUrl: './charity-view.component.html'
})
export class CharityViewComponent  {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Management / Administration / Operations',
      description: `Roles involve overseeing the day-to-day operations of the charity, ensuring everything runs smoothly. This can include roles like office managers, operations coordinators and administrative assistants.`
    },
    {
      title: 'Personnel and human resources',
      description: `Are crucial for managing the charity’s staff and volunteers, handling recruitment, training and employee welfare.`
    },
    {
      title: 'Marketing, communications and campaigning',
      description: `Roles focus on raising awareness of the charity’s work, managing public relations, social media and organising campaigns to attract donations and support.`
    },
    {
      title: 'Volunteer management',
      description: `Involves recruiting, training and coordinating volunteers, ensuring they are effectively integrated in the charity’s activities. `
    },
    {
      title: 'Policy development and research',
      description: `Roles involve researching issues related to the charity’s mission, developing policies and advocating for change at various levels of government and society. `
    },
    {
      title: 'Fundraising',
      description: `Is essential for securing the financial resources needed to support the charity’s activities. This can include grant writing, organising fundraising events and building relationships with donors. `
    },
    {
      title: 'Grants Officers/Advisers',
      description: `These roles involve managing the distribution of grants, ensuring funds are allocated effectively and are in line with the charity’s goals.`
    },
    {
      title: 'Care and support',
      description: `Includes frontline roles providing direct support to the charity’s beneficiaries, such as social workers, counsellors and support workers.`
    }
  ];

  constructor(
    private readonly title: Title,
    private router: Router,
    public readonly authService: AuthenticationService,
  ) {
    this.title.setTitle('Charity');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
  }

  nextSlide(track: HTMLElement): void {
    const slidesToShow = 2;
    const maxIndex = this.slides.length - 1;

    if (this.currentSlideIndex < maxIndex) {
      this.currentSlideIndex++;
      this.moveToSlide(track);
    }
  }

  prevSlide(track: HTMLElement): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track);
    }
  }

  moveToSlide(track: HTMLElement): void {
    const isMobileView = window.innerWidth <= 768;
    const trackWidth = track.clientWidth;
    const isLastSlide = this.currentSlideIndex === this.slides.length - 1;
    const isSecondLastSlide = this.currentSlideIndex === this.slides.length - 2;

    if (isMobileView) {

      const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955);
      track.style.transform = `translateX(${amountToMove}px)`;
    } else {
      if (isLastSlide || isSecondLastSlide) {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;
      } else {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;

        const slides = document.querySelectorAll('.carousel-slide') as NodeListOf<HTMLElement>;
        slides.forEach(slide => slide.classList.remove('next-slide'));

        if (this.currentSlideIndex < this.slides.length - 1) {
          slides[this.currentSlideIndex + 1].classList.add('next-slide');
        }
        if (screen.width < 480) {
          const amountToMove = -(this.currentSlideIndex * (trackWidth * 1));
          track.style.transform = `translateX(${amountToMove}px)`;
        }
      }
    }
    this.updatePagination();
  }
  setupPagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    pagination.innerHTML = '';

    this.slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index));
      pagination.appendChild(bullet);
    });

    this.updatePagination();
  }
  goToSlide(index: number): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    this.currentSlideIndex = index;
    this.moveToSlide(track);
  }
  updatePagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    const bullets = Array.from(pagination.children) as HTMLElement[];

    bullets.forEach((bullet, index) => {
      if (index === this.currentSlideIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }

  goToJobs() {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
    } else {
      this.router.navigate(['home', 'jobs', 'jobs-list'])
    }
  }
}
