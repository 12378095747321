import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Url } from 'src/app/shared/constants/url-constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authToken = `${Url.MILISEARCH_API_KEY}`; 
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(`${Url.MILISEARCH_API_URLS}`)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
