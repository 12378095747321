<div *ngIf="compareList.length > 0">
  <h2 class="text-3xl font-bold mb-12 text-center">Compare {{compareList.length}} courses</h2>
</div>
<div class="container mx-auto p-4 flex justify-center">

  <div *ngIf="compareList.length === 0" class="text-center text-gray-600 bg-[#F9FAFB] max-w-[460px] max-h-[310px]">
    <div class="bg-gray-100 p-6 rounded-lg">
      <button class="relative rounded-full border" type="button">
        <img src="../../../../assets/images/Header-Footer_SVG/switch-horizontal-01.svg" alt="switch" class="p-2.5">
        <span *ngIf="compareList.length > 0" class="item-count-badge bg-purple-600">{{ compareList.length }}</span>

      </button>
      <p class="text-xl font-medium text-gray-700 mb-4">Your compare list is empty</p>
      <p class="text-gray-500 mb-6">Find courses to compare, select 2 or 3 courses, and find the right one for you.</p>
      <button (click)="navigateToCourses()"
        class="bg-purple-700 text-white font-semibold py-2 px-4 rounded hover:bg-purple-800 transition duration-300">
        Find Courses
      </button>
    </div>
  </div>
  <div *ngIf="compareList.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    <div *ngFor="let course of compareList" class="rounded-lg p-6 relative bg-[#F9FAFB]">
      <button type="button" (click)="removeFromCompare(course.id)" aria-label="close"
        class="absolute top-2 right-2">
        <img src="../../../../../assets/icons/x-close.svg" alt="close_icon">
      </button>

      <h3 class="text-xl font-semibold mb-4 h-12 text-center">{{ course?.name }}</h3>
      <div class="space-y-2">
        <p class="border p-4 rounded-md flex flex-col text-sm">
          <span>Course Cost</span>
          <span class="text-base font-bold">{{ course?.cost || 'N/A'}}</span>
        </p>
        <p class="border p-4 rounded-md flex flex-col text-sm">
          <span>Funding Type</span>
          <span class="text-base font-bold">{{ course?.fundingType || 'N/A'}}</span>
        </p>
        <p class="border p-4 rounded-md flex flex-col text-sm">
          <span>Next available course duration (days)</span>
          <span class="text-base font-bold">{{ course?.seatsAvailable || 'N/A'}}</span>
        </p>
        <p class="border p-4 rounded-md flex flex-col text-sm">
          <span>Next available start date</span>
          <span class="text-base font-bold">{{ course?.nextStartDate || 'N/A'}}</span>
        </p>
        <p class="border p-4 rounded-md flex flex-col text-sm">
          <span>Next available Location</span>
          <span class="text-base font-bold">{{ course?.location || 'Online'}}</span>
        </p>
        <div class="border p-4 rounded-md flex flex-col text-sm">
          <span>Qualifications</span>
          <ul class="list-disc pl-5">
            <li class="font-bold mb-1 list-decimal" *ngFor="let qual of course?.qualifications">
              {{ qual?.name || 'N/A' }}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-between mt-6 flex-col">
        <button type="button" class="form_button mb-4"
        (click)="viewCourse(course.id)">
        View Course
        </button>
        <button *ngIf="!isCourseFavorite(course?.id)" type="button" class="form_button bg-white"
        (click)="$event.stopPropagation();favouritesSection('add',course?.id)">
        Save to My Transition
        </button>
        <button *ngIf="isCourseFavorite(course?.id)" type="button" class="form_button bg-white"
        (click)="$event.stopPropagation();favouritesSection('remove',course?.id)">
        Remove from My Transition 
        </button>
      </div>
    </div>
  </div>
</div>