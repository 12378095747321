import { Injectable, NgZone, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Url } from '../shared/constants/url-constants';
import { Base64 } from 'js-base64';
import { ApplicationConstant } from '../shared/constants/app-constant';
import { SharedService } from '../shared/service/shared.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ImageUrl } from '../shared/constants/image--url-constant';
import { environment } from 'src/environments/environment';
import { IAppFeatures } from '../core/components/header/AppFeatures';
import { Subject } from 'rxjs';

const authHeaders = new HttpHeaders({
  'Content-Type': 'application/json'
});
const exposeXAuthHeader = new HttpHeaders({
  'Access-Control-Expose-Headers': 'X-Authorization',
});

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  private readonly logoUrl = new BehaviorSubject<string>(ImageUrl.LOGO_IMAGE);
  currentLogo = this.logoUrl.asObservable();
  private signInFlagSubject = new BehaviorSubject<boolean>(false);

  private chatEventSubject = new Subject<any>();
  chatEvent$ = this.chatEventSubject.asObservable();
  
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly sharedserive:SharedService
  ) {}

   get isLoggedIn() {
    return localStorage.getItem('sessionToken') ? true : false;
  }

  signIn(formData: any): any {
    formData.appId = localStorage.getItem('ApplicationID')
    formData.clientId = localStorage.getItem('clientId');

    const body = JSON.stringify(formData);
    return this.http.post<any>(`${Url.USER}/login`, body, { headers: authHeaders, observe: 'response' })
      .pipe(
        tap(resp => {
          this.setAuth(resp);
        }));
  }

  signout() {
    return this.http.get(`${Url.USER}/logoutUser`)
      .pipe(finalize(() => this.purgeAuth()));
  }

  refreshToken() {
    return this.http.get<any>(`${Url.AUTHENTICATION}/refreshToken`, { observe: 'response' })
      .pipe(
        tap(resp => {
          this.setAuth(resp);
        }));
  }

  fetchGenders() {
    const href = `${Url.USER}/serviceUser/genders`;
    return this.http.get<any[]>(href);
  }

  fetchOrganizations() {
    const href = `${Url.USER}/manageOrganization/getAllOrginizations`;
    return this.http.get<any>(href, { params: new HttpParams().set('clientId', localStorage.getItem('clientId')) });
  }

  signUp(payload: any) {
    payload.appId = localStorage.getItem('ApplicationID')
    payload.clientId = localStorage.getItem('clientId');
    return this.http.post<any>(`${Url.USER}/serviceUser/self-register`, payload, { observe: 'response' });
  }

  validatePasswordLink(payload: any) {
    payload.appId = localStorage.getItem('ApplicationID')
    payload.clientId = localStorage.getItem('clientId');
    const href = `${Url.USER}/user/validatePasswordLink`;
    return this.http.post<any>(href, payload);
  }

  validateAndSavePassword(payload) {
    payload.appId = localStorage.getItem('ApplicationID')
    payload.clientId = localStorage.getItem('clientId');
    const href = `${Url.USER}/user/validateAndSavePassword`;
    return this.http.post<any>(href, payload);
  }

  forgotPassword(payload) {
    const href = `${Url.USER}/user/forgotPassword`
    return this.http.post<any>(href, payload);
  }

  private purgeAuth() {
 
    this.router.navigate([`/home`]) 
    .then(() => {
      window.location.reload();
   })
   
      let darkmodeConfig = localStorage.getItem('darkmode');
      localStorage.clear();
      localStorage.setItem('darkmode',darkmodeConfig);
  }

  private setAuth(resp) {
    const xAuthToken = resp.headers.get('X-Authorization').split(' ');
    const token = xAuthToken[1];
    localStorage.setItem('sessionToken', token);
    if (resp.body != null && resp.body.heartbeat) {
      localStorage.setItem('sessionParams', Base64.encode(JSON.stringify(resp.body)));
    }
    const payload = JSON.parse(atob(token.split('.')[1]));

    const parsedToken = JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])) || null;
    this.getRefDataForReedCtp().subscribe();
    this.getLeaverLocation(parsedToken.userId).subscribe(
      (response: any) => {
        localStorage.setItem('ctpContact', JSON.stringify(response?.serviceLeaverDetailsViewDTO?.ctpContactName ? true : false))
        localStorage.setItem('ctpContactName', response?.serviceLeaverDetailsViewDTO?.ctpContactName)
        localStorage.setItem('ctpContactId', response?.ctpContact)
        localStorage.setItem('programmeName',response?.serviceLeaverDetailsViewDTO?.entitlementToCTPServiceName);
        this.rrcnameIdentifier().subscribe(
          (resp: any) => {
            let rrcName = resp.filter(item=>item.id === response?.rrcName)[0]?.description;
            localStorage.setItem('locationDetails', JSON.stringify({desiredResettlementCountry:response?.desiredResettlementCountry,
              desiredResettlementRegion:response?.desiredResettlementRegion,rrcName:rrcName}));
          }
        );
      }
    );
    //this.sharedserive.register(payload.userId, 'SESSION').subscribe();
  }

  getRefDataForReedCtp() {
    const href = `${Url.USER}/ref-data-choice/all`;
    return this.http.get<any>(href).pipe(
      tap(data => {
       const jsonData= JSON.stringify(data)
       const encodedData = encodeURIComponent(jsonData)
        localStorage.setItem('refData_ServiceLeaver', encodedData);
      })
    );
  }

  getLeaverLocation(id){
      const href = `${Url.USER}/service-leaver-details/${id}`;
      return this.http.get<any>(href);
  }
  rrcnameIdentifier(){
    const href = `${Url.USER}/ref-data-choice/refDataIdentifier/RRCN`;
    return this.http.get<any>(href);
  }
  currentUser() {
    if (localStorage.getItem('sessionToken')) {
      const payload = atob(localStorage.getItem('sessionToken').split('.')[1]);
      return JSON.parse(payload);
    }
    return null;
  }

  noticeBoardMessage() {
    const data = {"appId" : localStorage.getItem('ApplicationID') , "clientId" : localStorage.getItem('clientId')}; 
    const href = `${Url.AUTHENTICATION}/notification/notice`;
    return this.http.post<any>(href, data);  
}
  
moodleLogin(){
  const href = `${Url.MOODLE_API}/login/`;
  return this.http.get<any>(href);
}


fetchOffices(orgUnitId :number) {
  const href = `${Url.USER}/probationService/offices`;
  return this.http.get<any[]>(href , {
    params : new HttpParams().set('orgUnitId',orgUnitId)
  });
}

fetchRegions(){
  const href = `${Url.USER}/probationService/regions`;
  return this.http.get<any[]>(href , {
    params : new HttpParams().set('clientId', localStorage.getItem('clientId'))
  });
}

getClientDetails() {
  const url = Url.CLIENT_URL;
  const href = `${Url.USER}/clients/getByUrl`;
  return this.http.get<any>(href, { params: new HttpParams().set('url', url) });
}

hasResource(auth: string[]) {

  if (localStorage.getItem('sessionToken') && auth) {
    const payload = atob(localStorage.getItem('sessionToken').split('.')[1]);
    const permissions = JSON.parse(payload).listResource;

    if (auth[1] && permissions.filter(feature => +feature.fid === +auth[0])[0]) {
      return !!permissions.filter(feature => +feature.fid === +auth[0])[0].opId.find(operation => +operation === +auth[1]);
    } else {
      return !!permissions.find(permission => +permission.fid === +auth[0]);
    }
  }
  return false;
}

getAppFeatures() {
  const href =  `${Url.USER}/menuDetails`;
   return this.http.get<IAppFeatures[]>(href);
}
setSignInFlag(flag: boolean) {
  this.signInFlagSubject.next(flag);
}

getSignInFlag() {
  return this.signInFlagSubject.asObservable();
}
emitEvent(data: any) {
  this.chatEventSubject.next(data);
}
}
