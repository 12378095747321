<div class="site-width-container">
    <form [formGroup]="messageForm">
        <div class="messages-page site-width-container px-2">
            <div id="messages" class="messages-container-header flex justify-between">
                <div class="header">
                    <h3 id="conversation_with" *ngIf="!selectedKw"
                        class="text-[#331643] font_hindguntur font-semibold text-xl"> Conversation with</h3>
                    <h3 id="conversation_with" *ngIf="selectedKw"
                        class="text-[#331643] font_hindguntur font-medium text-lg"> Conversation with {{selectedKw}}
                    </h3>
                </div>
                <button title="Cancel" (click)="cancelMessage()" id="button-cancel">
                    <img src="../../../../assets/images/ChatSVG/Button close X.svg" alt="button-close">
                </button>
            </div>

            <div id="messages-container" class="messages-container">
                <div class="message-list-left">
                    <div class=" kwTab">
                        <select class="govuk-select !border !rounded-lg !focus:outline-none !focus:ring-0"
                            id="kw_dropdown" name="userLists" (change)="updateReference($event.target.value)"
                            formControlName="keyWorker">
                            <option class="mh-40" value='0' id="select_kw">Select career consultant</option>
                            <option *ngFor="let user of userList;" [value]="user.id" id="kw_{{user.id}}">
                                {{user.userFullName}}</option>
                        </select>
                    </div>
                </div>

                <div class="message-panel-right">
                    <div class="bottom-contents">
                        <label for="text_area" class="hiddenForAT">message text field</label>
                        <input class="chat_send_inputbox" name="message" formControlName="message"
                            aria-label="messages" (keydown.enter)="sendMessage()"/>
                        <div class="button-alignment">
                            <button type="button" title="Send" id="button-send" class="!w-full sm:!w-fit"
                                [ngClass]="{ 'chat_send_button': messageForm.valid, 'chat_send_disable_button': !messageForm.valid }"
                                [disabled]="!messageForm.valid" (click)="sendMessage()">
                                <img src="../../../../assets/images/ChatSVG/send.svg" alt="send-button-2">
                                <span class="text-nowrap mt-[5px]">Send Message</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>