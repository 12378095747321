import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
//import { SessionsService } from '../../sessions/sessions.service';


@Injectable()
export class AuthorizationGuard  {

    constructor(
        protected router: Router,
        protected authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.authenticationService.hasResource(route.data['auth']) || this.authenticationService.hasResource(route.data['auth2nd'])) {
            return true;
        }

        //this.togetherModeModalService.setTogetherModeFalse();
        this.authenticationService.signout().subscribe();
        return false;
    }

    canActivateChild(childroute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.canActivate(childroute, state);
    }

}


