import { Url } from './../../shared/constants/url-constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private readonly http: HttpClient) {}

  getUserProfile() {
    const href = `${Url.USER}/serviceUser/getUserProfile`;
    return this.http.get<any>(href);
  }

  editServiceUser(payload) {
    const href = `${Url.USER}/serviceUser/update`;
    return this.http.put<any>(href, payload);
  }
  fetchGenders() {
    const href = `${Url.USER}/serviceUser/genders`;
    return this.http.get<any[]>(href);
  }
  changePassword(payload) {
    const href = `${Url.USER}/user/changePassword`;
    return this.http.put<any>(href, payload);
 }
  getUserLearnerInfo() {
    const href = `${Url.PROFILE}/su-learner/suLearnerInfo?id=`;
    return this.http.get<any>(href);
  }

  searchUsefulContacts(page: number, size:number, payload){
    const href = `${Url.USER}/usefulContact/su-search`;
    return this.http.post<any>(href, payload, {params: new HttpParams()
      .set('page', page)
      .set('size', size)});
  }

  sendPasswordChangeDetails(payload) {
    const href = `${Url.USER}/user/changeExpiredPassword`;
    return this.http.put<any>(href, payload);
  }

}
