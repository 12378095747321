import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ValidationService } from './../../../shared/components/form-control/validation.service';
import { ApplicationConstant } from '../../../shared/constants/app-constant';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { Title } from '@angular/platform-browser';
import { CHANGE_PASSWORD_VALIDATION_CONTEXT } from '../change-password/change-password.validation';
import { Router } from '@angular/router';

const CHANGE_PASSWORD_FORM_VALIDATORS = {
  existingPassword: Validators.required,
  newPassword: Validators.compose(
    [
      Validators.required, 
      Validators.maxLength(36), 
      Validators.minLength(14),
      Validators.pattern(/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\"#$%&\'\(\)\*+,-\.\/:;<=>\?@\[\\\]^_`{|}~])/)
     
    ]
  ),
  confirmPassword: Validators.compose(
    [
      Validators.required, 
      Validators.maxLength(36), 
      Validators.minLength(14),
      Validators.pattern(/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\"#$%&\'\(\)\*+,-\.\/:;<=>\?@\[\\\]^_`{|}~])/),
      ValidationService.matchSetPassword('newPassword', 'confirmPassword') 
     
    ]
  )
}

@Component({
  selector: 'vc-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent {
  changePasswordForm: UntypedFormGroup;
  @ViewChild('submitFormButton') submitFormButton: ElementRef;
  type="password";
  newType='password';
  confirmType='password';

  constructor(
    private readonly builder: UntypedFormBuilder,
    private readonly userProfileService: UserProfileService,
    private readonly notificationService: NotificationService,
    private readonly validationService: ValidationService,
    private readonly snackBar:SnackBarService,
    private readonly title:Title,
    private readonly router: Router,
  ) {
    this.validationService.setContext(CHANGE_PASSWORD_VALIDATION_CONTEXT);
    this.initForm();
  }
  ngOnInit(){
    this.title.setTitle("Change Password");
  }

  initForm() {
    this.changePasswordForm = this.builder.group({
      existingPassword: ['',Validators.required],
      newPassword: ['',[
        Validators.required, 
        Validators.maxLength(36), 
        Validators.minLength(14),
        Validators.pattern(/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\"#$%&\'\(\)\*+,-\.\/:;<=>\?@\[\\\]^_`{|}~])/)
       
      ]],
      confirmPassword: ['',[
        Validators.required, 
        Validators.maxLength(36), 
        Validators.minLength(14),
        Validators.pattern(/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\"#$%&\'\(\)\*+,-\.\/:;<=>\?@\[\\\]^_`{|}~])/),
        ValidationService.matchSetPassword('newPassword', 'confirmPassword') 
      ]],
    })
  }

  changePassword() {
    // this.clearAllValidatorsAndErrors(this.changePasswordForm);
    // this.setValidators(this.changePasswordForm, CHANGE_PASSWORD_FORM_VALIDATORS)
    if (this.changePasswordForm.pristine === false && this.changePasswordForm.valid) {
      const payload = {
        existingPassword: btoa(this.changePasswordForm.controls.existingPassword.value),
        newPassword: btoa(this.changePasswordForm.controls.newPassword.value),
        confirmPassword: btoa(this.changePasswordForm.controls.confirmPassword.value),
        appId: ApplicationConstant.ApplicationID,
        clientId: ApplicationConstant.clientId,
        username: localStorage.getItem('username')
      }
      this.userProfileService.sendPasswordChangeDetails(payload).subscribe(resp => {
        // this.notificationService.success([resp.applicationMessage], true);
        this.changePasswordForm.reset();
        this.changePasswordForm.get('existingPassword').setErrors(null);
        this.changePasswordForm.get('newPassword').setErrors(null);
        this.changePasswordForm.get('confirmPassword').setErrors(null);
        this.snackBar.successToast(resp?.applicationMessage);
        this.router.navigate(['/home/sign-in']);

      }, error => {
        this.setErrorsFromBackend(error);
        this.snackBar.errorToast(error?.error?.applicationMessage)
      })
    }
    window.scrollTo(0, 0);
  }//qwertyuiopoiuytrewq!2

  setValidators(form: UntypedFormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  setErrorsFromBackend(message) {
    switch (message.error.applicationMessage) {
      case 'Password does not match with existing password.':
        this.changePasswordForm.controls.existingPassword.setErrors({ existingPassInvalid: true })
        break;
      case 'Password is the same as your current password, please change it.':
        this.changePasswordForm.controls.newPassword.setErrors({ newPasswordSameAsCurrent: true })
        this.changePasswordForm.controls.confirmPassword.setErrors({ newPasswordSameAsCurrent: true })
        break;
      case 'Password has been used already, please choose another.':
        this.changePasswordForm.controls.newPassword.setErrors({ newPassUsedRecently: true })
        this.changePasswordForm.controls.confirmPassword.setErrors({ newPassUsedRecently: true })
        break;

      case 'New password is same as existing password.':
        this.changePasswordForm.controls.newPassword.setErrors({ newPassUsedRecently: true })
        this.changePasswordForm.controls.confirmPassword.setErrors({ newPassUsedRecently: true })
        break;
    }
  }

}
