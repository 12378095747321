import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  @Output() walletFlag: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly http: HttpClient) { }

  getNotificationCount() {
    const href = `${Url.USER}/notification`;
    return this.http.get<any>(href);
  }

  updateNotificationCount(payload: any): Observable<any> {
    const href = `${Url.USER}/notification`;
    return this.http.post(href, payload);
  }

  emitWalletNotification(flag) {
    this.walletFlag.emit(flag);
  }
  getUserBookings() {
    const href = `${Url.BOOKING}/dashBoard/SU/allBookings`;
    return this.http.get<any>(href);
  }


  sendWorkshopCancelRequest(payload): Observable<any> {
    const href = `${Url.BOOKING}/workshop-cancellation`;
    return this.http.post(href, payload);
  }

  sendEventCancelRequest(payload): Observable<any> {
    const href = `${Url.BOOKING}/event-cancel/cancel`;
    return this.http.put(href, payload);
  }

  sendCourseCancelRequest(payload): Observable<any> {
    const href = `${Url.BOOKING}/booking-course-cancellation/request`;
    return this.http.post(href, payload);
  }


  getCoursesList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/courses/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getEventsList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/events/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getArticlesList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/newsArticles/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getMyfavouriteJobs(collectionName, pageIndex, PageSize) {

    const href = `${Url.CMS_PROXY_API}/favourites/${collectionName}?page=${pageIndex}&size=${PageSize}`;

    return this.http.post<any>(href, collectionName);
  }
  getUserDetails(detailType,id) {
    const href = `${Url.USER}/${detailType}/${id}`;
    return this.http.get<any>(href);
  }
  getRefGraphQLList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getRefDataForReedCtp() {
    const href = `${Url.USER}/ref-data-choice/all`;
    return this.http.get<any>(href).pipe(
      tap(data => {
       const jsonData= JSON.stringify(data)
       const encodedData = encodeURIComponent(jsonData)
        localStorage.setItem('refData_ServiceLeaver', encodedData);
      })
    );
  }
  getSLSkillsDetails(id) {
    const href = `${Url.USER}/skillQualification/get/${id}`;
    return this.http.get<any>(href);
  }

  getPersonalStatementInfo() {
    const href = `${Url.CVB}/personal-statement`;
    return this.http.get(href);
  }

  savePreferredIndustries(payload) : Observable<any>{
    const href = `${Url.USER}/skillQualification/updatePreferredIndustries`;
    return this.http.put(href, payload);
  }

  getIndustiesPresent(id:any) {
    const href = `${Url.USER}/skillQualification/isIndustiesPresent/${id}`;
    return this.http.get<any>(href);
  }
}