<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4 lg:px-0">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Cyber Security</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            In today's digital age, technology is the backbone of almost every industry. From
                            smartphones to cloud computing, the influence of technology is omnipresent. For British
                            military Service leavers, transitioning to a civilian career in cyber security offers a
                            promising and dynamic pathway. This guide aims to provide a comprehensive overview of the
                            cyber security sector, highlighting the opportunities, necessary skills, and steps to embark
                            on a successful career in this field.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-3xl lg:text-4xl font-semibold font_inter leading-[44px] mb-3">
                            Why consider a
                            role in cyber security?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">The cyber security
                            sector is a cornerstone of the UK’s economy, crucial for protecting sensitive data and
                            systems across various industries. With the increasing reliance on digital infrastructure,
                            the demand for cyber security professionals is ever-growing, requiring more than 150,000 new
                            entrants annually. This high demand ensures a wealth of opportunities across various
                            sectors, including retail, finance, healthcare, and entertainment.
                            Cyber security is vital for safeguarding the digital economy, ensuring smooth business
                            operations, and protecting consumer data. With over 1.5 million employees in the broader IT
                            sector, cyber security professionals play a crucial role in maintaining the integrity and
                            security of digital systems. The need for skilled experts is rising due to the increasing
                            frequency and sophistication of cyber threats, making this dynamic field a promising career
                            path for those looking to make a significant impact on the UK’s digital landscape.
                        </div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/CyberSecurity/Cyber Security 1.jpg"
                                alt="pathway-img" />
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/CyberSecurity/Cyber Security 2.jpg"
                                alt="pathway-img" />
                        </div>
                        <div class="justify-start items-start gap-8 inline-flex">
                            <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                src="../../../../../assets/images/sector_pathway/images/CyberSecurity/Cyber Security 3.jpg"
                                alt="pathway-img" />
                            <div
                                class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                    <div class=" flex-col justify-start items-start flex">
                                        <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                            £55,000</div>
                                        <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                            Average salary</div>
                                    </div>
                                    <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                        Cyber security professionals in the UK enjoy competitive salaries, with entry-level positions ranging from £22k to
                                        £27k. Experienced professionals can earn significantly higher, with some salaries for cyber security in excess of
                                        £60K.</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div class="text-center text-[#331643] lg:text-5xl text-[32px] font-bold font_hindguntur leading-[64px]">
                                Is a career in cyber security for me?</div>
                            <div class="text-center text-[#4f3b5b] text-lg font-normal font_hindguntur leading-7">
                                Deciding if a career in cyber security is right for you depends on several factors. Here
                                are some aspects to consider:</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[rgb(51,22,67)] text-xl font-semibold font_hindguntur leading-loose">
                                            Skills and interests</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            An interest in technology, coding and problem-solving in technology, 
                                            coding, and problem-solving are key as the field requires
                                            a strong understanding of IT systems, networks, and software. If you
                                            enjoy analysing data and identifying patterns, you might find
                                            satisfaction in detecting and responding to threats. Additionally, a
                                            willingness to engage in continuous learning is essential, as the cyber
                                            security landscape is constantly evolving, necessitating staying updated
                                            with the latest trends and technologies.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Personality traits</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Cyber security requires meticulous
                                            attention to detail to identify vulnerabilities and 
                                            prevent breaches. A natural curiosity with a natural curiosity about how things work and how they can be
                                            exploited is also beneficial. Additionally, the ability to handle stress
                                            and respond calmly to incidents is important for success in this field.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Career goals</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Cyber security offers strong job security due to the high demand for
                                            skilled professionals in this growing field. With a variety of roles
                                            available, you can find a niche that suits your interests and skills.
                                            Additionally, if you want to make a significant impact by protecting
                                            organisations and individuals from cyber threats, this field can be very
                                            rewarding.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Education and training</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            To enhance your qualifications for cyber security roles, consider
                                            pursuing certifications such as CISSP, CEH, or CompTIA Security+.
                                            Additionally, many positions in this field require a degree in computer
                                            science, information technology, or a related discipline.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Work environment</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            The work environment within the cyber security field includes teamwork, which is essential as it often involves collaborating with other IT professionals.
                                            Additionally, be prepared for the possibility of irregular work hours,
                                            especially if you are involved in incident response. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-16 lg:my-24 flex items-center justify-center">
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
            class="hidden sm:flex" />
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
            class="sm:hidden" />
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container lg:mb-24 mb-16">
            <div class="max_container">
                <div class="justify-between items-start lg:flex block mb-16">
                    <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">What
                        roles are available in cyber security?</div>
                    <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                        <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">The cyber
                            security sector is rapidly growing, with increasing demand for skilled professionals to
                            protect sensitive information and systems from cyber threats. Service leavers can find
                            roles such as:</div>
                    </div>
                </div>
                <div class="w-full relative">
                    <div class="carousel-container">
                        <div class="carousel-track">
                            <div *ngFor="let slide of slides" class="carousel-slide">
                                <div
                                    class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                    <div
                                        class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                            <div
                                                class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                {{ slide.title }}
                                            </div>
                                            <div
                                                class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                {{ slide.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Carousel navigation buttons -->
                        <div class="button-container">
                            <button id="slider-button-left"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                        alt="arrow-left"></div>
                            </button>
                            <button id="slider-button-right"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                        alt="arrow-right"></div>
                            </button>
                        </div>

                        <!-- Pagination -->
                        <div class="carousel-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container bg-[#F9FAFB]">
            <div class="max_container">
                <div>
                    <div
                        class="lg:py-24 py-16 bg-gray-50 rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
                        <div class="self-stretch justify-between items-center lg:flex block">
                            <div class="max-w-[44rem] mb-8">
                                <div class="text-[#331643] text-[32px] font-bold font_hindguntur leading-[48px]">
                                    Featured jobs</div>
                                <div class="text-[#4f3b5b] text-base font-normal font_hindguntur leading-normal">Cyber
                                    security professionals in the UK enjoy competitive salaries, with entry-level
                                    positions ranging from £22k to £27k. Experienced professionals can earn
                                    significantly higher, with average salaries for cyber security in excess of £60k.
                                    Jobs within the cyber security industry include:</div>
                            </div>
                            <div
                                class="px-4 py-2.5 bg-white rounded-full shadow border border-[#d2d6db] justify-center items-center gap-1.5 flex">
                                <div class="px-0.5 pt-[5px] justify-center items-center flex cursor-pointer text-[#384250] text-base font-medium font_hindguntur leading-normal"
                                    (click)="goToJobs()">
                                    View more jobs
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:gap-6 gap-4">
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Cyber security</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Governance, risk, and compliance (GRC) specialist</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £30,000-£60,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    plays a vital role in ensuring that an organisation operates within legal
                                    boundaries, adheres to internal policies, and minimises risk exposure. Salaries can
                                    range from £30k-£60k+.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Cyber security</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Incident responder</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £55,000-£80,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    identifies, investigates and mitigates security incidents. They act quickly during
                                    breaches to minimise impact and prevent further compromise. Salaries range from
                                    £55k-£80k+.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Cyber security</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    IT & cyber support trainee</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £23,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    is designed to provide foundational skills and experience in both IT support and
                                    cyber security. Salaries typically start around £23k.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Cyber security</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Digital forensic & insider threat analyst</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £25,000-£35,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    plays a critical role in cyber security by investigating and mitigating internal
                                    threats and conducting digital forensic analysis. Salaries generally range from
                                    £25k-£35k.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container bg-[#724EB3]">
            <div class="max_container">
                <div class="lg:py-24 py-16">
                    <div class="px-8 justify-start items-start gap-16 lg:flex block">
                        <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                                <div>
                                    <img src="../../../../../assets/images/sector_pathway/Featured icon.svg" alt="icon">
                                </div>
                                <div
                                    class="self-stretch text-white text-3xl lg:text-4xl font-semibold font_hindguntur leading-[52px]">
                                    What’s it like to work in cyber security?</div>
                            </div>
                            <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                Working in cyber security can be an exciting and dynamic experience. Here are some
                                insights based on experiences shared by professionals in the field:</div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Daily activities</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Daily activities in cyber security include monitoring networks and systems for
                                        unusual activity, analysing potential threats and responding quickly to
                                        security breaches by identifying, containing and mitigating their impact.
                                        Professionals regularly scan for vulnerabilities, apply patches, develop and
                                        enforce security policies, and educate employees about security best practices
                                        through training sessions.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Work environment</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        The cyber security work environment involves close collaboration with IT
                                        professionals, management and sometimes law enforcement. It is dynamic and
                                        fast-paced, there is a need to be constantly up to date with the latest threats
                                        and technologies. Additionally, it demands creative and technical
                                        problem-solving skills to tackle complex challenges. </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Pros and cons</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Cyber security professionals are in high demand, offering good job security and
                                        competitive salaries. The field provides a variety of roles, allowing
                                        individuals to find niches that match their interests and skills. Additionally,
                                        the work is impactful and fulfilling, as it involves protecting organisations
                                        and individuals from cyber threats.
                                        The responsibility of protecting sensitive information in cyber security can be
                                        stressful, especially during security incidents. The field demands continuous
                                        learning to stay updated with the latest threats and technologies and incident
                                        response may require working irregular hours, including nights and weekends.
                                    </div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Personal experiences</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Many cyber security professionals find the field exciting and rewarding. They
                                        enjoy the challenge of staying ahead of cyber threats and the satisfaction of
                                        making a tangible impact.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<vc-footer></vc-footer>