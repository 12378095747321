import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'vc-sector-view',
  templateUrl: './logistics-view.component.html'
})
export class logistcisViewComponent {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Freight Logistics',
      description: `Also known as shipping logistics, involves the management and coordination of transporting goods in large quantities from one place to another. 
      This process can include various modes of transportation via road, air or sea. Some key aspects of freight logistics include transportation, warehousing, inventory management, 
      customs and documentation and packaging. Freight logistics are crucial for businesses as it ensures that products are delivered both efficiently and cost-effectively.`
    },
    {
      title: 'Supply chain management (SCM)',
      description: `Within logistics involves overseeing the entire lifecycle of a product, from the initial sourcing of raw materials to the final delivery of finished goods to consumers. 
      It encompasses a wide range of activities including procurement, production management, inventory management, transportation and distribution, demand forecasting and customer service. 
      SCM aims to optimize these processes to reduce costs, improve efficiency and enhance customer satisfaction. `
    },
    {
      title: 'Vehicle manufacturing and retail',
      description: `Often referred to as automotive logistics, involves the planning, coordination, and execution of various activities related to the production and distribution of vehicles and their components. 
      Key components include manufacturing logistics (sourcing raw materials, production management, inventory management), distribution logistics (transportation, warehousing, order fulfilment) and retail logistics 
      (dealership management, after sales services). Automotive logistics are crucial for ensuring that vehicles and parts are delivered efficiently and cost effectively 
      whilst maintaining high levels of customer satisfaction.`
    },
    {
      title: 'Passenger transport',
      description: `In logistics refers to the movement of people from one location to another using various modes of transportation. This can include buses, trains, airplanes, 
      ferries and ride-sharing services. Key aspects of passenger transport include modes of transport (to include road, rail, air and sea), operations (route planning, scheduling, 
      customer service and safety and compliance) and infrastructure (terminals and maintenance facilities). Passenger transport is essential for enabling mobility, supporting economic 
      activities and enhancing the quality of life by providing access to work, education and leisure activities.`
    },
    {
      title: 'Transport planning and traffic management',
      description: `Are vital components of logistics, ensuring the efficient and safe movement of goods and people. Transport planning involves the strategic design and organisation 
      of transportation systems to meet the needs of businesses and communities. Key aspects of this include route optimisation, capacity planning, scheduling and infrastructure development. 
      Traffic management focuses on the real-time control and regulation of traffic flow to ensure safety and efficiency. Key aspects include traffic control systems, monitoring and surveillance, 
      incident management and regulatory compliance. Both are essential for optimising the supply chain, reducing costs and improve service quality. Effective planning and management help prevent 
      delays, reduce fuel consumption and enhance overall efficiency.`
    }
  ];

  constructor(
    private readonly title: Title,
    private router: Router,
    public readonly authService: AuthenticationService,
  ) {
    this.title.setTitle('Logistics');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
  
    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
}
  
nextSlide(track: HTMLElement): void {
  const slidesToShow = 2; 
  const maxIndex = this.slides.length - 1;

  if (this.currentSlideIndex < maxIndex) {
    this.currentSlideIndex++;
    this.moveToSlide(track);
  }
}
  
prevSlide(track: HTMLElement): void {
  if (this.currentSlideIndex > 0) {
    this.currentSlideIndex--;
    this.moveToSlide(track);
  }
}
  
moveToSlide(track: HTMLElement): void {
  const isMobileView = window.innerWidth <= 768;
  const trackWidth = track.clientWidth;
  const isLastSlide = this.currentSlideIndex === this.slides.length - 1;
  const isSecondLastSlide = this.currentSlideIndex === this.slides.length - 2;

  if (isMobileView) {
    
    const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955);
    track.style.transform = `translateX(${amountToMove}px)`;
  } else {
  if (isLastSlide || isSecondLastSlide) {
    
    const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
    track.style.transform = `translateX(${amountToMove}px)`;
  } else {
      
      const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
      track.style.transform = `translateX(${amountToMove}px)`;

      const slides = document.querySelectorAll('.carousel-slide') as NodeListOf<HTMLElement>;
      slides.forEach(slide => slide.classList.remove('next-slide'));

      if (this.currentSlideIndex < this.slides.length - 1) {
        slides[this.currentSlideIndex + 1].classList.add('next-slide');
      }
      if (screen.width < 480) { 
        const amountToMove = -(this.currentSlideIndex * (trackWidth * 1));
        track.style.transform = `translateX(${amountToMove}px)`;
      }
    }
  }
  this.updatePagination();
}
setupPagination(): void {
  const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
  pagination.innerHTML = '';

  this.slides.forEach((_, index) => {
    const bullet = document.createElement('div');
    bullet.classList.add('bullet');
    bullet.addEventListener('click', () => this.goToSlide(index));
    pagination.appendChild(bullet);
  });

  this.updatePagination();
}
goToSlide(index: number): void {
  const track = document.querySelector('.carousel-track') as HTMLElement;
  this.currentSlideIndex = index;
  this.moveToSlide(track);
}
updatePagination(): void {
  const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
  const bullets = Array.from(pagination.children) as HTMLElement[];

  bullets.forEach((bullet, index) => {
    if (index === this.currentSlideIndex) {
      bullet.classList.add('active');
    } else {
      bullet.classList.remove('active');
    }
  });
}

  goToJobs() {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
    } else {
      this.router.navigate(['home', 'jobs', 'jobs-list'])
    }
  }
}
