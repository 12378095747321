<mat-drawer-container class="!w-full" hasBackdrop="true" autosize >
  <mat-drawer #drawer class="w-full md:min-w-[600px] lg:min-w-[1008px] max-w-[1008px] !fixed max-h-screen" mode="over" position="end">
    <vc-srm-app *ngIf="authenticationService.isLoggedIn" (closeChatEvent)="chatCloseEvent($event)"></vc-srm-app> 
  </mat-drawer>
  <ng-http-loader spinner="sk-rotationg-plane" [backgroundColor]="'#00823b'"
  [filteredUrlPatterns]="['/sequation-user-api/checkHeartbeat', '/sequation-user-api/refreshToken', '/sequation-job-api/ref-data/what/', '/sequation-job-api/ref-data/where/' ]">
</ng-http-loader>
<vc-confirm-box class="!z-50"></vc-confirm-box>
<vc-header *ngIf="showHeader" (openmsg)="openChat($event)"></vc-header>
<router-outlet></router-outlet>
</mat-drawer-container>
