<div class="bg-white hidden sm:flex relative">
    <form [formGroup]="chatForm" class="h-screen w-full">
        <div class="w-full block h-screen relative top-0">
            <div class="hidden sm:block fixed top-0 z-40 bg-white w-fill-available">
                <div class="px-4 md:px-6 pt-6 pb-4 border-b">
                    <div class="flex justify-between items-start">
                        <h5 class="text-[#331643] font_hindguntur font-semibold text-xl">Messages</h5>
                        <button type="button" (click)="closeChat()">
                            <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-1">
                        </button>
                    </div>
                    <p class="text-[#503C5C] font-normal text-base font_hindguntur text-wrap max-w-3xl">Message your
                        assigned career consultant. Please note this chat is not monitored 24/7 or over weekend periods.
                        If you need urgent assistance, please contact Samaritans on 116 123 or Police on 111.</p>
                </div>
            </div>
            <div class="sm:flex mt-[0.56rem]" *ngIf="isChatAvailable == true && chatConversations?.length > 0">
                <div class="w-full block lg:max-w-[33%] p-4 space-y-4">
                    <div class="fixed top-[8.5rem] overflow-y-auto w-[300px] h-[80vh]">
                        <!-- CHAT CARD 1  -->
                        <a href="javascript:void(0)" *ngFor="let chat of chatConversations" (click)="onClickChat(chat)"
                            id="chat_{{chat?.chatId}}">
                            <div class="p-4 flex items-start space-x-3 w-full mb-4 hover:bg-gray-50 md:max-w-sm"
                                [ngClass]="{'border rounded-lg border-[#E5E7EB]': chat?.active}">
                                <div class="rounded-full flex items-center justify-center p-1 max-h-12 max-w-12 min-h-12 min-w-12"
                                    [ngClass]="getBgColorForConversation(chat?.chatId)">
                                    <p class="text-white font_hindguntur font-semibold text-xl leading-8 pt-2 px-1.5"  *ngIf="userId != chat?.messageToId">
                                        {{ getInitials(chat?.messageToName) }}
                                    </p>
                                    <p class="text-white font_hindguntur font-semibold text-xl leading-8 pt-2 px-1.5" *ngIf="userId == chat?.messageToId">
                                        {{ getInitials(chat?.messageFromName) }}
                                    </p>
                                </div>

                                <div class="w-full">
                                    <h6 class="text-[#331643] font_hindguntur font-medium text-lg"
                                        *ngIf="messageToName && userId != chat?.messageToId">
                                        {{chat?.messageToName }}
                                    </h6>
                                    <h6 class="text-[#331643] font_hindguntur font-medium text-lg"
                                          *ngIf="messageToName && userId == chat?.messageToId">
                                          {{chat?.messageFromName }}
                                      </h6>
                                    <p class="text-[#503C5C] font_hindguntur font-normal text-sm"
                                        id="last_msg_{{chat?.chatId}}">
                                        {{ chat?.lastMessageText | truncate : [15, "..."] }}
                                    </p>
                                </div>
                                <div *ngIf="chat?.lastMessageRecievedDate" class="text-[#503C5C] font_hindguntur font-normal text-xs text-nowrap">
                                    <span id="time__{{chat?.chatId}}">
                                        {{ formatLastTimeDate(chat?.lastMessageRecievedDate) }}
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <!-- CHAT SECTION  -->
                <div class="w-full h-full max-w-[67%] hidden sm:flex flex-col justify-between bg-[#F9FAFB] relative"
                    [ngClass]="{'!flex': chat?.active}">
                    <div *ngIf="chat?.messageFromName"
                    class="flex items-center justify-between px-4 py-2 space-x-4 bg-[#F3F4F6] border border-[#F3F4F6] fixed sm:top-[8.8rem] md:top-[7.25rem] w-fill-available">
                        <div class="flex space-x-4 items-center">
                            <div class="rounded-full flex items-center justify-center p-1 max-h-8 max-w-8 min-h-8 min-w-8"
                                [ngClass]="getBgColorForConversation(chat?.chatId)">
                                <p class="text-white font_hindguntur font-semibold text-xl pt-2 px-1.5"
                                    [attr.aria-label]="chat?.messageToName">
                                    {{ getInitials(chat?.messageFromName) }}
                                </p>
                            </div>

                            <h6 class="text-[#331643] font_hindguntur font-medium text-base">{{chat?.messageToName }}
                            </h6>
                        </div>
                    </div>
                    <!-- chatting  -->
                    <div class="relative top-[11rem] bg-[#f9fafb]">
                        <div [ngClass]="{'oldVersion': oldVersion}" (scroll)="scrolled($event)" #mainScreen
                            id="messageBody"
                            class="h-full sm:min-h-[59vh] sm:max-h-[59vh] xl:max-h-[60vh] xl:min-h-[60vh] overflow-y-scroll flex flex-col-reverse"
                            >
                            <div *ngFor="let message of chatMessages" id="message_{{message.messageId}}"
                                class="px-6 space-y-4 pb-4">
                                <!-- send msg  -->
                                <div class="flex justify-between mb-4" *ngIf="!message?.isSameDate">
                                    <div class="border-b border-[#E5E7EB] w-full mb-2.5"></div> 
                                    <div
                                        class="text-center mx-2 text-[#4D5761] font_hindguntur font-medium text-sm pt-2">
                                        <time class="text-nowrap" id="date__{{message.messageId}}">{{
                                            formatDate(message?.messageTimestamp) }}</time>
                                    </div>
                                    <div class="border-b border-[#E5E7EB] w-full mb-2.5"></div>  
                                </div>
                                <div *ngIf="userId === message?.messageSenderId">
                                    <div class="w-full flex justify-end">
                                        <div
                                            class="max-w-72 bg-[#7953C2] flex items-end space-x-2 pt-2.5 px-3.5 border rounded-l-lg rounded-b-lg">
                                            <p class="font_hindguntur text-lg font-normal text-white chat_text_wrap mb-0.5 anchor-color-send"
                                                id="msg_text_{{message.messageId}}" [innerHTML]="message.messageText">
                                            </p>
                                            <p class="font_hindguntur text-xs font-normal text-[#CCC4EE] mb-1">
                                                <time id="time_{{message.messageId}}"
                                                    attr.aria-label="{{ message.messageTimestamp | date:'HH:mm' }}date{{ message.messageTimestamp | date:'dd MMMM yyyy' }}">
                                                    {{message.messageTimestamp | date:'HH:mm' }}
                                                </time>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- receive msg  -->
                                <div *ngIf="userId !== message?.messageSenderId" class="w-full">
                                    <div
                                        class="max-w-xs w-fit bg-white flex items-end space-x-2 py-2.5 px-3.5 border rounded-r-lg rounded-b-lg justify-between">
                                        <p class="font_hindguntur text-lg font-normal text-[#101828] chat_text_wrap mb-0.5 anchor-color-receive"
                                            id="msg_text_{{message.messageId}}" [innerHTML]="message.messageText">
                                        </p>
                                        <p class="font_hindguntur text-xs font-normal text-[#475467] mb-1">
                                            <time class="message-item__meta--timestamp" id="time_{{message.messageId}}"
                                                attr.aria-label="{{ message.messageTimestamp | date:'HH:mm' }}date{{ message.messageTimestamp | date:'dd MMMM yyyy' }}">
                                                {{message.messageTimestamp | date:'HH:mm' }}
                                            </time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full sticky bottom-0 border border-[#E5E7EB]">
                            <div class="px-6 py-4 space-x-3 flex items-center justify-between bg-[#F9FAFB]"
                                *ngIf="messageToId">
                                <input type="text" class="chat_send_inputbox" placeholder="Message" id="text_area"
                                    name="message" (click)="sendMessage()" (keydown.enter)="sendMessage()" formControlName="message" tabindex="0"
                                    aria-label="messages" maxlength="400" #message>

                                <button class="chat_send_button" type="button" title="Send" id="button-send"
                                    [ngClass]="{'chat_send_disable_button': !chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0, 
                                            'chat_send_button': chatForm.get('message')?.value && chatForm.get('message')?.value.length > 0}"
                                    [disabled]="!chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0"
                                    (keydown.enter)="sendMessage()" (click)="sendMessage()" tabindex="0">
                                    <img src="../../../assets/images/ChatSVG/send.svg" alt="send-button">
                                    <span class="hidden lg:flex text-nowrap mt-[5px]">Send Message</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="sm:flex" *ngIf="isChatAvailable == true && chatConversations?.length == 0">

                <div class="w-full block lg:max-w-[33%] p-4 space-y-4">
                    <div class="fixed top-[133px] overflow-y-auto w-[300px] h-[80vh]">
                        <a href="javascript:void(0)" id="chat_{{ctpContactDetails.id}}" [ngClass]="{'pointer-events-none': true}">
                            <div class="p-4 flex items-center space-x-3 w-full mb-4 hover:bg-gray-50 md:max-w-sm "
                                [ngClass]="{'border rounded-lg border-[#E5E7EB]': true}">
                                <div class="rounded-full flex items-center justify-center p-1 max-h-12 max-w-12 min-h-12 min-w-12"
                                    [ngClass]="getBgColorForConversation(ctpContactDetails.id)">
                                    <p class="text-white font_hindguntur font-semibold text-xl leading-8 pt-2 px-1.5">
                                        {{ getInitials(ctpContactDetails.ctpContactName) }}
                                    </p>
                                </div>
                                <div class="w-full">
                                    <h6 class="text-[#331643] font_hindguntur font-medium text-lg">
                                        {{ctpContactDetails.ctpContactName}}
                                    </h6>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div class="w-full h-full max-w-[67%] hidden sm:flex flex-col justify-between bg-[#F9FAFB]"
                    [ngClass]="{'!flex': true}">
                    <div
                        class="w-full flex items-center justify-between px-4 py-2 space-x-4 bg-[#F3F4F6] border border-[#F3F4F6] fixed top-[76px]">
                        <div class="flex space-x-4 items-center">
                            <div class="rounded-full flex items-center justify-center p-1 max-h-8 max-w-8 min-h-8 min-w-8"
                                [ngClass]="getBgColorForConversation(ctpContactDetails.id)">
                                <p class="text-white font_hindguntur font-semibold text-xl pt-2 px-1.5"
                                    [attr.aria-label]="ctpContactDetails.ctpContactName">
                                    {{ getInitials(ctpContactDetails.ctpContactName) }}
                                </p>
                            </div>

                            <h6 class="text-[#331643] font_hindguntur font-medium text-base">
                                {{ctpContactDetails.ctpContactName}}
                            </h6>
                        </div>
                    </div>

                    <div>
                        <div [ngClass]="{'oldVersion': oldVersion}" #mainScreen id="messageBody"
                            class="h-full md:min-h-[70vh] md:max-h-[70.6vh] lg:max-h-[53vh] lg:min-h-[50vh] xl:max-h-[75vh] xl:min-h-[85vh] 2xl:max-h-[85vh] overflow-y-scroll flex flex-col-reverse">
                            <div id="message_{{ctpContactDetails.id}}" class="px-6 space-y-4 pb-4">
                                <!-- send msg  -->

                            </div>
                        </div>
                        <hr class="bg-[#E5E7EB]">
                        <div class="w-full sticky bottom-0">
                            <div class="px-6 py-4 space-x-3 flex items-center justify-between bg-[#F9FAFB]">
                                <input type="text" class="chat_send_inputbox" placeholder="Message" id="text_area"
                                    name="message" (keydown.enter)="sendMessageFirstTime()" formControlName="message"
                                    aria-label="messages" maxlength="400" #message>

                                <button class="chat_send_button" type="button" title="Send" id="button-send"
                                    [ngClass]="{'chat_send_disable_button': !chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0, 
                                            'chat_send_button': chatForm.get('message')?.value && chatForm.get('message')?.value.length > 0}"
                                    [disabled]="!chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0"
                                    (keydown.enter)="sendMessageFirstTime()" (click)="sendMessageFirstTime()">
                                    <img src="../../../assets/images/ChatSVG/send.svg" alt="send-button">
                                    <span class="hidden lg:flex text-nowrap mt-[5px]">Send Message</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="isChatAvailable == false"
                class="w-full hidden sm:flex flex-col justify-center items-center h-[calc(100vh-78px)]">
                <div class="text-center">
                    <div class="flex justify-center items-center mb-4">
                        <div aria-label="Messages icon"
                            class="bg-purple-200 flex justify-center items-center rounded-full">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" aria-labelledby="titleID"
                                xmlns="http://www.w3.org/2000/svg">
                                <title id="titleID">Messages icon</title>
                                <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFC" />
                                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FBF6FE" stroke-width="8" />
                                <path
                                    d="M22.0944 27.2288C22.0322 26.8282 22 26.4179 22 26C22 21.5817 25.6052 18 30.0526 18C34.4999 18 38.1052 21.5817 38.1052 26C38.1052 26.9981 37.9213 27.9535 37.5852 28.8345C37.5154 29.0175 37.4804 29.109 37.4646 29.1804C37.4489 29.2512 37.4428 29.301 37.4411 29.3735C37.4394 29.4466 37.4493 29.5272 37.4692 29.6883L37.8717 32.9585C37.9153 33.3125 37.9371 33.4895 37.8782 33.6182C37.8266 33.731 37.735 33.8205 37.6211 33.8695C37.4911 33.9254 37.3146 33.8995 36.9617 33.8478L33.7765 33.3809C33.6101 33.3565 33.527 33.3443 33.4512 33.3448C33.3763 33.3452 33.3245 33.3507 33.2511 33.3661C33.177 33.3817 33.0823 33.4172 32.893 33.4881C32.0097 33.819 31.0524 34 30.0526 34C29.6344 34 29.2237 33.9683 28.8227 33.9073M23.6316 38C26.5965 38 29 35.5376 29 32.5C29 29.4624 26.5965 27 23.6316 27C20.6667 27 18.2632 29.4624 18.2632 32.5C18.2632 33.1106 18.3603 33.6979 18.5395 34.2467C18.6153 34.4787 18.6532 34.5947 18.6657 34.6739C18.6786 34.7567 18.6809 34.8031 18.6761 34.8867C18.6714 34.9668 18.6514 35.0573 18.6113 35.2383L18 38L20.9948 37.591C21.1583 37.5687 21.24 37.5575 21.3114 37.558C21.3865 37.5585 21.4264 37.5626 21.5001 37.5773C21.5701 37.5912 21.6742 37.6279 21.8823 37.7014C22.4306 37.8949 23.0191 38 23.6316 38Z"
                                    stroke="#8636BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <h2 class="text-[#331643] font_hindguntur text-lg font-semibold">You don’t currently have a career
                        consultant</h2>
                    <p class="font_hindguntur text-gray-600">You will be assigned one soon</p>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- MOBILE DEVICE  -->
<div class="bg-white h-full flex sm:hidden relative">
    <form [formGroup]="chatForm" class="h-full w-full">
        <div class="w-full">
            <!-- User List View for Mobile Devices -->
            <div *ngIf="!selectedChatId" class="block sm:hidden">
                <div class="px-4 md:px-6 pt-6 pb-4 border-b">
                    <div class="flex justify-between items-start">
                        <h5 class="text-[#331643] font_hindguntur font-semibold text-xl">Messages</h5>
                        <button type="button" (click)="closeChat()">
                            <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-2">
                        </button>
                    </div>
                    <p class="text-[#503C5C] font-normal text-base font_hindguntur text-wrap max-w-2xl">Message your
                        assigned career consultant. Please note this chat is not monitored 24/7 or over weekend periods.
                        If you need urgent assistance, please contact Samaritans on 116 123 or Police on 111.</p>
                </div>
            </div>

            <!-- Chat List -->
            <div *ngIf="isChatAvailable == true && !selectedChatId && chatConversations?.length > 0"
                class="w-full sm:flex h-full lg:max-w-[461px] p-4 space-y-4 overflow-y-scroll">
                <a href="javascript:void(0)" *ngFor="let chat of chatConversations" (click)="selectChat(chat.chatId)">
                    <div class="p-4 flex items-start space-x-3 rounded-lg w-full mb-4 hover:bg-gray-50"
                        [ngClass]="{'': chat?.active}">
                        <div class="rounded-full flex items-center justify-center p-1 max-h-12 max-w-12 min-h-12 min-w-12"
                            [ngClass]="getBgColorForConversation(chat?.chatId)">
                            <p class="text-white font_hindguntur font-semibold text-xl leading-8 pt-2 px-1.5">
                                {{(chat?.initials) | uppercase }}
                            </p>
                        </div>
                        <div class="w-full">
                            <h6 class="text-[#331643] font_hindguntur font-medium text-lg">
                                {{ chat?.messageFromName }}
                            </h6>
                            <p class="text-[#503C5C] font_hindguntur font-normal text-sm">
                                {{ chat?.lastMessageText | truncate : [15, "..."]}}
                            </p>
                        </div>
                        <div class="text-[#503C5C] font_hindguntur font-normal text-xs text-nowrap">
                            <span> {{ formatLastTimeDate(chat?.lastMessageRecievedDate) }}</span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- Chat Section -->
            <div *ngIf="isChatAvailable == true && selectedChatId && chatConversations?.length > 0"
                class="w-full sm:flex flex-col justify-between bg-[#F9FAFB]">
                <div class="w-full flex justify-between px-4 py-2 space-x-4 bg-[#FCFCFD] border border-[#F3F4F6]">
                    <div class="flex space-x-4 items-center">
                        <!-- Back Button for Mobile -->
                        <button type="button" class="flex items-start" (click)="deselectChat()">
                            <img src="../../../assets/images/ChatSVG/ChatBack.svg" alt="back-button-2">
                        </button>
                        <div class="rounded-full flex items-center justify-center p-1 max-h-8 max-w-8 min-h-8 min-w-8"
                            [ngClass]="getBgColorForConversation(selectedChatId)">
                            <p class="text-white font_hindguntur font-semibold text-xl pt-2 px-1.5">
                                {{(selectedChatInitials) | uppercase }}
                            </p>
                        </div>
                        <h6 class="text-[#331643] font_hindguntur font-medium text-base mt-1">{{ selectedChatName }}
                        </h6>
                    </div>
                    <button type="button" class="sm:hidden flex items-start" (click)="closeChat()">
                        <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-3">
                    </button>
                </div>
                <!-- Chat Messages -->
                <div class="h-full">
                    <div (scroll)="scrolled($event)" #mainScreen id="messageBody"
                        class="max-h-[80.8vh] min-h-[80.8vh] overflow-y-scroll flex flex-col-reverse">
                        <div *ngFor="let message of chatMessages" id="message_{{message.messageId}}"
                            class="px-6 space-y-4 pb-4">
                            <div class="flex justify-between mb-4" *ngIf="!message?.isSameDate">
                                <div class="border-b border-[#E5E7EB] w-full mb-2.5"></div>
                                <div class="text-center mx-2 text-[#4D5761] font_hindguntur font-medium text-sm pt-2">
                                    <time class="text-nowrap" id="date__{{message.messageId}}">
                                        {{ formatDate(message?.messageTimestamp) }}
                                    </time>
                                </div>
                                <div class="border-b border-[#E5E7EB] w-full mb-2.5"></div>
                            </div>
                            <div *ngIf="userId === message?.messageSenderId">
                                <div class="w-full flex justify-end">
                                    <div
                                        class="max-w-72 bg-[#412871] flex items-end space-x-2 pt-2.5 px-3.5 border rounded-l-lg rounded-b-lg">
                                        <p class="font_hindguntur text-lg font-normal text-white chat_text_wrap mb-0.5 anchor-color-send"
                                            id="msg_text_{{message.messageId}}" [innerHTML]="message.messageText">
                                        </p>
                                        <p class="font_hindguntur text-xs font-normal text-[#CCC4EE] mb-1">
                                            <time>{{ message.messageTimestamp | date:'HH:mm' }}</time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="userId !== message?.messageSenderId" class="w-full">
                                <div
                                    class="max-w-xs w-fit bg-white flex items-end space-x-2 py-2.5 px-3.5 border rounded-r-lg rounded-b-lg justify-between">
                                    <p class="font_hindguntur text-lg font-normal text-[#101828] chat_text_wrap anchor-color-receive"
                                        id="msg_text_{{message.messageId}}" [innerHTML]="message.messageText">
                                    </p>
                                    <p class="font_hindguntur text-xs font-normal text-[#475467] mb-1">
                                        <time>{{ message.messageTimestamp | date:'HH:mm' }}</time>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="bg-[#E5E7EB]">
                    <div class="px-6 py-4 space-x-3 flex items-center justify-between fixed bottom-0 w-full">
                        <input type="text" class="chat_send_inputbox" placeholder="Message" name="message"
                            (keydown.enter)="sendMessage()" formControlName="message" aria-label="messages"
                            maxlength="400">
                        <button class="chat_send_button" type="button"
                            [ngClass]="{'chat_send_disable_button': !chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0, 
                        'chat_send_button': chatForm.get('message')?.value && chatForm.get('message')?.value.length > 0}"
                            [disabled]="!chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0"
                            (click)="sendMessage()">
                            <img src="../../../assets/images/ChatSVG/send.svg" alt="send-button-2">
                            <span class="hidden lg:flex text-nowrap mt-[5px]">Send Message</span>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isChatAvailable == true && showChat == false && chatConversations?.length == 0"
                class="w-full sm:flex h-full lg:max-w-[461px] p-4 space-y-4 overflow-y-scroll">
                <a href="javascript:void(0)" (click)="showChat = true" id="chat_{{ctpContactDetails.id}}">
                    <div class="p-4 flex items-center space-x-3 w-full mb-4 hover:bg-gray-50 md:max-w-sm "
                        [ngClass]="{'border rounded-lg border-[#E5E7EB]': true}">
                        <div class="rounded-full flex items-center justify-center p-1 max-h-12 max-w-12 min-h-12 min-w-12"
                            [ngClass]="getBgColorForConversation(ctpContactDetails.id)">
                            <p class="text-white font_hindguntur font-semibold text-xl leading-8 pt-2 px-1.5"
                                [attr.aria-label]="ctpContactDetails.ctpContactName">
                                {{ getInitials(ctpContactDetails.ctpContactName) }}
                            </p>
                        </div>
                        <div class="w-full">
                            <h6 class="text-[#331643] font_hindguntur font-medium text-lg">
                                {{ctpContactDetails.ctpContactName}}
                            </h6>
                        </div>
                    </div>
                </a>
            </div>

            <div *ngIf="isChatAvailable == true && showChat == true && chatConversations?.length == 0"
                class="w-full sm:flex flex-col justify-between bg-[#F9FAFB]">
                <div class="w-full flex justify-between px-4 py-2 space-x-4 bg-[#FCFCFD] border border-[#F3F4F6]">
                    <div class="flex space-x-4 items-center">
                        <!-- Back Button for Mobile -->
                        <button type="button" class="flex items-start" (click)="showChat = false">
                            <img src="../../../assets/images/ChatSVG/ChatBack.svg" alt="back-button-2">
                        </button>
                        <div class="rounded-full flex items-center justify-center p-1 max-h-8 max-w-8 min-h-8 min-w-8"
                            [ngClass]="getBgColorForConversation(ctpContactDetails.id)">
                            <p class="text-white font_hindguntur font-semibold text-xl pt-2 px-1.5"
                                [attr.aria-label]="ctpContactDetails.ctpContactName">
                                {{ getInitials(ctpContactDetails.ctpContactName) }}
                            </p>
                        </div>

                        <h6 class="text-[#331643] font_hindguntur font-medium text-base">
                            {{ctpContactDetails.ctpContactName}}
                        </h6>
                    </div>
                    <button type="button" class="sm:hidden flex items-start" (click)="closeChat()">
                        <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-3">
                    </button>
                </div>
                <!-- Chat Messages -->
                <div class="h-full">
                    <div #mainScreen id="messageBody"
                        class="max-h-[80.8vh] min-h-[80.8vh] overflow-y-scroll flex flex-col-reverse">
                        <div class="px-6 space-y-4 pb-4">

                        </div>
                    </div>
                    <hr class="bg-[#E5E7EB]">
                    <div class="px-6 py-4 space-x-3 flex items-center justify-between fixed bottom-0 w-full">
                        <input type="text" class="chat_send_inputbox" placeholder="Message" name="message"
                            (keydown.enter)="sendMessageFirstTime()" formControlName="message" aria-label="messages"
                            maxlength="400">
                        <button class="chat_send_button" type="button"
                            [ngClass]="{'chat_send_disable_button': !chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0, 
                        'chat_send_button': chatForm.get('message')?.value && chatForm.get('message')?.value.length > 0}"
                            [disabled]="!chatForm.get('message')?.value || chatForm.get('message')?.value.length === 0"
                            (click)="sendMessageFirstTime()">
                            <img src="../../../assets/images/ChatSVG/send.svg" alt="send-button-2">
                            <span class="hidden lg:flex text-nowrap mt-[5px]">Send Message</span>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isChatAvailable == false"
                class="w-full flex flex-col justify-center items-center h-[calc(100vh-78px)]">
                <div class="text-center w-[calc(100%-25px)] mx-3">
                    <div class="flex justify-center items-center mb-4">
                        <div aria-label="Messages icon"
                            class="bg-purple-200 flex justify-center items-center rounded-full">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" aria-labelledby="titleID"
                                xmlns="http://www.w3.org/2000/svg">
                                <title id="titleID">Messages icon</title>
                                <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFC" />
                                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FBF6FE" stroke-width="8" />
                                <path
                                    d="M22.0944 27.2288C22.0322 26.8282 22 26.4179 22 26C22 21.5817 25.6052 18 30.0526 18C34.4999 18 38.1052 21.5817 38.1052 26C38.1052 26.9981 37.9213 27.9535 37.5852 28.8345C37.5154 29.0175 37.4804 29.109 37.4646 29.1804C37.4489 29.2512 37.4428 29.301 37.4411 29.3735C37.4394 29.4466 37.4493 29.5272 37.4692 29.6883L37.8717 32.9585C37.9153 33.3125 37.9371 33.4895 37.8782 33.6182C37.8266 33.731 37.735 33.8205 37.6211 33.8695C37.4911 33.9254 37.3146 33.8995 36.9617 33.8478L33.7765 33.3809C33.6101 33.3565 33.527 33.3443 33.4512 33.3448C33.3763 33.3452 33.3245 33.3507 33.2511 33.3661C33.177 33.3817 33.0823 33.4172 32.893 33.4881C32.0097 33.819 31.0524 34 30.0526 34C29.6344 34 29.2237 33.9683 28.8227 33.9073M23.6316 38C26.5965 38 29 35.5376 29 32.5C29 29.4624 26.5965 27 23.6316 27C20.6667 27 18.2632 29.4624 18.2632 32.5C18.2632 33.1106 18.3603 33.6979 18.5395 34.2467C18.6153 34.4787 18.6532 34.5947 18.6657 34.6739C18.6786 34.7567 18.6809 34.8031 18.6761 34.8867C18.6714 34.9668 18.6514 35.0573 18.6113 35.2383L18 38L20.9948 37.591C21.1583 37.5687 21.24 37.5575 21.3114 37.558C21.3865 37.5585 21.4264 37.5626 21.5001 37.5773C21.5701 37.5912 21.6742 37.6279 21.8823 37.7014C22.4306 37.8949 23.0191 38 23.6316 38Z"
                                    stroke="#8636BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <h2 class="text-[#331643] font_hindguntur text-lg font-semibold">You don’t currently have a career
                        consultant</h2>
                    <p class="font_hindguntur text-gray-600">You will be assigned one soon</p>
                </div>
            </div>
        </div>
    </form>
</div>