import { ApplicationConstant } from "src/app/shared/constants/app-constant";

export const environment = {
  production: true,
  api: 'https://reedctpdevservices.sequation.net',
  enableCtrl: true,
  azureBlobStorage: 'https://cdn-reedctp-dev.captr.online',
  azureCDNStorageUrl: 'https://cdn-reedctp-dev.captr.online',
  env: ApplicationConstant.DEV_ENV,
  MilisearchURL: 'https://ctp-cms-search-dev.sequation.net/',
  MilisearchURL_API_KEY :'331cd2f91184d294a65ad58c1dc3cbd1d21cc749670545e3d944168a4327a397',
  instantSearchApiKey:'331cd2f91184d294a65ad58c1dc3cbd1d21cc749670545e3d944168a4327a397',
  instantSearchHost:'https://ctp-cms-search-dev.sequation.net',
  appUpdateCheckInterval: 15 * 60 * 1000
};
