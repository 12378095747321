import { Component } from '@angular/core';
import { Hit, InstantSearchStatus } from 'instantsearch.js';
import { connectHits } from 'instantsearch.js/es/connectors';
import { Router } from '@angular/router';
import { configure, hitsPerPage } from 'instantsearch.js/es/widgets';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { favouritesService } from 'src/app/core/services/favourites.service';
import { SharedFeaturedArticlesServiceService } from './shared-featured-articles.service';

@Component({
  selector: 'vc-shared-featured-articles',
  templateUrl: './shared-featured-articles.component.html'
})
export class SharedFeaturedArticlesComponent {

  public featuredHits?: Hit[];
  public query?: string;
  public search?: (value: string) => void;
  public status: InstantSearchStatus = 'idle';
  private statusChangeListener: () => void;
  results: any[];
  userId: any;
  favouriteArticlesList = [];
  collectionName: string = 'newsArticles';
  public hits?: Hit[];
  helper: any;

  constructor(
    private readonly sharedFeaturedArticlesServiceService: SharedFeaturedArticlesServiceService,
    private readonly router: Router,
    private readonly favouritesService: favouritesService,
    private readonly snackBar: SnackBarService,
  ) {
    this.statusChangeListener = () => {
      this.status = this.sharedFeaturedArticlesServiceService.instantSearchInstance.status;
    };
    if (localStorage.getItem('sessionToken')) this.userId = JSON.parse(atob(localStorage?.getItem('sessionToken')?.split('.')[1]))?.userId;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.helper = this.sharedFeaturedArticlesServiceService.instantSearchInstance.helper;
    
    this.sharedFeaturedArticlesServiceService.addWidgets([
      configure({
        facetsRefinements: {
          'featured': ['true'],
        },
        // hitsPerPage: 10000,
      }),

      connectHits(({ hits }) => {
        const featured = [];
        hits.forEach(element => {
          if (this.filterByDateRange(element.featuredStartDate, element.featuredEndDate)) {
            featured.push(element);
          }
        });
        this.featuredHits = featured;
        let shuffledList = this.shuffleArray(featured);
        this.featuredHits = shuffledList.slice(0, 3);
        if (this.userId) this.getFavouritesByCollectionName(this.featuredHits);
      })({}),
    ]);
  }

  filterByDateRange(startDate: string, endDate: string): boolean {
    const today = new Date().toISOString().split('T')[0]; // Example: '2024-09-19'
    const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
    const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
    return today >= formattedStartDate && today <= formattedEndDate;
  }

  shuffleArray(array: any[]): any[] {
    return array.sort(() => Math.random() - 0.5);
  }

  ngAfterContentInit() {
    this.sharedFeaturedArticlesServiceService.start();
  }

  ngOnDestroy() {
    this.sharedFeaturedArticlesServiceService.instantSearchInstance.removeListener('render', this.statusChangeListener);
    this.sharedFeaturedArticlesServiceService.dispose();
  }

  allArticles() {
    this.router.navigate(['/home/articles-guide/all-articles']);
  }

  detailedView(articleId: any) {
    this.router.navigate(['/home/articles-guide/view-articles'], { queryParamsHandling: 'merge', queryParams: { articleId } });
  }

  getFavouritesByCollectionName(collections) {

    let collectionIds = collections.map(obj => obj.id);

    this.favouritesService.getFavouritesByCollectionName(this.collectionName, collectionIds).subscribe(res => {

      this.favouriteArticlesList = res?.body?.data?.userFavourites?.data || [];
    }, err => {

    })
  }

  favouritesSection(method, articleId) {

    switch (method) {

      case 'add':

        this.favouritesService.addToFavourites(articleId, this.collectionName).subscribe(res => {

          this.snackBar.successToast(res?.message?.applicationMessage);
          this.getFavouritesByCollectionName(this.featuredHits);
        }, err => {

          this.snackBar.errorToast(err?.error.applicationMessage);
        });
        break;

      case 'remove':

        let id = this.favouriteArticlesList.find(article => article.attributes.targetId == articleId)?.id;
        this.favouritesService.removeFromFavourites(id).subscribe(res => {

          this.snackBar.successToast(res?.message?.applicationMessage);
          this.getFavouritesByCollectionName(this.featuredHits);
        }, err => {

          this.snackBar.errorToast(err?.error.applicationMessage)
        });
        break;
    }
  }

  isArticleFavorite(articleId: string): boolean {
    return this.favouriteArticlesList.some(favorite => favorite.attributes.targetId == articleId);
  }

  // checkFeaturedDate(){
  //   const helper = this.instantSearchService.instantSearchInstance.helper;
  //   const attribute = 'hierarchicalAddress.lvl1';
  //   helper?.toggleFacetRefinement(attribute, searchResult).search();
  // }
}
