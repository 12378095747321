import { OnInit, Component, ViewChild, EventEmitter, ElementRef, OnDestroy, Output } from '@angular/core';
import { MainService } from '../../core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SrmService } from './srm.service';
import { Location } from '@angular/common';
import { TabsService } from '../../shared/components/tabs/tabs.service';
import { ConfirmService } from '../../shared/components/confirm-box/confirm-box.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { FeatureConstants } from 'src/app/shared/constants/features-constants';


@Component({
    selector: 'vc-srm-app',
    templateUrl: './srm.component.html'
})
export class SrmComponent implements OnInit, OnDestroy {
    protected myDate: Date;
    protected userId: number;
    public messageToName;
    readonly pageSize = 1000;
    private pageIndex = 1;
    sortColumn = '';
    public chatConversations: any[];
    public chatMessages: any[];
    public chat;
    public isAllMessagesLoaded: boolean = false;
    chatForm: UntypedFormGroup;
    messageToId: any;
    chatWith: string;
    chatId;
    versionNumber: number;
    browserName = navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+/g)[0];
    oldVersion: boolean;
    selectedChatId: string | null = null;
    selectedChatName: string | null = null;
    selectedChatInitials: string | null = null;

    @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
    viewHeight: number;
    @Output() closeChatEvent = new EventEmitter();
    message = {
        messageTimestamp: '2024-08-09T12:00:00Z' // Replace with your actual timestamp
    };
    ctpContact: boolean = false;
    ctpContactDetails = {
        id: null,
        ctpContactName: null
    }
    isChatAvailable: boolean = false;
    showChat: boolean = false;
    ngOnDestroy(): void {
        this.pageIndex = 0;
        this.chatMessages = [];
        this.chatConversations = [];
        this.chat = null;
        this.tabService.emitChatFocus(false);
    }

    constructor(
        private readonly mainService: MainService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly srmService: SrmService,
        private readonly location: Location,
        private readonly confirmService: ConfirmService,
        private readonly tabService: TabsService,
        private readonly builder: UntypedFormBuilder,

    ) {
        this.mainService.setPageTitle(FeatureConstants.Message, route.snapshot.data);
        this.ctpContact = JSON.parse(localStorage.getItem('ctpContact')) || false;
        this.ctpContactDetails.ctpContactName = localStorage.getItem('ctpContactName') || null;
        this.ctpContactDetails.id = localStorage.getItem('ctpContactId') || null;
    }
    ngOnInit() {

        this.chatMessages = null;
        if(localStorage?.getItem('sessionToken')){
            this.userId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).userId;
            this.getUserList();
            this.tabService.setHideChatCount(true);
            this.tabService.emitChatFocus(true);
            this.route.queryParams.subscribe(params => {
                this.tabService.setHideChatCount(true);
                this.chatId = params.active;
                this.pageIndex = 0;
                this.isAllMessagesLoaded = false;
                this.chat = null;
                this.getChatDisplay();
            });
            this.initForm();
            if (this.browserName === "Firefox") {
                this.versionNumber = parseInt(window.navigator.userAgent.match(/Firefox\/([0-9]+)\./)[1]);
                if (this.versionNumber <= 80) {
                    this.oldVersion = true;
                }
            }
        }
    }

    getChatDisplay(chat?: any) {
        this.srmService.getChatConversation()?.subscribe(data => {
            setTimeout(() => {
                this.ctpContact = JSON.parse(localStorage.getItem('ctpContact')) || false;
                this.ctpContactDetails.ctpContactName = localStorage.getItem('ctpContactName') || null;
                this.ctpContactDetails.id = localStorage.getItem('ctpContactId') || null;
                this.isChatAvailable = data?.length > 0 || this.ctpContact;
            }, 200);
            this.chatConversations = data;

            this.getLatestChat();

            this.chatConversations.forEach(chat => {
                if (this.userId === chat.messageFromId) {
                    chat.initials = chat.messageToName.split(' ').map((n) => n[0]).join('');
                } else if (this.userId === chat.messageToId) {
                    chat.initials = chat.messageFromName.split(' ').map((n) => n[0]).join('');
                }
            });
            if (data && data.length > 0) {
                this.checkCtpContactPresent(this.chatConversations);
                
                this.chatConversations.forEach(element => {
                    if (element.chatId === chat?.chatId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                });

                let chatObject = {};
                if (!chat?.firstMessage) {
                    chatObject = chat?.chatId ? this.chatConversations.find(obj => obj.chatId === chat?.chatId) : this.chatConversations[0];
                } else {
                    chatObject['messageToId'] = Number(this.ctpContactDetails.id);
                    chatObject['messageToName'] = this.ctpContactDetails.ctpContactName;
                    chatObject['messageFromId'] = this.userId;
                    chatObject['messageFromName'] = this.chatConversations[0].messageToName;
                }
                chatObject['active'] = true;

                this.chat = chatObject;
                this.loadMessages(chatObject);
            }
        });
    }


    loadMessages(chat) {
        const chatMessage = {
            chatId: chat.chatId,
            userId: this.userId
        };

        this.srmService.getChatMessages(this.pageSize, this.pageIndex, chatMessage).subscribe(data => {

            this.chatMessages = data.content;
            if (this.userId === chat.messageFromId) {
                chat.initials = chat.messageToName.split(' ').map((n) => n[0]).join('');
                this.messageToName = chat.messageToName;
            } else if (this.userId === chat.messageToId) {
                chat.initials = chat.messageFromName.split(' ').map((n) => n[0]).join('');
                this.messageToName = chat.messageFromName;
            }

            this.groupByDate();
            this.setReceiverInfo();

            if (!chat.isRead) {
                this.srmService.readAllMessages(chatMessage).subscribe(data => {
                    chat.isRead = true;
                    this.tabService.emitChat(chat.chatId);
                });
            }

            this.scrollToBottom();
        });
    }



    groupByDate() {
        let messageDate = null;
        let messageCount = 0;
        const length = this.chatMessages.length;
        this.chatMessages.forEach(message => {

            if (messageCount < length - 1) {
                messageDate = this.chatMessages[messageCount + 1].messageTimestamp;
                if (length > 1 && this.compairDates(messageDate, message.messageTimestamp)) {
                    message.isSameDate = true;
                } else {
                    message.isSameDate = false;
                }
            } else {
                message.isSameDate = false;
            }

            if (messageCount === length - 1) {
                message.isSameDate = false;
            }

            messageCount++;
        });

    }


    compairDates(fromDate, toDate) {
        const firstDate = new Date(Date.parse(fromDate));
        const secondDate = new Date(Date.parse(toDate));

        const firstYear = firstDate.getFullYear();
        const firstMonth = firstDate.getMonth();
        const firstDay = firstDate.getDate();

        const secondYear = secondDate.getFullYear();
        const secondMonth = secondDate.getMonth();
        const secondtDay = secondDate.getDate();

        if (firstYear === secondYear && firstMonth === secondMonth && firstDay === secondtDay) {
            return true;
        }
        return false;
    }

    onClickChat(chat) {
        this.getChatDisplay(chat)
    }

    closeChat() {
        this.closeChatEvent.emit('close');
    }


    scrolled(event: any): void {
        const offsetHeight = this.elementView.nativeElement.offsetHeight;
        const scrollHeight = this.elementView.nativeElement.scrollHeight;
        const scrollTop = this.elementView.nativeElement.scrollTop;
        const scrollCeil = Math.ceil(Math.abs(scrollTop));
        const scrollFloor = Math.floor(Math.abs(scrollTop));
        const diff = scrollHeight - offsetHeight;
        if (this.browserName === 'Firefox' && this.versionNumber <= 80) {
            this.olderVersionCode(scrollTop, diff);
        } else if (this.browserName === 'Firefox' && this.versionNumber > 80) {
            this.newerVersionCode(scrollTop, diff, scrollCeil);
        } else if (scrollFloor === diff && !this.isAllMessagesLoaded && this.chat) {
            this.loadMoreChats(scrollTop);
        }
    }

    olderVersionCode(scrollTop, diff) {
        if (!this.isAllMessagesLoaded && this.chat && scrollTop <= diff && scrollTop === 0) {
            this.loadMoreChats(scrollTop);
        }
    }

    newerVersionCode(scrollTop, diff, scrollCeil) {
        if (!this.isAllMessagesLoaded && this.chat && scrollTop <= diff && scrollCeil === diff) {
            this.loadMoreChats(scrollTop);
        }
    }

    loadMoreChats(scrollTop) {
        // this.pageIndex = this.pageIndex + 1;
        const chatMessage = {
            chatId: this.chat.chatId,
            userId: this.userId
        };
        this.srmService.getChatMessages(this.pageSize, this.pageIndex, chatMessage).subscribe(data => {
            if (data.content && data.content.length > 0) {
                this.chatMessages = this.chatMessages.concat(data.content);
                this.groupByDate();
            } else {
                this.isAllMessagesLoaded = true;
            }
        });
        document.getElementById("messageBody").scrollTop = scrollTop + 10;
    }
    onCreateClick() {
        this.router.navigate(['srm/new-chat']);
    }
    backClicked() {
        this.location.back();
    }

    cancelMessage() {
        const message: string = this.chatForm.get('message').value;

        if (message.length > 0) {
            this.confirmService.confirm({
                message: 'Are you sure you want to cancel?',
                header: '',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: () => {
                    this.chatForm.reset();
                }
            });
        }
    }
    initForm() {
        this.chatForm = this.builder.group({
            message: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(400)]]
        });
    }
    sendMessage() {
        let message: string = this.formatedText(this.chatForm.get('message').value);
        const conversation = {
            status: 'SU',
            messageText: message,
            messageToId: this.messageToId
        };
        this.srmService.create(conversation).subscribe((data: any) => {
            if (data) {
                const newArray = new Array();
                message = '';
                newArray.push(data);
                this.chatMessages = newArray.concat(this.chatMessages);
                this.srmService?.getChatConversation()?.subscribe(data => {
                    this.chatConversations = data;
                    this.checkCtpContactPresent(this.chatConversations);
                    this.getLatestChat();
                    this.chatConversations.forEach(chatObj => {
                        if (chatObj.chatId === this.chat.chatId) {
                            chatObj.active = true;
                        } else {
                            chatObj.active = false;
                        }
                        if (this.userId === chatObj.messageFromId) {
                            chatObj.initials = chatObj.messageToName.split(' ').map((n) => n[0]).join('');
                        } else if (this.userId === chatObj.messageToId) {
                            chatObj.initials = chatObj.messageFromName.split(' ').map((n) => n[0]).join('');
                        }
                    });
                    this.scrollToBottom();
                });
                this.groupByDate();
                this.chatForm.reset();
            }
        });

    }

    setReceiverInfo() {
        if (this.userId === this.chat.messageFromId) {
            this.messageToName = this.chat.messageToName;
            this.messageToId = this.chat.messageToId;
        } else if (this.userId === this.chat.messageToId) {
            this.messageToName = this.chat.messageFromName;
            this.messageToId = this.chat.messageFromId;
        }
    }

    scrollToBottom() {
        setTimeout(() => {
            try {
                this.elementView.nativeElement.scrollTop = this.elementView.nativeElement.scrollHeight;
            } catch (err) { }
        }, 0);
    }

    formatDate(timestamp: string): string {
        const messageDate = new Date(timestamp);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (messageDate.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (messageDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            // Format date as 'dd MMMM yyyy'
            return messageDate.toLocaleDateString('en-UK', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });
        }
    }

    getFormattedInitials(nameInitials) {
        return nameInitials?.split('')[0] + (nameInitials?.split('')[nameInitials?.split('').length - 1]);
    }

    formatedText(message: any) {
        const urlPattern = /((https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}(\/\S*)?)/gi;
        return message.replace(urlPattern, (url) => {
            // Check if the URL starts with http or https, if not, prepend http
            const href = url.match(/^(https?:\/\/)/) ? url : `http://${url}`;
            return `<a href="${href}" target="_blank">${url}</a>`;
        });
    }

    // Array of background colors
    bgColors: string[] = ['bg-one', 'bg-two', 'bg-three', 'bg-four'];

    // Function to get a random background color
    getBgColorForConversation(conversationId: number): string {
        if (conversationId === undefined) {
            conversationId = 1
        }
        const colorIndex = conversationId % this.bgColors.length;
        return this.bgColors[colorIndex];
    }

    // DAYS AGO STATUS
    formatRelativeDate(dateString: string): string {
        const messageDate = new Date(dateString);
        const today = new Date();
        const timeDifference = today.getTime() - messageDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(daysDifference / 365);

        if (daysDifference < 0) {
            return 'Just now'; // Optional: handle future dates
        } else if (daysDifference === 0) {
            return 'Today';
        } else if (daysDifference === 1) {
            return 'Yesterday';
        } else if (daysDifference < 30) {
            return `${daysDifference} days ago`;
        } else if (daysDifference < 365) {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${yearsDifference} year${yearsDifference !== 1 ? 's' : ''} ago`;
        }
    }

    // last status of user 

    formatLastTimeDate(dateString: string): string {
        const messageDate = new Date(dateString);
        const today = new Date();
        const timeDifference = today.getTime() - messageDate.getTime();

        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(daysDifference / 365);

        if (minutesDifference < 0) {
            return 'Just now'; // Optional: handle future dates
        } else if (minutesDifference < 1) {
            return 'Just now';
        } else if (minutesDifference < 60) {
            return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference === 1) {
            return 'Yesterday';
        } else if (daysDifference < 30) {
            return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference < 365) {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${yearsDifference} year${yearsDifference !== 1 ? 's' : ''} ago`;
        }
    }


    // selected chat id 
    selectChat(chatId: string): void {
        // Find the chat corresponding to the selected chatId
        const chat = this.chatConversations.find(chat => chat.chatId === chatId);

        if (chat) {
            // Set the selected chat properties
            this.selectedChatId = chatId;
            this.selectedChatName = chat.messageFromName; // Adjust as needed
            this.selectedChatInitials = chat.initials;

            // Load the messages for the selected chat
            this.getChatDisplay(chatId); // Use chatId to ensure correct chat is loaded
        }
    }

    deselectChat(): void {
        this.selectedChatId = null;
        this.selectedChatName = null;
        this.selectedChatInitials = null;
    }

    getChatConversation() {
        this.getChatDisplay()
    }
    getInitials(fullName: string): string {
        if (!fullName) return '';
        const names = fullName.split(' ');
        const initials = names.map(name => name.charAt(0).toUpperCase()).join('');
        return initials;
    }

    getLatestChat() {
        this.chatConversations.forEach(element => {
            const chatMessage = {
                chatId: element.chatId,
                userId: this.userId
            };
            this.srmService.getChatMessages(1, 0, chatMessage).subscribe(data => {
                if (data.content.length > 0) {
                    element.lastMessageText = data.content[0].messageText;
                }
            });
        });
    }

    sendMessageFirstTime(){

        let message: string = this.formatedText(this.chatForm.get('message').value);
        const conversation = {
            status: 'SU',
            messageText: message,
            messageToId: this.ctpContactDetails.id
        };
        this.srmService.create(conversation).subscribe(() => {

            this.getChatConversation();
        });
    }


    getUserList(){

        setTimeout(() => {

            this.ctpContactDetails.ctpContactName = localStorage?.getItem('ctpContactName') || null;
            this.srmService?.getUserList(this.userId)?.subscribe(data=>{
                data.forEach((users: any) => {
                    Object.assign(users, { userFullName: users.firstName + ' ' + users.lastName });
                });

                this.ctpContactDetails.id = data.find(user => user.userFullName === this.ctpContactDetails.ctpContactName)?.id || localStorage.getItem('ctpContactId');
            });
        }, 200);
    }

    checkCtpContactPresent(chatConversationsList: any) {
        const ctpContactExists = chatConversationsList.some((obj) =>
            obj.messageToId === Number(this.ctpContactDetails.id));
        if (!ctpContactExists) {
            this.chatConversations.push({
                messageToId: Number(this.ctpContactDetails.id),
                messageToName: this.ctpContactDetails.ctpContactName,
                messageFromId: this.userId,
                messageFromName: this.chatConversations[0].messageToName,
                firstMessage: true
            });
        }
    }
}
