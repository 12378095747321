import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'vc-automotive-view',
  templateUrl: './automotive-view.component.html'
})
export class AutomotiveViewComponent  {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Vehicle Service Technician',
      description: `Play a crucial role in ensuring that vehicles are in optimal working condition. Their responsibilities include performing regular maintenance tasks such as oil changes, tire rotations and brake inspections. They also diagnose and repair any issues that arise, using specialised tools and diagnostic equipment to identify problems with engines, transmissions and other vehicle systems. This role requires a strong understanding of automotive technology and excellent problem-solving skills. `
    },
    {
      title: 'Automotive Electrician',
      description: `Specialise in the electrical systems of vehicles, ensuring that all electronic components are functioning correctly. Their responsibilities include installing new electrical systems maintaining existing ones and repairing any faults that arise. This can involve working with complex wiring, lighting systems, ignition systems and electronic control units. They use diagnostic tools to identify issues and ensure that all repairs meet safety and performance standards. The role requires a deep understanding of automotive electronics, attention to detail and problem-solving skills. `
    },
    {
      title: 'Bodyshop Technician',
      description: `Responsibilities include repairing and restoring the bodies of vehicles to their original condition. This includes tasks such as fixing dents, scratches, and other damage to the vehicle’s exterior, as well as performing structural repairs to ensure the vehicle’s integrity. They also handle paintwork, applying new coats of paint to match the vehicle’s original colour and finish. This role requires a keen eye for detail, precision, and a thorough understanding of automotive bodywork and painting techniques. `
    },
    {
      title: 'HGV (Heavy Goods Vehicle) Driver',
      description: `Responsibilities include transporting goods using large, heavy vehicles, such as trucks, tractors and articulated vehicles. Their primary duties include ensuring the safe and timely delivery of goods to various destinations, adhering to all traffic laws and regulations. They must also perform routine vehicle inspections to ensure their truck is in good working condition, report any mechanical issues and maintain accurate records of deliveries and vehicle maintenance. Additionally, HGV drivers often handle loading and unloading of cargo, secure loads properly and plan routes to optimise efficiency and meet delivery schedules. This role requires excellent driving skills, attention to detail and the ability to work independently.`
    },
    {
      title: 'Project Manager',
      description: `Responsibilities include overseeing various automotive projects from inception to completion. This role involves coordinating and leading teams of engineers, designers and other professionals to ensure that project goals are met on time and within budget. Key responsibilities include developing project plans, setting timelines and allocating resources effectively. Project managers must also monitor progress, identify and mitigate risks, and ensure that all project deliverables meet quality standards. They act as the primary point of contact between stakeholders, providing regular updates and addressing any issues that arise. Strong leadership, communication and organisational skills are essential for this role.`
    }
  ];

  constructor(
    private readonly title: Title,
    private router: Router,
    public readonly authService: AuthenticationService,
  ) {
    this.title.setTitle('Automotive');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }
  
  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
  }

  nextSlide(track: HTMLElement): void {
    const maxIndex = this.slides.length - 1;
  
    if (this.currentSlideIndex < maxIndex) {
      this.currentSlideIndex++;
      this.moveToSlide(track);
    }
  }
  
  prevSlide(track: HTMLElement): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track);
    }
  }
  

  moveToSlide(track: HTMLElement): void {
    const isMobileView = window.innerWidth <= 768;
    const trackWidth = track.clientWidth;
  
    if (isMobileView) {
      const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955); 
      track.style.transform = `translateX(${amountToMove}px)`;
    } else {
      const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669)); 
      track.style.transform = `translateX(${amountToMove}px)`;
    }
  
    this.updatePagination();
  }
  
  setupPagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    pagination.innerHTML = '';

    this.slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index));
      pagination.appendChild(bullet);
    });

    this.updatePagination();
  }
  goToSlide(index: number): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    this.currentSlideIndex = index;
    this.moveToSlide(track);
  }
  
  updatePagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    const bullets = Array.from(pagination.children) as HTMLElement[];

    bullets.forEach((bullet, index) => {
      if (index === this.currentSlideIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }

  goToJobs() {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
    } else {
      this.router.navigate(['home', 'jobs', 'jobs-list'])
    }
  }
}
