import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptorModule } from './core/interceptors/request-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from './shared/components/dialog-box/dialog.module';
import { DialogService } from './shared/components/dialog-box/dialog.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { PipesModule } from './core/pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe } from '@angular/common';
import { MaterialModule } from './shared/material/material.module';
import {ToastrModule} from 'ngx-toastr';
import { NewsArticlesComponent } from './features/news-articles/news-articles.component'
import { CoreModule } from './core/core.module';
import { SrmModule } from './features/srm/srm.module';
import { CourseCompareComponent } from './core/components/header/course-compare/course-compare.component';
import { EmployersComponent } from './authentication/pre-signin/content-pages/employers/employers.component';
import { TrainingProvidersComponent } from './authentication/pre-signin/content-pages/training-providers/training-providers.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ContactReedComponent } from './authentication/pre-signin/content-pages/contact-reed/contact-reed.component';
import { ServiceLeaversComponent } from './authentication/pre-signin/content-pages/service-leavers/service-leavers.component';
import { SectorPathwaysModule } from './authentication/pre-signin/sector-pathways/sector-pathways.module';
import { ConfirmBoxModule } from './shared/components/confirm-box/confirm-box.module';
import { UserActivityService } from './home/user-activity/user-activity.service';

@NgModule({
  declarations: [
    AppComponent,
    NewsArticlesComponent,
    CourseCompareComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpRequestInterceptorModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgHttpLoaderModule.forRoot(),
    PipesModule,
    CoreModule,
    SrmModule,
    MaterialModule,
    ConfirmBoxModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ToastrModule.forRoot({
      progressBar:true,
      positionClass:'toast-bottom-center',
      tapToDismiss:true,
      maxOpened:1,
      autoDismiss:true,
      timeOut:3000
    }),
    SectorPathwaysModule,
  ],
   providers: [UserActivityService,DatePipe, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, }],
  bootstrap: [AppComponent]
})
export class AppModule { }
