import { environment } from '../../../environments/environment';

export const Url = {
    USER: environment.api + '/sequation-user-api',
    AUTHENTICATION: environment.api + '/sequation-user-api',
    CONTENT_LIBRARY: environment.api + '/sequation-content-management-v2-api',
    PROFILE: environment.api + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.api + '/sequation-user-management-api',
    ASSESSMENT: environment.api + '/sequation-community-assessment-api',
    PLAN: environment.api + '/sequation-plan-api',
    PLANv2: environment.api + '/sequation-plan-v2-api',
    JOBS: environment.api + '/sequation-job-v2-api',
    SRM: environment.api + '/sequation-srm-v2-api',
    CVB: environment.api + '/sequation-cv-builder-v2-api',
    DOCUMENT: environment.api + '/sequation-document-v2-api',
    ANALYTICS: environment.api + '/sequation-analytics-v2-api',
    OPENVIDU: environment.api + '/sequation-openvidu-v2-api',
    SEQUATION_MOODLE_API: localStorage.getItem('moodleUrl') + '/webservice/rest/server.php',
    MOODLE_URL: localStorage.getItem('moodleUrl') + '/',
    MOODLE_API: environment.api + '/sequation-moodle-v2-api',
    CMS_PROXY_API : environment.api +'/sequation-cms-proxy-api',
    CLIENT_URL: window.location.host,
    MILISEARCH_API_URLS : environment.MilisearchURL,
    MILISEARCH_API_KEY : environment.MilisearchURL_API_KEY,
    BOOKING: environment.api + '/sequation-booking-api',
    REFERENCE_DATA :environment.api + '/sequation-reference-data-api',
};

