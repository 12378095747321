import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SrmComponent } from "./srm.component";
import { CreateSrmComponent } from './create-srm/create-srm.component';
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";


const routes: Routes = [
    {
      path: '',
      component: SrmComponent,
      canActivate: [AuthorizationGuard],
      data: { pageTitle: 'Message', pageMainTitle: '', auth:[70]}
    },
    {
      path: 'new-chat',
      component: CreateSrmComponent,
      canActivate: [AuthorizationGuard],
      data: { pageTitle: 'Message', pageMainTitle: '', auth:[70]}
    }
  ];


@NgModule({
    imports:[RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SrmRoutingModule{

}