import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBanner } from './../components/banner/banner.interface';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private readonly bannerSubject = new BehaviorSubject<IBanner>(null);
  currentBanner = this.bannerSubject.asObservable();
  autoHideDuration = 4000;
  timeout: any;

  constructor() { }

  warning(message: IBanner['message'], autoHide?: boolean) {
    this.notify('warning', message, autoHide);
  }

  success(message: IBanner['message'], autoHide?: boolean) {
    this.notify('success', message, autoHide);
  }

  close() {
    this.bannerSubject.next(null);
  }

  private notify(type: IBanner['type'], message: IBanner['message'], autoHide?: boolean) {
    const banner: IBanner = { message, type };
    
    this.bannerSubject.next(banner);

    if (autoHide) {
      setTimeout(() => this.close(), this.autoHideDuration);
    }
  }

}
