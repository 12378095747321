<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4 lg:px-0">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Transport and Logistics</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            Transitioning from military service to civilian life can be a challenging yet rewarding
                            journey. One sector that offers a wealth of opportunities for Service leavers is transport
                            and logistics. This industry is the backbone of global trade and commerce, ensuring the
                            efficient movement of goods and people. With a variety of roles available, from hands-on
                            positions to strategic management, the transport and logistics sector can provide a stable
                            and fulfilling career path for those with the right skills and mindset.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-3xl lg:text-4xl font-semibold font_inter leading-[44px] mb-3">
                            Why consider a
                            career in transport and logistics?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">The transport and
                            logistics sector is a dynamic and essential part of the economy, operating
                            24/7 to meet the demands of businesses and consumers. Despite economic fluctuations, this
                            industry has shown resilience and growth, offering job security and career progression. For
                            military Service leavers, the sector presents an opportunity to leverage their skills in a
                            new environment. The Chartered Institute of Logistics and Transport (CILT) reports that it
                            is possible to reach a management position within five years, making it an attractive option
                            for those willing to start at the entry level and work their way up.</div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Logistics/Logistics 1.jpg"
                                alt="pathway-img" />
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Logistics/Logistics 2.jpg"
                                alt="pathway-img" />
                        </div>
                        <div class="justify-start items-start gap-8 inline-flex">
                            <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                src="../../../../../assets/images/sector_pathway/images/Logistics/Logistics 3.jpg"
                                alt="pathway-img" />
                            <div
                                class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                    <div class=" flex-col justify-start items-start flex">
                                        <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                            £28,500</div>
                                        <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                            Average salary</div>
                                    </div>
                                    <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                        Transport and logistics professionals in the UK enjoy competitive salaries, with
                                        entry-level positions ranging from
                                        £17k to £24k. Experienced professionals can earn significantly higher, with
                                        salaries in excess of £50K.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div
                                class="text-center text-[#331643] lg:text-5xl text-[32px] font-bold font_hindguntur leading-[64px]">
                                Is a career in transport and logistics for me?</div>
                            <div class="text-center text-[#503C5C] text-lg font-normal font_hindguntur leading-7">
                                Deciding if a career in transport and logistics is right for you depends on your
                                interests, skills and career goals. Military Service leavers possess a range of skills
                                that are highly valued in the transport and logistics sector, such as:</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Decision making</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            In transport and logistics roles, decision making skills are paramount.
                                            Professionals in this field must evaluate various factors, such as cost,
                                            time and resources, to make informed choices that optimise the supply
                                            chain. Effective decision making can include critical thinking, problem
                                            solving, data analysis, risk management, strategic planning,
                                            adaptability and collaboration.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Resilience</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Resilience is necessary within this sector as professionals often face
                                            unexpected
                                            challenges within high pressure situations. Demonstrating resilience can
                                            involve stress management, adaptability, persistence, optimism, problem
                                            solving, learning from experience and resourcefulness.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Discipline</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Discipline is a key attribute, ensuring tasks are completed efficiently and
                                            standards are consistently met. Demonstrating discipline can involve
                                            punctuality, attention to detail, adherence to regulations, strong work
                                            ethic, goal setting, accountability and continuous improvement. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Leadership skills</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Leadership skills are vital in transport and logistics roles as they involve
                                            guiding teams
                                            to achieve operational excellence. Effective leadership skills can
                                            include inspiring and motivating, visionary thinking, decision making,
                                            conflict resolution, delegation, mentorship and strong communication.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Organisational skills</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Organisational skills are fundamental within a transport and logistics role
                                            as they ensure
                                            operations can run smoothly and efficiently. Key aspects of
                                            organisational skills can include time management, attention to detail,
                                            planning and coordination, multi tasking and resource management. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-24 flex-col justify-start items-center gap-16 lg:flex block px-6 lg:px-0">
                    <div class=" lg:h16 flex-col justify-start items-center gap-4 flex">
                        <div class=" text-center text-[#331643] text-5xl font-bold font_hindguntur leading-[64px]">
                            Other things to consider...</div>
                    </div>
                    <div class="justify-start items-start gap-6 lg:flex block">
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start inline-flex lg:h-[19.5rem] mb-6 lg:mb-0">
                            <div class="p-6 bg-gray-50 flex-col justify-start items-start gap-16 flex">
                                <div class=" flex-col justify-start items-start gap-5 flex">
                                    <div class="  flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class=" text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Work environment</div>
                                        <div
                                            class=" text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            These roles are dynamic and fast-paced, often involving high-pressure
                                            environments, especially when dealing with tight deadlines. The positions
                                            are typically team-oriented, requiring close collaboration with others to
                                            ensure smooth operations. Additionally, professionals may work in a variety
                                            of settings, including offices, warehouses or even on the road, depending
                                            on the specific role.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start inline-flex lg:h-[19.5rem] mb-6 lg:mb-0">
                            <div
                                class=" grow shrink basis-0 p-6 bg-gray-50 flex-col justify-start items-start gap-16 flex">
                                <div class=" flex-col justify-start items-start gap-5 flex">
                                    <div class=" flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class=" text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Career opportunities</div>
                                        <div
                                            class=" text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            This industry is experiencing significant growth, offering numerous career
                                            advancement opportunities. With diverse roles ranging from warehouse
                                            management to supply chain analysis, there are many different paths one can
                                            take. Additionally, transport and logistics is a global industry, providing
                                            opportunities to work and travel with international companies.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex lg:h-[19.5rem]">
                            <div
                                class=" grow shrink basis-0 p-6 bg-gray-50 flex-col justify-start items-start gap-16 flex">
                                <div class="flex-col justify-start items-start gap-5 flex">
                                    <div class=" flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class=" text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Personal considerations</div>
                                        <div
                                            class=" text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            If you find the process of how goods move from manufacturers to consumers
                                            fascinating, transport and logistics might be a good fit for you. The
                                            ability to adapt to changing circumstances and new technologies is important
                                            in this field. Additionally, transport and logistics is a critical industry,
                                            often providing stable employment opportunities.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-16 lg:my-24 flex items-center justify-center">
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
            class="hidden sm:flex" />
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
            class="sm:hidden" />
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container lg:mb-24 mb-16">
            <div class="max_container">
                <div class="justify-between items-start lg:flex block mb-16">
                    <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">What
                        roles are available in transport and logistics?</div>
                    <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                        <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">The
                            transport and logistics sector encompasses a wide range of roles, catering to various
                            interests and skill sets. Some examples of these include:</div>
                    </div>
                </div>
                <div class="w-full relative">
                    <div class="carousel-container">
                        <div class="carousel-track">
                            <div *ngFor="let slide of slides" class="carousel-slide">
                                <div
                                    class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                    <div
                                        class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                            <div
                                                class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                {{ slide.title }}
                                            </div>
                                            <div
                                                class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                {{ slide.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Carousel navigation buttons -->
                        <div class="button-container">
                            <button id="slider-button-left"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                        alt="arrow-left"></div>
                            </button>
                            <button id="slider-button-right"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                        alt="arrow-right"></div>
                            </button>
                        </div>

                        <!-- Pagination -->
                        <div class="carousel-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container bg-[#F9FAFB]">
            <div class="max_container">
                <div>
                    <div
                        class="lg:py-24 py-16 bg-gray-50 rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
                        <div class="self-stretch lg:h11 justify-between items-center lg:flex block">
                            <div class="max-w-[44rem] mb-8">
                                <div class="text-[#331643] text-[32px] font-bold font_hindguntur leading-[48px]">
                                    Featured jobs</div>
                                <div class="text-[#4f3b5b] text-base font-normal font_hindguntur leading-normal">
                                    Transport and logistics professionals in the UK enjoy competitive salaries, with
                                    entry-level positions ranging from £17k to £24k. Experienced professionals can earn
                                    significantly higher, with salaries in excess of £50k. Jobs within the transport and
                                    logistics industry include:</div>
                            </div>
                            <div
                                class="px-4 py-2.5 bg-white rounded-full shadow border border-[#d2d6db] justify-center items-center gap-1.5 flex">
                                <div class="px-0.5 pt-[5px] justify-center items-center flex cursor-pointer text-[#384250] text-base font-medium font_hindguntur leading-normal"
                                    (click)="goToJobs()">
                                    View more jobs
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:gap-6 gap-4">
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Transport & Logistics</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Warehouse Staff</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="lg:h7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £17,000-£24,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    play a crucial role in the logistics industry with tasks that include receiving
                                    goods, managing inventory and preparing items for dispatch. Salaries range from
                                    £17k-£24k, with opportunities for advancement. </div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Transport & Logistics</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Warehouse Manager</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="lg:h7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £18,000-£40,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    play a pivotal role in the industry which includes overseeing all warehouse
                                    operations, managing teams of staff and ensuring efficient processes for a smooth
                                    day-to-day operation. Salaries range from £18k-£40k+ depending on experience.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Transport & Logistics</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Driver (HGV/PSV)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="lg:h7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 lg:h5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £20,000-£50,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    can work in various capacities, from local deliveries to long-haul trips.
                                    Progression is possible from smaller vehicles to larger ones and into management
                                    roles. Salaries range from £20k-£50k+. </div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Transport & Logistics</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Transport Manager</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="lg:h7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £25,000-£50,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    is responsible for ensuring vehicles are roadworthy and drivers comply with
                                    regulations. This role involves managing budgets, schedules and compliance. Salaries
                                    range from £25k-£50k. </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container bg-[#724EB3]">
            <div class="max_container">
                <div class="lg:py-24 py-16">
                    <div class="px-8 justify-start items-start gap-16 lg:flex block">
                        <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div class="self-stretch lg:h[236px] flex-col justify-start items-start gap-6 flex">
                                <div class="">
                                    <img src="../../../../../assets/images/sector_pathway/Featured icon.svg" alt="icon">
                                </div>
                                <div
                                    class="self-stretch text-white text-3xl lg:text-4xl font-semibold font_hindguntur leading-[52px]">
                                    What’s it like to work within transport and logistics?</div>
                            </div>
                            <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                Working in logistics can be dynamic and rewarding, offering a variety of experiences and
                                opportunities. Here’s a glimpse into what it’s like:</div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Daily responsibilities</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Depending on your role, your daily responsibilities could include planning and
                                        coordinating the movement of goods to ensure smooth operations from point A to
                                        point B. You may also need to address unexpected issues, such as delays or
                                        inventory discrepancies, with quick thinking and effective solutions. Having
                                        regular communication with suppliers, customers, and team members is crucial to
                                        keep everyone informed and aligned.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Career growth</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        The transport and logistics industry offers numerous opportunities for
                                        advancement, allowing you to progress from entry-level positions to management
                                        roles if this is the route you want to take. Additionally, you’ll also have the
                                        chance to develop a wide range of skills, including project management, data
                                        analysis and customer service.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Rewards</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Transport and logistics plays a crucial role in ensuring that products reach
                                        consumers, making your work essential and impactful. Many logistics roles offer
                                        competitive salaries, especially for specialized skills. Additionally, the field
                                        is constantly evolving, providing opportunities to work on innovative projects
                                        and improve processes.</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Work environment</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        The work environment in transport and logistics can be fast-paced and
                                        high-energy, where rapid changes keep you on your toes. Collaboration is key, as
                                        you'll work closely with others to achieve common goals. Depending on your role,
                                        you might find yourself working in an office, a warehouse or even traveling to
                                        different locations.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Challenges</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Meeting tight deadlines and managing multiple tasks can be stressful at times,
                                        requiring you to handle pressure effectively. Additionally, the ability to adapt
                                        to new technologies and changing circumstances is essential in this dynamic
                                        environment.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<vc-footer></vc-footer>