import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'vc-security-view',
  templateUrl: './security-view.component.html'
})
export class SecurityViewComponent {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Security Officer',
      description: `Plays a crucial role in maintaining the safety and security of premises and people. Their responsibilities include patrolling designated areas, 
      monitoring surveillance equipment, and responding to any incidents or emergencies. They ensure that all security protocols are followed to protect property and individuals. 
      The role requires vigilance, attention to detail, and the ability to remain calm under pressure. Security officers often work in various environments, from corporate buildings 
      to public events, adapting to different security needs. The average salary for a security officer in the UK ranges from £20k at entry level to £35k for those with more experience, 
      reflecting the importance and responsibility of the position.`
    },
    {
      title: 'Private Investigator',
      description: `Plays a vital role in uncovering information for private clients. Their duties include conducting thorough investigations, performing discreet 
      surveillance, and gathering crucial information that can range from personal matters to corporate inquiries. They often work on cases involving fraud, missing 
      persons, background checks, and infidelity, requiring a keen eye for detail and strong analytical skills. The nature of their work demands a high level of discretion, 
      patience, and the ability to think critically under various circumstances. Salaries for private investigators can vary widely based on their experience, expertise, 
      and the complexity of the cases they handle, typically starting from around £25k and potentially reaching up to £50k per year. This range reflects the diverse and often 
      challenging nature of their work, as well as the value they provide to their clients.`
    },
    {
      title: 'Close Protection Officer',
      description: `Commonly known as a bodyguard, is responsible for providing personal protection to high-profile clients, such as celebrities, politicians, and business 
      executives. This role demands advanced training in areas such as defensive tactics, threat assessment, and emergency response to ensure the safety of their clients in 
      various situations. Close protection officers must be highly vigilant, physically fit, and capable of making quick decisions under pressure. They often work in dynamic and 
      potentially high-risk environments, requiring them to adapt swiftly to changing circumstances. The salary for a close protection officer can vary significantly, typically 
      ranging from £30k to £100k per year, depending on the client’s profile, the level of risk involved, and the officer’s experience and expertise. This wide salary range reflects 
      the critical nature of their responsibilities, and the high stakes associated with their role.`
    },
    {
      title: 'Event Security',
      description: `Plays a crucial role in ensuring the safety and well-being of attendees at various events, such as concerts, sports events, festivals, and large public gatherings. 
      Their responsibilities include crowd control, monitoring entry and exit points, conducting security checks, and responding to any incidents or emergencies that may arise. 
      They must be vigilant, able to assess potential threats quickly, and capable of maintaining order in potentially chaotic environments. Effective communication and teamwork 
      are essential, as they often work closely with event organisers, law enforcement, and emergency services. Salaries for event security personnel typically range from £18k to £30k per year, 
      depending on factors such as the size and frequency of the events they cover, as well as their level of experience and specific duties. This range reflects the importance of their role in 
      creating a safe and secure environment for all attendees.`
    },
    {
      title: 'Fire and Security Engineer',
      description: `Plays a pivotal role in ensuring the safety and security of buildings and their occupants by leading the installation, servicing, and maintenance of fire alarm and security systems.
       These professionals are responsible for designing and implementing systems that detect and respond to fires, intrusions, and other emergencies. Their work involves a deep understanding 
       of both electrical and mechanical systems, as well as compliance with safety regulations and standards. Fire and security engineers must possess strong problem-solving skills, attention 
       to detail, and the ability to work independently or as part of a team. They often collaborate with other engineers, contractors, and clients to ensure systems are installed correctly and function optimally. The average salary for a fire and security engineer ranges from £25k to £40k annually, reflecting the technical expertise and critical nature of their work in protecting lives and property.`
    },
    {
      title: 'Corporate Security Officer',
      description: `Plays a crucial role in maintaining high standards of security within corporate environments. Their responsibilities often include monitoring surveillance systems, 
      managing access control, conducting security audits, and responding to incidents. These professionals ensure the safety of employees, protect company assets, and maintain a secure 
      working environment. Salaries for corporate security officers in the UK typically range from £25k to over £57k annually, depending on factors such as experience, location, and the 
      specific requirements of the role. This career offers a blend of routine tasks and dynamic challenges, making it a vital part of corporate operations.`
    }
  ];

  constructor(
    private readonly title: Title,
  ) {
    this.title.setTitle('Security');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
  }

  nextSlide(track: HTMLElement): void {
    const slidesToShow = 2;
    const maxIndex = this.slides.length - 1;

    if (this.currentSlideIndex < maxIndex) {
      this.currentSlideIndex++;
      this.moveToSlide(track);
    }
   
  }

  prevSlide(track: HTMLElement): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track);
    }
  }

  moveToSlide(track: HTMLElement): void {
    const isMobileView = window.innerWidth <= 768;
    const trackWidth = track.clientWidth;
    const isLastSlide = this.currentSlideIndex === this.slides.length - 1;
    const isSecondLastSlide = this.currentSlideIndex === this.slides.length - 2;

    if (isMobileView) {

      const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955);
      track.style.transform = `translateX(${amountToMove}px)`;
    } else {
      if (isLastSlide || isSecondLastSlide) {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;
      } else {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;

        const slides = document.querySelectorAll('.carousel-slide') as NodeListOf<HTMLElement>;
        slides.forEach(slide => slide.classList.remove('next-slide'));

        if (this.currentSlideIndex < this.slides.length - 1) {
          slides[this.currentSlideIndex + 1].classList.add('next-slide');
        }
        if (screen.width < 480) {
          const amountToMove = -(this.currentSlideIndex * (trackWidth * 1));
          track.style.transform = `translateX(${amountToMove}px)`;
        }
      }
    }
    this.updatePagination();
  }
  setupPagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    pagination.innerHTML = '';

    this.slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index));
      pagination.appendChild(bullet);
    });

    this.updatePagination();
  }
  goToSlide(index: number): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    this.currentSlideIndex = index;
    this.moveToSlide(track);
  }
  updatePagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    const bullets = Array.from(pagination.children) as HTMLElement[];

    bullets.forEach((bullet, index) => {
      if (index === this.currentSlideIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }
}

