import {Injectable, EventEmitter} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({providedIn:'root'})
export class deletePopUpService {
  private isFormDirtySubject = new BehaviorSubject<boolean>(false);
  isFormDirty$ = this.isFormDirtySubject.asObservable();

  private signOutEvent = new Subject<boolean>(); 
  signOutEvent$ = this.signOutEvent.asObservable();

  
  setFormDirty(isDirty: boolean) {
    this.isFormDirtySubject.next(isDirty);
  } 

  emitSignOutEvent(isFormDirty: boolean) {
    this.signOutEvent.next(isFormDirty);
  }
}