import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  private updatePrompted = false; // Flag to track if prompt has been shown
  private updateSubject = new Subject<void>(); // Subject to control prompting

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {

    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
      const interval$ = interval(environment.appUpdateCheckInterval); 
      const everyIntervalOnceAppIsStable$ = concat(appIsStable$, interval$);
  
      everyIntervalOnceAppIsStable$.subscribe(async () => {
      try {

        this.checkForUpdates();
      } catch (err) {

        console.error('Failed to check for updates:', err);
      }
    });



    // Subscribe to version updates
    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        if (!this.updatePrompted) {
          this.updatePrompted = true; // Set the flag to prevent multiple prompts
          this.updateSubject.next(); // Emit a notification to prompt user
        }
      });

    // Subscribe to update subject to prompt user
    this.updateSubject.subscribe(() => this.promptUser());
  }

  public checkForUpdates(): void {
    this.updates.checkForUpdate().then(updateFound => {
    }).catch(err => {
      console.error('Error when checking for updates:', err);
    });
  }

  private promptUser(): void {
    const userConfirmed = confirm('A new version is available. Would you like to reload the app to update?');

    if (userConfirmed) {
      this.updates.activateUpdate().then(() => {
        // Clear caches to ensure the new version is loaded
        if ('caches' in window) {
          caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
              caches.delete(cacheName);  // Clear the cache
            });
          }).finally(() => {
            // Reload the page after clearing the cache
            document.location.reload();
          });
        } else {
          // Reload without cache clearing if no cache support
          document.location.reload();
        }
      });
    } 
  }
}
