<div class="w-full">
  <div class="w-full home_sub_container">
    <div class="max_container">
      <div class="lg:pt-24 pt-16">
        <div>
          <div class="text-center text-[#331643] text-lg font-medium font_hindguntur leading-7">Sector Guides
          </div>
          <div class="text-center text-[#331643] text-5xl lg:text-7xl font-bold font_hindguntur_bold lg:leading-[88px] leading-[64px]">
            Discover more about<br>
            different careers</div>
          <div class="text-center text-[#4f3b5b] text-lg font-normal font_hindguntur leading-7">
            Learn about different career paths and how to begin your journey to a new job</div>
        </div>
        
        <div class="lg:py-24 py-16 relative hidden md:flex justify-center">
          <div class="relative grid grid-cols-1 md:grid-cols-4 gap-y-8 md:gap-x-12">
            
            <div class="w-full flex flex-col items-center">
              <div class="relative z-10">
                <img class="mx-auto hidden md:block" src="../../../../assets/images/sector_svg/sector_pathway1.svg" alt="Discovery">
                <img class="block md:hidden" src="../../../../assets/images/sector_svg/sector_pathway_sm_1.svg" alt="Discovery">
              </div>
              <h5 class="sp_design_title">Discovery</h5>
              <p class="sp_design_paragraph">Explore sector insights, experience taster days and discover job roles for your journey ahead</p>
            </div>
        
            <div class="w-full flex flex-col items-center">
              <div class="relative z-10">
                <img class="mx-auto hidden md:block" src="../../../../assets/images/sector_svg/sector_pathway2.svg" alt="Design">
                <img class="block md:hidden" src="../../../../assets/images/sector_svg/sector_pathway_sm_2.svg" alt="Design">
              </div>
              <h5 class="sp_design_title">Design</h5>
              <p class="sp_design_paragraph">Assess your skills, explore employer insights and unlock vacancies with pre-screening</p>
            </div>
        
            <div class="w-full flex flex-col items-center">
              <div class="relative z-10">
                <img class="mx-auto hidden md:block" src="../../../../assets/images/sector_svg/sector_pathway3.svg" alt="Develop">
                <img class="block md:hidden" src="../../../../assets/images/sector_svg/sector_pathway_sm_3.svg" alt="Develop">
              </div>
              <h5 class="sp_design_title">Develop</h5>
              <p class="sp_design_paragraph">Engage in sector-focused specialist training for personal and professional growth</p>
            </div>
        
            <div class="w-full flex flex-col items-center">
              <div class="relative z-10">
                <img class="mx-auto hidden md:block" src="../../../../assets/images/sector_svg/sector_pathway4.svg" alt="Destination">
                <img class="block md:hidden" src="../../../../assets/images/sector_svg/sector_pathway_sm_4.svg" alt="Destination">
              </div>
              <h5 class="sp_design_title">Destination</h5>
              <p class="sp_design_paragraph">Secure interviews, explore civilian work attachments and receive in-work support for success</p>
            </div>
        
            <div class="absolute lg:top-[20%] md:top-[20%] transform -translate-y-1/2 left-[4rem] right-[4rem] px-12">
              <hr class="border-t border-gray-300 md:block hidden">
            </div>
        
          </div>
        </div>
        
        <!-- Mobile -->
        <div class="md:hidden flex relative py-16">
          <div class="w-[1px] bg-[#E5E7EB] h-[70%] absolute left-[7.5%] transform -translate-x-1/2 z-0 mt-1"></div>
        
          <div class="relative grid grid-cols-1 md:hidden gap-y-8 z-10">
            <div class="w-full flex items-start space-x-4">
              <img class="mt-1" src="../../../../assets/images/sector_svg/sector_pathway_sm_1.svg" alt="Discovery">
              <div>
                <h5 class="sp_design_title">Discovery</h5>
                <p class="sp_design_paragraph">Explore sector insights, experience taster days and discover job roles for your journey ahead</p>
              </div>
            </div>
            <div class="w-full flex items-start space-x-4">
              <img class="mt-1" src="../../../../assets/images/sector_svg/sector_pathway_sm_2.svg" alt="Design">
              <div>
                <h5 class="sp_design_title">Design</h5>
                <p class="sp_design_paragraph">Assess your skills, explore employer insights and unlock vacancies with pre-screening</p>
              </div>
            </div>
            <div class="w-full flex items-start space-x-4">
              <img class="mt-1" src="../../../../assets/images/sector_svg/sector_pathway_sm_3.svg" alt="Develop">
              <div>
                <h5 class="sp_design_title">Develop</h5>
                <p class="sp_design_paragraph">Engage in sector-focused specialist training for personal and professional growth</p>
              </div>
            </div>
            <div class="w-full flex items-start space-x-4">
              <img class="mt-1" src="../../../../assets/images/sector_svg/sector_pathway_sm_4.svg" alt="Destination">
              <div>
                <h5 class="sp_design_title">Destination</h5>
                <p class="sp_design_paragraph">Secure interviews, explore civilian work attachments and receive in-work support for success</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <!-- Card 1 -->
          <div
            class="max-w-[37rem] max-h-[28.25rem] w-full min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/Automotive/Cover.jpg')"
            (click)="viewPage1()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[12rem] sm:top-[15rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Automotive</h1>
              <p>Find out more about how a career in the Automotive sector would be good for you</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>

          <!-- Card 2 -->
          <div
            class="max-w-[37rem] max-h-[28.25rem] w-full min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/Charity/cover.jpg')"
            (click)="viewPage2()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[12rem] sm:top-[15rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Charity</h1>
              <p>Find out more about how a career in the Charity sector would be good for you</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>

          <!-- Card 3 -->
          <div
            class="max-w-[37rem] max-h-[28.25rem] w-full min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/CyberSecurity/cover.jpg')"
            (click)="viewPage3()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[12rem] sm:top-[15rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Cyber Security</h1>
              <p class="max-w-fit text-wrap min-h-[3rem]">Find out more about how a career in Cyber Security would be good for you.</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>

          <!-- Card 4 -->
          <div
            class="max-w-[37rem] max-h-[28.25rem] w-full min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/Logistics/cover.jpg')"
            (click)="viewPage4()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[13.5rem] sm:top-[13.5rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Logistics</h1>
              <p class="max-w-fit text-wrap min-h-[3rem]">Find out more about how a career in Logistics would be good for you.</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="my-16 lg:my-24 flex items-center justify-center">
    <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
        class="hidden sm:flex" />
    <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
        class="sm:hidden" />
</div>

  <div class="w-full">
    <div class="w-full home_sub_container">
      <div class="max_container">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:pb-24 pb-16">
          <!-- Card 1 -->
          <div
            class="max-w-[37rem] w-full max-h-[28.25rem] min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/Security/cover.jpg')"
            (click)="viewPage5()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[13.5rem] sm:top-[15rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Security</h1>
              <p class="max-w-fit text-wrap min-h-[3rem]">Find out more about how a career in Security would be good for you.</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>

          <!-- Card 2 -->
          <div
            class="max-w-[37rem] max-h-[28.25rem] w-full min-h-96 md:min-h-[28.25rem] rounded-2xl relative z-0 bg-cover bg-no-repeat cursor-pointer mx-auto"
            style="background-image: url('../../../../assets/images/sector_pathway/images/WindEnergy/cover.jpg')"
            (click)="viewPage6()">
            <div class="bg-gradient-to-b from-black to-black opacity-50 h-full w-full absolute z-10 rounded-2xl">
            </div>
            <div
              class="text-white font_hindguntur md:top-[17rem] top-[12rem] sm:top-[15rem] md:left-10 left-4 relative z-20 rounded-2xl max-w-[80%]">
              <h1 class="max-w-fit sector_title">Wind Energy</h1>
              <p class="max-w-fit text-wrap min-h-[3rem]">Find out more about how a career in the Wind Energy sector would be good for you.</p>
              <button class="rounded-full px-6 py-2.5 border border-white mt-4">Explore</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <vc-shared-featured-articles></vc-shared-featured-articles>
</div>
<vc-footer></vc-footer>