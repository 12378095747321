import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { Url } from 'src/app/shared/constants/url-constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class servicesService {
  private chatEventSubject = new Subject<any>();
  chatEvent$ = this.chatEventSubject.asObservable();

  constructor(
    private http: HttpClient,
    private readonly uploadService: FileUploadService,
    private readonly router: Router
  ) { }

  getCoursesList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/courses/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getCourseGraphQLList(sort: string, page: number, size: number, collection, body){
    const href = `${Url.CMS_PROXY_API}/open/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  getCoursesView(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/courses/read/${id}`;
    return this.http.get<any>(href);
  }

  getCourseDates(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/course-deliveries/combine/${id}`;
    return this.http.get<any>(href);
  }

  getCourseStatus(id:any,courseDeliveryId:any) {
    const href = `${Url.BOOKING}/course-booking/status/${id}/${courseDeliveryId}`;
    return this.http.get<any>(href);
  }

  sendAlretme(data:any) {
    const href = `${Url.BOOKING}/course-booking/alertme`;
    return this.http.post<any>(href,data);
  }
  
  bookCourse(data:any) {
    const href = `${Url.BOOKING}/booking/new`;
    return this.http.post<any>(href,data);
  }

  bookCourseNcf(formData:any) {
    const href = `${Url.BOOKING}/ncf-booking/new`;
    return this.uploadService.uploadFile(href, formData,'POST');
  }
  
  getRefGraphQLList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  getRefGraphQLList1(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        // .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  
  getEventsList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/events/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  
  geteventView(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/events/read/${id}`;
    return this.http.get<any>(href);
  }

  getEventCategoryList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  geteventDates(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/event-deliveries/combine/${id}`;
    return this.http.get<any>(href);
  }

  getEventStatus(id:any,eventDeliveryId:any) {
    const href = `${Url.BOOKING}/booking-event/status/${id}/${eventDeliveryId}`;
    return this.http.get<any>(href);
  }

  bookEvent(data:any) {
    const href = `${Url.BOOKING}/booking-event/new`;
    return this.http.post<any>(href,data);
  }

  initiatePaymentRequest(data:any) {
    const href = `${Url.BOOKING}/payment/initiate-request`;
    return this.http.post<any>(href, data);
  }
  
  getWorkshopView(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/workshops/read/${id}`;
    return this.http.get<any>(href);
  }
  getWorkshopDates(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/workshop-deliveries/combine/${id}`;
    return this.http.get<any>(href);
  }
  getWorkshopsList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/open/workshops/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  getWorkshopCategoryList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${Url.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  getWorkshopStatus(id:any,workshopDeliveryId:any) {
    const href = `${Url.BOOKING}/booking-workshop/status/${id}/${workshopDeliveryId}`;
    return this.http.get<any>(href);
  }
  bookWorkshop(data:any) {
    const href = `${Url.BOOKING}/booking-workshop/new`;
    return this.http.post<any>(href,data);
  }

  statusApi(data:any){
    const href = `${Url.BOOKING}/payment/shopper-response`;
    return this.http.post<any>(href, data);
  }

  getPersonalisedCourse(){
    let rrcLocation = JSON.parse(localStorage.getItem('locationDetails'))?.rrcName;
    const href = `${Url.CMS_PROXY_API}/personalize/courses`;
    return this.http.post<any>(href,{rrcLocation});
  }
  
  getbookingDetails(bookingId:any){
    const href = `${Url.BOOKING}/payment/details/${bookingId}`;
    return this.http.get<any>(href);
  }

  getPersonalisedEvents(){
    let rrcLocation = JSON.parse(localStorage.getItem('locationDetails'))?.rrcName;
    const href = `${Url.CMS_PROXY_API}/personalize/events`;
    return this.http.post<any>(href,{rrcLocation});
  }
  getUserBookings() {
    const href = `${Url.BOOKING}/dashBoard/SU/allBookings`;
    return this.http.get<any>(href);
  }
  
  emitEvent(data: any) {
    this.chatEventSubject.next(data);
  }

  getCompareCoursesView(id:any) {
    const href = `${Url.CMS_PROXY_API}/open/course-deliveries/compare/${id}`;
    return this.http.get<any>(href);
  }
}