import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SrmService {

  constructor(private readonly http: HttpClient) { }

  getChatConversation(): Observable<any> {
    const href = `${Url.SRM}/srm/chat/search`;
    return this.http.get<any>(href);
  }

  getChatMessages(size: number, page: number, body: any): Observable<any> {
    const href = `${Url.SRM}/srm/messages/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
    }

    );
  }
  getTopThreeReceivedChat(): Observable<any> {
    return this.http.get<any>(`${Url.SRM}/srm/chat/search-for-notification`);
  }

  getUnreadMessageCount(): Observable<number> {
    const href = `${Url.SRM}/srm/messages/message-count`;
    return this.http.get<any>(href);
  }

  readAllMessages(chatObj): Observable<any> {
    const href = `${Url.SRM}/srm/messages/readall`;
    return this.http.post<any>(href, chatObj);
  }
  
  getKwList(identifier): Observable<any> {
    const href = `${Url.USER}/user/client-based-user/${identifier}`;
    return this.http.get<any>(href);
  }

  getKeyworkerList(identifier): Observable<any> {
    const href = `${Url.USER}/user/users-by-identifier`;
    return this.http.get<any>(href,{
      params: new HttpParams().set('identifiers',identifier)
    });
  }

  create(body: any) {
    const href = `${Url.SRM}/srm/messages/create`;
    return this.http.post<any>(href, body);
  }

  getFilterUserList(id) {
    const href = `${Url.USER}/user/getFilteredUserList/${id}`;
    return this.http.get<any>(href);
  }

  getUserList(id) {
    const href = `${Url.USER}/user/getUserList/${id}`;
    return this.http.get<any>(href);
  }


}
