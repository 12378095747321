import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Injectable, Output } from '@angular/core';
import { Itabs } from './tabs.interface';
import { EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TabsService {
  @Output()  hideChatCount: EventEmitter<boolean> = new EventEmitter();
  @Output() showManuItem: EventEmitter<boolean> = new EventEmitter();
  chat: EventEmitter<number> = new EventEmitter();
  chatFocus: EventEmitter<number> = new EventEmitter();
  @Output() refreshQuickChat: EventEmitter<boolean> = new EventEmitter();
  @Output()  signOutClicked: EventEmitter<boolean> = new EventEmitter();
  profileNavigation: Itabs[];

  private readonly tabSource = new BehaviorSubject(null);
  currentTab = this.tabSource.asObservable();

  constructor() { }

  public tab(data: Itabs[]) {
    this.tabSource.next(data);
  }
  setHideChatCount(flag) {
    this.hideChatCount.emit(flag);

  }
  emitChat(chatId) {
    this.chat.emit(chatId);
  }
  emitChatFocus(flag){
    this.chatFocus.emit(flag);
  }
  emitRefreshQuickChat(flag) {
    this.refreshQuickChat.emit(flag);
  }

  emitshowManuItem(flag) {
    this.showManuItem.emit(flag);
  }

  emitSignOutClicked(flag) {
    this.signOutClicked.emit(flag);
  }
}
