<div class="w-full">
    <div class="w-full bg-logo home_sub_container md:px-4">
        <div class="max_container">
            <div class="py-16 md:py-24 px-6 md:px-0 articles-guides_flex-start gap-12 md:gap-16 flex">
                <div class="justify-start items-start lg:flex block">
                    <div class="lg:min-w-[594px] lg:w-1/2 w-full justify-between flex-col items-start flex ">
                        <div class="text-[#F4EBFC] articles-guides_button">Learn &
                            Discover</div>
                        <div
                            class="text-[#F4EBFC] text-4xl md:text-5xl font-semibold font_hindguntur leading-[64px] md:leading-[64px]">
                            Articles &
                            Guides</div>
                    </div>
                    <div class="md:max-w-[486px] articles-guides_flex-start gap-4 flex !w-full">
                        <div class="text-[#F4EBFC] text-lg font-normal font_hindguntur leading-7 text-wrap !w-full">Discover all the latest news, information and guides exclusively available from the CTP.
                        </div>
                        <div class="px-[18px] py-3 rounded-[120px] shadow border border-gray-300 justify-center items-center gap-1.5 flex cursor-pointer"
                            (click)="allArticles()">
                            <div class="articles-guides_filter">
                                <div class="text-white articles-guides_button">All
                                    Articles & Guides</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="featuredHits?.length" class="w-full gap-8 grid grid-cols-1 lg:grid-cols-3">
                    <div class="rounded-2xl gap-4 relative group flex cursor-pointer card_parent flex-col"
                        *ngFor="let article of featuredHits; let i = index"
                        (keydown.enter)="detailedView(article?.id)" (click)="detailedView(article?.id)">
                        <div *ngIf="userId"
                            class="bg-white flex items-center justify-center rounded-full absolute top-[160px] left-[16px]">
                            <img *ngIf="isArticleFavorite(article?.id)" class="p-2 hover:scale-105"
                                src="../../../assets/images/resourses_svg/favourite.svg" alt="heart-icon"
                                (click)="$event.stopPropagation();favouritesSection('remove',article?.id)">
                            <img *ngIf="!isArticleFavorite(article?.id)" class="p-2 hover:scale-105"
                                src="../../../assets/images/resourses_svg/heart.svg" alt="heart-icon"
                                (click)="$event.stopPropagation();favouritesSection('add',article?.id)">
                        </div>
                        <div class="card_img_parent">
                            <img class="card_img" [src]="article?.image?.url ||                               
                                                '../../../../../assets/images/Courses_SVG/Trio-image.svg'"
                                [alt]="article?.imageAlt" alt="Course Title" />
                        </div>
                        <div class="articles-guides_flex-start gap-6 flex pb-2 px-2">
                            <div class="articles-guides_flex-start gap-3 flex">
                                <div class="px-2 pt-1 rounded-full border border-neutral-50 articles-guides_inlineflex-start" *ngIf="article?.ref_news_article_type?.name">
                                    <div class="articles-guides_Leavers-chip">{{article?.ref_news_article_type?.name| truncate : [30,"..."]}}</div>
                                </div>
                                <div class="articles-guides_flex-start flex">
                                    <div class="text-white text-xl font-semibold font_hindguntur leading-loose">{{ article?.newsArticleName }}</div>
                                    <div
                                        class=" opacity-70 text-white text-sm font-normal font-['Inter'] leading-tight">{{ article?.summary | truncate : [150, "..."] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>