import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { CourseCompareComponent } from './core/components/header/course-compare/course-compare.component';
import { AutomotiveViewComponent } from './authentication/pre-signin/sector-pathways/automotive-view/automotive-view.component';
import { CharityViewComponent } from './authentication/pre-signin/sector-pathways/charity-view/charity-view.component';
import { CyberSecurityViewComponent } from './authentication/pre-signin/sector-pathways/cyber-security-view/cyber-security-view.component';
import { logistcisViewComponent } from './authentication/pre-signin/sector-pathways/logistics-view/logistics-view.component';
import { SectorPathwaysComponent } from './authentication/pre-signin/sector-pathways/sector-pathways.component';
import { SecurityViewComponent } from './authentication/pre-signin/sector-pathways/security-view/security-view.component';
import { WindEnergyComponent } from './authentication/pre-signin/sector-pathways/wind-energy/wind-energy.component';
import { SetNewPasswordComponent } from './features/user-profile/set-new-password/set-new-password.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./authentication/pre-signin/courses/courses.module').then(mod => mod.CoursesModule),
    data: { title: 'Log in to your Reed account' }
  },
  {
    path: 'home/jobs',
    loadChildren: () => import('./../app/authentication/pre-signin/jobs/jobs.module').then(mod => mod.JobsModule),
    data: { title: 'jobs' },
    canActivate: [AuthenticatedGuard]

  },
  {
    path: 'home/directory-of-services',
    loadChildren: () => import('./authentication/pre-signin/directory-of-services/directory-of-services.module').then(mod => mod.DirectoryofServicesModule),
    data: { title: 'Log in to your Reed account' }
  },
  {
    path: 'home/sign-in',
    loadChildren: () => import('./authentication/sign-in/sign-in.module').then(mod => mod.SignInModule),
    data: { title: 'Log in to your CAPTR account' }
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./authentication/sign-up/sign-up.module').then(mod => mod.SignUpModule),
    data: { title: 'Create an account' }
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./authentication/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule),
    data: { title: 'Reset your password' }
  },
  {
    path: 'set-password',
    loadChildren: () => import('./authentication/set-password/set-password.module').then(mod => mod.SetPasswordModule),
    data: { title: 'Set password' }
  },
  {
    path: 'set-new-password',
    component: SetNewPasswordComponent 
  },
  {
    path: 'home/articles-guide',
    loadChildren: () => import('./authentication/articles-and-guides/articles-and-guides.module').then(mod => mod.ArticlesAndGuidesModule),
  },
  { path: 'compare-courses', 
    component: CourseCompareComponent 
  },
  {
    path: 'employers',
    loadChildren: () => import('./authentication/pre-signin/content-pages/employers/employers.module').then(mod => mod.EmployersModule),
  },
  {
    path: 'training-providers',
    loadChildren: () => import('./authentication/pre-signin/content-pages/training-providers/training-providers.module').then(mod => mod.TraningProvidersModule),
  },
  {
    path: 'contact-reed',
    loadChildren: () => import('./authentication/pre-signin/content-pages/contact-reed/contact-reed.module').then(mod => mod.ContactReedModule),
  },
  {
    path: 'service-leavers',
    loadChildren: () => import('./authentication/pre-signin/content-pages/service-leavers/service-leavers.module').then(mod => mod.ServiceLeaversModule),
  },
  { path: 'sector-guides', 
    component: SectorPathwaysComponent 
  },
  { path: 'sector-guides/logistics-view', 
    component: logistcisViewComponent 
  },
  { path: 'sector-guides/automotive-view', 
    component: AutomotiveViewComponent 
  },
  { path: 'sector-guides/charity-view', 
    component: CharityViewComponent 
  },
  { path: 'sector-guides/cyber-security-view', 
    component: CyberSecurityViewComponent 
  },
  { path: 'sector-guides/wind-energy-view', 
    component: WindEnergyComponent 
  },
  { path: 'sector-guides/security-view', 
    component: SecurityViewComponent 
  },
  {
    path: '**',
    redirectTo: 'content-unavailable',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration:'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
