<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4 lg:px-0">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Automotive</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            The automotive industry in the UK encompasses a wide range of companies and activities
                            involved in the design, development, manufacturing, marketing, selling, repairing and
                            modification of motor vehicles. This industry produces a variety of vehicles, including
                            passenger cars, light trucks, vans and commercial vehicles such as delivery trucks and
                            larger transport trucks. British military Service leavers are particularly well-suited for
                            the automotive industry for several reasons. Many military roles involve working with
                            advanced machinery and technology, which translates well to the technical demands of the
                            automotive industry, such as experience with high voltage equipment and mechanical systems.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-4xl font-semibold font_inter leading-[44px] mb-3">Why consider a
                            role in automotive?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">British military
                            Service leavers should consider a role in the automotive industry for several compelling
                            reasons. Firstly, the technical skills acquired in the military, such as working with
                            advanced machinery and high voltage equipment, are highly transferable to the automotive
                            sector. The discipline, reliability and attention to detail instilled by military service
                            are invaluable in an industry that prioritises precision and safety. Additionally, military
                            personnel are trained to solve problems under pressure, a skill that is crucial for
                            addressing technical issues in automotive roles. Leadership and teamwork experience gained
                            in the military also translates well to managing projects and collaborating with colleagues
                            in the automotive industry.
                            The ability to adapt to new environments and technologies is also beneficial in the rapidly
                            evolving automotive sector, especially with the shift towards electric and autonomous
                            vehicles. Programs such as Mission Automotive facilitate this transition by providing
                            industry recognised qualifications and fostering relationships between automotive companies
                            and the armed forces community, ensuring that Service leavers can effectively apply their
                            skills and experience to their new roles. This makes the automotive industry a promising and
                            rewarding career path for military Service leavers.
                        </div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Automotive/Automotive1.jpg"
                                alt="pathway-img" />
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Automotive/Automotive2.jpg"
                                alt="pathway-img" />
                        </div>
                        <div class="justify-start items-start gap-8 inline-flex">
                            <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                src="../../../../../assets/images/sector_pathway/images/Automotive/Automotive3.jpg"
                                alt="pathway-img" />
                            <div
                                class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                    <div class=" flex-col justify-start items-start flex">
                                        <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                            £31,700</div>
                                        <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                            Average salary</div>
                                    </div>
                                    <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                        Professionals in the UK Automotive sector enjoy competitive salaries with entry level positions ranging from £21K
                                        to £30K. Experienced professionals can earn more, with salaries in excess of £40K depending on the role.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div
                                class="text-center text-[#331643] lg:text-5xl text-[32px] font-bold font_hindguntur leading-[64px]">
                                Is a career in automotive right for me?</div>
                            <div class="text-center text-[#503C5C] text-lg font-normal font_hindguntur leading-7">
                                Deciding if a career in the automotive industry is right for you depends on several
                                factors, including your interests, skills, and career goals. Here are some points to
                                consider:</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretc flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Interests and passion</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            This sector is ideal for those who love vehicles and are passionate
                                            about understanding their mechanics. Additionally, if you are a
                                            technology enthusiast, you’ll find the automotive industry particularly
                                            exciting as it is rapidly evolving with new technologies such as
                                            electric vehicles and autonomous driving. This dynamic environment
                                            offers numerous opportunities to engage with cutting-edge innovations
                                            and contribute to the future of transportation.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Skills and strengths</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Roles in engineering, design
                                            and repair require strong technical skills and problem-solving
                                            abilities. Precision and attention to detail are crucial, especially in
                                            positions related to safety and quality control. Additionally, if you
                                            enjoy interacting with people, roles in sales or customer service might
                                            be particularly appealing, as they allow you to leverage your customer
                                            service skills.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem]  flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Career goals</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            The many paths that can be taken within the industry make it an
                                            attractive field for those seeking growth. Additionally, job stability
                                            is a key consideration, as the demand for roles in this sector remains
                                            strong. For instance, the growing field of electric vehicle technology
                                            presents significant opportunities for stable and rewarding
                                            careers.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Work environment</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Many roles
                                            involve practical, hands-on-work which can be highly rewarding if
                                            you enjoy working directly with vehicles. Additionally,
                                            collaboration is often key in this field, whether you’re working on
                                            a production line or as part of a design team. This teamwork aspect
                                            can enhance the work experience, making it both dynamic and
                                            fulfilling.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Education and training</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Education & training can be important for roles within the automotive industry as it’s
                                            constantly evolving, so a willingness to learn and adapt is
                                            important for staying current with new technologies and practices.
                                            For specific roles there may be a requirement for qualifications or
                                            certifications, such as vocational training for auto mechanics.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-16 lg:my-24 flex items-center justify-center">
            <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
                class="hidden sm:flex" />
            <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
                class="sm:hidden" />
        </div>

        <div class="w-full">
            <div class="w-full home_sub_container lg:mb-24 mb-16 px-6 lg:px-0">
                <div class="max_container">
                    <div class="justify-between items-start lg:flex block mb-16">
                        <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">
                            What roles are available in automotive?</div>
                        <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                            <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">
                                The automotive industry in the UK offers a wide range of roles across various sectors.
                                Here are some key positions:</div>
                        </div>
                    </div>
                    <div class="w-full relative">
                        <div class="carousel-container">
                            <div class="carousel-track">
                                <div *ngFor="let slide of slides" class="carousel-slide">
                                    <div
                                        class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                        <div
                                            class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                            <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                                <div
                                                    class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    {{ slide.title }}
                                                </div>
                                                <div
                                                    class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                    {{ slide.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Carousel navigation buttons -->
                            <div class="button-container">
                                <button id="slider-button-left"
                                    class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                    <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                            src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                            alt="arrow-left"></div>
                                </button>
                                <button id="slider-button-right"
                                    class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                    <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                            src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                            alt="arrow-right"></div>
                                </button>
                            </div>

                            <!-- Pagination -->
                            <div class="carousel-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full">
            <div class="w-full home_sub_container bg-[#F9FAFB]">
                <div class="max_container">
                    <div>
                        <div
                            class="lg:py-24 py-16 bg-gray-50 rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
                            <div class="self-stretch justify-between items-center lg:flex block">
                                <div class="max-w-[44rem] mb-8">
                                    <div class="text-[#331643] text-[32px] font-bold font_hindguntur leading-[48px]">
                                        Featured jobs</div>
                                    <div class="text-[#4f3b5b] text-base font-normal font_hindguntur leading-normal">
                                        Within the automotive industry in the UK, there are various roles on offer,
                                        whether that’s entry level to those that are highly skilled within the sector.
                                        Here are some featured positions:</div>
                                </div>
                                <div
                                    class="px-4 py-2.5 bg-white rounded-full shadow border border-[#d2d6db] justify-center items-center gap-1.5 flex">
                                    <div class="px-0.5 pt-[5px] justify-center items-center flex cursor-pointer text-[#384250] text-base font-medium font_hindguntur leading-normal"
                                        (click)="goToJobs()">
                                        View more jobs
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:gap-6 gap-4">
                                <div
                                    class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div class="self-stretch justify-between items-start inline-flex">
                                            <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                                <div
                                                    class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                    <div
                                                        class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                        Automotive</div>
                                                    <div
                                                        class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                        Service Advisors</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="self-stretch justify-start items-end inline-flex">
                                            <div class="h-7 justify-start items-center gap-6 flex">
                                                <div class="justify-start items-center gap-2 flex">
                                                    <div class="w-5 h-5 relative"><img
                                                            src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                            alt="currency-icon"></div>
                                                    <div class="pt-1 justify-center items-center gap-2 flex">
                                                        <div
                                                            class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                            £21,000-£30,000 per annum</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                        Liaise between customers and the service department in the automotive industry.
                                        Explain repairs, provide cost estimates and ensure customer understanding.
                                        Requires excellent communication and organisational skills. Salaries range from
                                        £21k-£30k.
                                    </div>
                                </div>
                                <div
                                    class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                    <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                        <div class="self-stretch justify-between items-start inline-flex">
                                            <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                                <div
                                                    class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                    <div
                                                        class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                        Automotive</div>
                                                    <div
                                                        class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                        Automotive Engineers</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="self-stretch justify-start items-end inline-flex">
                                            <div class="h-7 justify-start items-center gap-6 flex">
                                                <div class="justify-start items-center gap-2 flex">
                                                    <div class="w-5 h-5 relative"><img
                                                            src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                            alt="currency-icon"></div>
                                                    <div class="pt-1 justify-center items-center gap-2 flex">
                                                        <div
                                                            class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                            £20,000-£40,000 per annum</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                        Designs, develops and tests new vehicle models and technologies. They improve
                                        performance, safety and efficiency using CAD software, simulations and tests.
                                        Salaries average £40k+ per year, with entry-level positions available.</div>
                                </div>
                                <div
                                    class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                    <div class="self-stretch  flex-col justify-start items-start gap-2 flex">
                                        <div class="self-stretch justify-between items-start inline-flex">
                                            <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                                <div
                                                    class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                    <div
                                                        class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                        Automotive</div>
                                                    <div
                                                        class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                        HGV Drivers</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="self-stretch justify-start items-end inline-flex">
                                            <div class="h-7 justify-start items-center gap-6 flex">
                                                <div class="justify-start items-center gap-2 flex">
                                                    <div class="w-5 h-5 relative"><img
                                                            src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                            alt="currency-icon"></div>
                                                    <div class="pt-1 justify-center items-center gap-2 flex">
                                                        <div
                                                            class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                            £21,000-£60,000 per annum</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                        Transport goods across the UK, ensuring safe and timely delivery. Requires
                                        handling large vehicles, navigating routes and adhering to safety regulations.
                                        Needs a Cat C or C+E licence and CPC training. Salaries range from £21k-£60k,
                                        averaging £32k.</div>
                                </div>
                                <div
                                    class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div class="self-stretch justify-between items-start inline-flex">
                                            <div class="grow shrink basis-0  justify-start items-start gap-4 flex">
                                                <div
                                                    class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                    <div
                                                        class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                        Automotive</div>
                                                    <div
                                                        class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                        Materials Technicians</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="self-stretch justify-start items-end inline-flex">
                                            <div class="h-7 justify-start items-center gap-6 flex">
                                                <div class="justify-start items-center gap-2 flex">
                                                    <div class="w-5 h-5 relative"><img
                                                            src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                            alt="currency-icon"></div>
                                                    <div class="pt-1 justify-center items-center gap-2 flex">
                                                        <div
                                                            class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                            £24,000-£30,000 per annum</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                        Test and develop materials for vehicle manufacturing, ensuring they meet quality
                                        and safety standards. They conduct tests on strength, flexibility and durability
                                        and help develop new products and improve existing ones. Salaries range from
                                        £24k-£30k.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full">
            <div class="w-full home_sub_container bg-[#724EB3]">
                <div class="max_container">
                    <div class="lg:py-24 py-16">
                        <div class="px-8 justify-start items-start gap-16 lg:flex block">
                            <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                                    <div>
                                        <img src="../../../../../assets/images/sector_pathway/Featured icon.svg"
                                            alt="icon">
                                    </div>
                                    <div
                                        class="self-stretch text-white text-3xl lg:text-4xl font-semibold font_hindguntur leading-[52px]">
                                        What’s it like to work in the automotive industry?</div>
                                </div>
                                <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                    Working in the automotive industry can be a dynamic and rewarding experience,
                                    offering a variety of roles and opportunities. Here are some key aspects of what
                                    it’s like:</div>
                            </div>
                            <div
                                class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Diverse career paths</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            the diversity within the industry allows individuals to explore various
                                            career paths and find a role that aligns with their unique talents and
                                            passions. Whether you are drawn to the technical, creative, or
                                            customer-focused aspects of the automotive world, there is a place for you
                                            to thrive and grow.</div>
                                    </div>
                                </div>
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Hands-on work</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            many roles involve practical, hands-on tasks, whether it’s repairing
                                            vehicles, designing new models, or working on the production line. This type
                                            of work can be incredibly satisfying for those who enjoy tangible, physical
                                            activities. Overall, hands-on work provides a unique blend of physical
                                            activity, problem-solving, and creativity, making it an attractive option
                                            for individuals who thrive in practical, engaging roles.</div>
                                    </div>
                                </div>
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Technological innovation</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            the automotive industry is at the forefront of technological advancements,
                                            including electric vehicles, autonomous driving, and smart car technologies.
                                            This makes it an exciting field for those interested in cutting-edge
                                            technology.</div>
                                    </div>
                                </div>
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Team collaboration</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            is a key component in the automotive industry, whether you’re working in a
                                            manufacturing plant, a design studio, or a dealership. The ability to work
                                            well in a team and communicate effectively is highly valued in the
                                            automotive industry. Whether you’re on the factory floor, in a creative
                                            studio, or interacting with customers, collaboration is key to achieving
                                            success and driving innovation.</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Customer interaction</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            roles in sales, service advising, and customer support involve significant
                                            interaction with customers, helping them choose the right vehicle or
                                            ensuring
                                            their
                                            car is well-maintained. They play a crucial role in the success of the
                                            automotive
                                            industry due to the positive interactions they have with customers.</div>
                                    </div>
                                </div>
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Career stability and growth</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            the demand for vehicles and automotive services remains strong, providing
                                            job stability. Additionally, there are ample opportunities for career
                                            advancement and specialisation. The automotive industry is a cornerstone of
                                            the global economy, with millions of vehicles being produced, sold, and
                                            serviced each year. This consistent demand ensures a steady need for skilled
                                            professionals across various roles, from manufacturing and engineering to
                                            sales and customer service. As a result, individuals working in this
                                            industry can enjoy a high level of job security, knowing that their skills
                                            and expertise will continue to be in demand.</div>
                                    </div>
                                </div>
                                <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                    <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                        <div
                                            class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                            Challenges</div>
                                        <div
                                            class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                            like any industry, the automotive sector has its challenges. These can
                                            include meeting tight production deadlines, staying updated with rapidly
                                            changing technologies, and managing customer expectations. Successfully
                                            navigating these challenges requires a combination of strategic planning,
                                            continuous learning, and a strong commitment to customer satisfaction. By
                                            addressing these challenges head-on, professionals and companies can thrive
                                            in this dynamic and evolving industry.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <vc-footer></vc-footer>