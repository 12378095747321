import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'vc-sector-pathways',
  templateUrl: './sector-pathways.component.html'
})
export class SectorPathwaysComponent implements AfterViewInit {
  currentSlideIndex = 0;

  constructor(private readonly router: Router,
    private readonly title: Title,
  ) { }

  ngOnInit(){
    this.title.setTitle('Sector Guides');
  }

  ngAfterViewInit(): void {
    this.setupCarousel();
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index, track, slides));
      pagination.appendChild(bullet);
    });

    prevButton?.addEventListener('click', () => this.prevSlide(track, slides));
    nextButton?.addEventListener('click', () => this.nextSlide(track, slides));
    this.updatePagination(pagination, this.currentSlideIndex);
  }

  nextSlide(track: HTMLElement, slides: HTMLElement[]): void {
    if (this.currentSlideIndex < slides.length - 2) { 
      this.currentSlideIndex++;
      this.moveToSlide(track, slides);
    }
  }

  prevSlide(track: HTMLElement, slides: HTMLElement[]): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track, slides);
    }
  }

  goToSlide(index: number, track: HTMLElement, slides: HTMLElement[]): void {
    this.currentSlideIndex = index;
    this.moveToSlide(track, slides);
  }

  moveToSlide(track: HTMLElement, slides: HTMLElement[]): void {
    const amountToMove = -this.currentSlideIndex * 50; 
    track.style.transform = `translateX(${amountToMove}%)`;
    this.updatePagination(document.querySelector('.carousel-pagination') as HTMLElement, this.currentSlideIndex);
  }

  updatePagination(pagination: HTMLElement, activeIndex: number): void {
    Array.from(pagination.children).forEach((bullet, index) => {
      bullet.classList.toggle('active', index === activeIndex);
    });
  }

  allArticles() {
    this.router.navigate(['/home/articles-guide/all-articles']);
  }

  viewPage1() {
    this.router.navigate(['/sector-guides/automotive-view']);
  }

  viewPage2() {
    this.router.navigate(['/sector-guides/charity-view']);
  }

  viewPage3() {
    this.router.navigate(['/sector-guides/cyber-security-view']);
  }

  viewPage4() {
    this.router.navigate(['/sector-guides/logistics-view']);
  }

  viewPage5() {
    this.router.navigate(['/sector-guides/security-view']);
  }

  viewPage6() {
    this.router.navigate(['/sector-guides/wind-energy-view']);
  }
}
