import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomotiveViewComponent } from './automotive-view/automotive-view.component';
import { CharityViewComponent } from './charity-view/charity-view.component';
import { CyberSecurityViewComponent } from './cyber-security-view/cyber-security-view.component';
import { logistcisViewComponent } from './logistics-view/logistics-view.component';
import { SectorPathwaysComponent } from './sector-pathways.component';
import { SecurityViewComponent } from './security-view/security-view.component';
import { WindEnergyComponent } from './wind-energy/wind-energy.component';
import { SharedFeaturedModule } from '../../articles-and-guides/shared-featured-articles/shared-featured.module';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    SectorPathwaysComponent,
    logistcisViewComponent,
    AutomotiveViewComponent,
    CharityViewComponent,
    CyberSecurityViewComponent,
    SecurityViewComponent,
    WindEnergyComponent    
  ],
  imports: [
    CommonModule,
    SharedFeaturedModule,
    CoreModule
  ]
})
export class SectorPathwaysModule { }
