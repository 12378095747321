import { Component, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'vc-cyber-security-view',
  templateUrl: './cyber-security-view.component.html'
})
export class CyberSecurityViewComponent {
  currentSlideIndex = 0;

  slides = [
    {
      title: 'Cyber security analyst',
      description: `A cyber security analyst plays a crucial role in protecting an organisations computer systems and networks from digital attacks. 
      Their responsibilities include monitoring network traffic for security incidents, identifying and investigating threats, and responding to security breaches
      in real time. They also install and manage security software such as firewalls and encryption programs, conduct risk assessments and develop best practices
      for information security. Additionally, they engage in threat research and may perform ethical hacking to identify vulnerabilities. This role requires staying
      up to date with the latest cyber threats and technologies to effectively safeguard an organisations data. `
    },
    {
      title: 'Penetration tester',
      description: `A penetration tester, also known as an ethical hacker, plays a vital role in cyber security by simulating cyber attacks on an organisations 
      systems, networks and applications to identify any vulnerabilities. Their primary goal is to uncover weaknesses before malicious hackers can exploit them. 
      This involves using various techniques to test the security of different systems. They also work closely with clients to understand their specific needs and 
      tailor the testing accordingly. This work includes creating detailed reports outlining discovered vulnerabilities, potential risks and recommended mitigation
       strategies. They may also develop new testing methods and tools to stay ahead of emerging threats. `
    },
    {
      title: 'Security consultant',
      description: `A security consultant plays a pivotal role in helping organisations protect their IT systems and data. They assess existing security measures, 
      identify potential vulnerabilities, and recommend improvements to enhance overall security. Their responsibilities include, but are not limited to, conducting 
      risk assessments, designing and implementing security protocols, and ensuring compliance with relevant regulations. Security consultants also stay updated on 
      the latest security threats and technologies to provide informed advice. They often work closely with clients to tailor security solutions to specific needs 
      and may support by training staff on best practices to prevent security breaches.`
    },
    {
      title: 'Incident responder',
      description: `An incident responder is a cyber security professional responsible for identifying, investigating and mitigating security incidents within 
      an organisation. When a security breach occurs, they are the first line of defence, acting quickly to minimise the impact and prevent further compromise. 
      Their duties include monitoring network traffic for suspicious activity, conducting forensic analysis to understand the root cause of incidents and implement
      measures to contain and resolve threats. They also collaborate with other IT and security teams to ensure a coordinated response and may provide recommendations 
      to improve the organisations overall security posture. `
    }
  ];

  constructor(
    private readonly title: Title,
    private router: Router,
    public readonly authService: AuthenticationService,
  ) {
    this.title.setTitle('Cyber security');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setupCarousel();
      this.setupPagination();
    }, 1000);
  }

  setupCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    const slides = Array.from(document.querySelectorAll('.carousel-slide')) as HTMLElement[];
    const prevButton = document.getElementById('slider-button-left');
    const nextButton = document.getElementById('slider-button-right');
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;

    prevButton?.addEventListener('click', () => this.prevSlide(track));
    nextButton?.addEventListener('click', () => this.nextSlide(track));
  }

  nextSlide(track: HTMLElement): void {
    const slidesToShow = 2;
    const maxIndex = this.slides.length - 1;

    if (this.currentSlideIndex < maxIndex) {
      this.currentSlideIndex++;
      this.moveToSlide(track);
    }
  }

  prevSlide(track: HTMLElement): void {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.moveToSlide(track);
    }
  }

  moveToSlide(track: HTMLElement): void {
    const isMobileView = window.innerWidth <= 768;
    const trackWidth = track.clientWidth;
    const isLastSlide = this.currentSlideIndex === this.slides.length - 1;
    const isSecondLastSlide = this.currentSlideIndex === this.slides.length - 2;

    if (isMobileView) {

      const amountToMove = -(this.currentSlideIndex * trackWidth * 0.955);
      track.style.transform = `translateX(${amountToMove}px)`;
    } else {
      if (isLastSlide || isSecondLastSlide) {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;
      } else {

        const amountToMove = -(this.currentSlideIndex * (trackWidth * 0.669));
        track.style.transform = `translateX(${amountToMove}px)`;

        const slides = document.querySelectorAll('.carousel-slide') as NodeListOf<HTMLElement>;
        slides.forEach(slide => slide.classList.remove('next-slide'));

        if (this.currentSlideIndex < this.slides.length - 1) {
          slides[this.currentSlideIndex + 1].classList.add('next-slide');
        }
        if (screen.width < 480) {
          const amountToMove = -(this.currentSlideIndex * (trackWidth * 1));
          track.style.transform = `translateX(${amountToMove}px)`;
        }
      }
    }
    this.updatePagination();
  }
  setupPagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    pagination.innerHTML = '';

    this.slides.forEach((_, index) => {
      const bullet = document.createElement('div');
      bullet.classList.add('bullet');
      bullet.addEventListener('click', () => this.goToSlide(index));
      pagination.appendChild(bullet);
    });

    this.updatePagination();
  }
  goToSlide(index: number): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    this.currentSlideIndex = index;
    this.moveToSlide(track);
  }
  updatePagination(): void {
    const pagination = document.querySelector('.carousel-pagination') as HTMLElement;
    const bullets = Array.from(pagination.children) as HTMLElement[];

    bullets.forEach((bullet, index) => {
      if (index === this.currentSlideIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }

  goToJobs() {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
    } else {
      this.router.navigate(['home', 'jobs', 'jobs-list'])
    }
  }
}
