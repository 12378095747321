// shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedFeaturedArticlesComponent } from './shared-featured-articles.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { QuillModule } from 'ngx-quill';
import { CoreModule } from 'src/app/core/core.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FormControlModule } from 'src/app/shared/components/form-control/form-control.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { CoursesRoutingModule } from '../../pre-signin/courses/courses-routing.module';
import { ArticlesAndGuidesRoutingModule } from '../articles-and-guides-routing.module';

@NgModule({
  declarations: [
    SharedFeaturedArticlesComponent,  // Declare the common component
  ],
  imports: [
    CommonModule,
    PipesModule,
  ],
  exports: [
    SharedFeaturedArticlesComponent,  // Export the common component so it can be used in other modules
  ]
})
export class SharedFeaturedModule { }
