<div [style.display]="visible ? 'block' : 'none'" class="fade-in" style="z-index: 10000;">
    <div class="modal show" id="myModal" style="position:fixed; z-index: 10000;">
        <div class="custmodal-dialog p-2.5 text-sm text-gray-900 bg-[#fff] rounded-lg border border-gray-300 focus:outline-none z-30">
            <div class="custmodal-content">
                <div class="custmodal-header">
                    <h1 class="text-[#331643] font-bold font_hindguntur text-[32px] leading-[48px] !mb-0" id="myModalLabel" autofocus="autofocus">
                        {{header || 'Confirm'}}</h1>
                </div>
                <div class="custmodal-body">
                    <p class="text-[18px] !text-[#331643] font-medium leading-[36px] !mb-0" title="{{message}} sometime">{{message}}</p>
                    <p class="white-space-preline" *ngIf="showTimer">
                        {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}.
                    </p>
                </div>
                <div class="custmodal-footer flex gap-2 mt-4 w-full">
                    <button *ngIf="hasSecondPopup" id="secondaryLast" type="button" class="flex-grow flex justify-center items-center gap-1.5 py-4.5 rounded-full font_hindguntur font-medium bg-[#562672] shadow-xs text-white text-[16px] px-5 py-3 focus:outline-none leading-[24px]"
                        (click)="acceptOnHavingSecondpopup()">{{acceptLabel || 'Ok'}}</button>
                    <button *ngIf="!hasSecondPopup" id="secondaryLast" type="button" class="flex-grow flex justify-center items-center gap-1.5 py-4.5 rounded-full font_hindguntur font-medium bg-[#562672] shadow-xs text-white text-[16px] px-5 py-3 focus:outline-none leading-[24px]"
                        (click)="accept()">{{acceptLabel || 'Ok'}}</button>
                    <button *ngIf="showClose" id="last" type="button" class="flex-grow flex justify-center items-center gap-1.5 py-4.5 rounded-full font_hindguntur font-medium bg-white shadow-xs text-[#384250] text-[16px] px-5 py-3 focus:outline-none hover:bg-gray-100 leading-[24px] border border-gray-300"
                        (click)="reject()">{{rejectLabel || 'Cancel'}}</button>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade in"></div>
    </div>
</div>
