import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from 'src/app/shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})

export class favouritesService {

  constructor(private readonly http: HttpClient) {
  }

  addToFavourites(targetId, collectionName){

    const href = `${Url.CMS_PROXY_API}/proxy/userFavourites/create`;
    return this.http.post<any>(href, {
        "data":{
            "targetId": targetId.toString(),
            "targetCollection":collectionName
        }
    });
  }

  removeFromFavourites(id){

    const href = `${Url.CMS_PROXY_API}/proxy/userFavourites/delete/${id}`;
    return this.http.delete<any>(href);
  }

  getFavouritesByCollectionName(collectionName, collectionIds?){

    const href = `${Url.CMS_PROXY_API}/favourites/getUserFavouritesData/${collectionName}`;
    return this.http.post<any>(href, collectionIds);
  }

  getMyfavouriteJobs(collectionName, pageIndex, PageSize){

    const href = `${Url.CMS_PROXY_API}/favourites/${collectionName}?page=${pageIndex}&size=${PageSize}`;
    
    return this.http.post<any>(href,collectionName);
  }
}
