import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { INavigation } from '../components/header/header.interfaces';

const topNavigation: INavigation[] = [
  {
    id: 8,
    name: 'My programme',
    state: 'programme',
    showAuthed: true,
  },
  {
    id: 1,
    name: 'Courses',
    state: 'courses',
    showAuthed: true,
  },
  // {
  //   id: 2,
  //   name: 'Plan',
  //   state: 'plan',
  //   showAuthed: true,
  // },
  {
    id: 3,
    name: 'Content',
    state: 'content',
    showAuthed: true,
  },
  {
    id: 4,
    name: 'Employment',
    state: 'employment',
    showAuthed: true,
  },
  {
    id: 5,
    name: 'Wallet',
    state: 'wallet',
    showAuthed: true,
  },
  {
    id: 7,
    name: 'Useful websites',
    state: 'useful-websites',
    showAuthed: true,
  },
  {
    id: 6,
    name: 'Social',
    state: 'social',
    showAuthed: true,
  },
  // {
  //   id: 8,
  //   name: 'Messages',
  //   state: 'srm',
  //   showAuthed: true,
  // },

];

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private readonly navigationSubject = new BehaviorSubject(topNavigation);
  currentNavigation = this.navigationSubject.asObservable();
  private redirectUrl: string | null = null;
  constructor() {
  }

  setNavigation(data: any) {
    this.navigationSubject.next(data);
  }

  setConditionalDisplay(flag: boolean) {
    topNavigation
      .filter(item => item.id !== 1 && item.id !== 2)
      .forEach(item => item.showAuthed = flag);
    this.setNavigation(topNavigation);
  }
  // Set the URL to redirect to after login
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  // Get the URL to redirect to after login
  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

}
